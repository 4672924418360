import React from 'react';
import {Button, Form, Input, message, Select, Switch} from "antd";
import "../../../resource/styles/certInfo.scss"
import "../index.scss"
import http from './../../../lib/service/http'
import {regexps_length_30, regexps_length_150} from "../../../lib/help/validates";
const {Option} = Select;
const {TextArea} = Input;


export default class editConfig extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            info: [],
            optionsList:[],
            institutionList:[],
            initialValues:{},
            Disabled:null
        }
    }
    onFinish =(values) =>{
        if (Object.keys(this.props.certInfoData).length >0) {
            values.id=this.props.certInfoData.ID
            values.enable=this.state.Disabled === 0
            http.post('copyright-config/update', values).then(res => {
                if (Object.keys(res).length===0) {
                    this.props.close();
                    message.success({
                        className: '',
                        content: '编辑成功',
                        duration: 2,
                    });
                }
            });

        } else {
            http.post('/copyright-config/create', values).then(res => {
                if (Object.keys(res).length===0) {
                    this.props.close();
                    message.success({
                        className: '',
                        content: '新增成功',
                        duration: 2,
                    });
                }
            }, () => {

            });
        }
    };
    onFinishFailed(errorInfo) {
    };
    componentDidMount () {
        this.getOptionsList()
        this.getInstitutionList()
        if (Object.keys(this.props.certInfoData).length >0) {
            let initialValues = {
                "name": this.props.certInfoData.Name,
                "option":this.props.certInfoData.Option,
                "remark":this.props.certInfoData.Remark,
                "institution_id":this.props.certInfoData.InstitutionID,
            }
            this.setState({
                Disabled:this.props.certInfoData.Disabled,
                initialValues
            });
            this.formRef.current.setFieldsValue(initialValues);
        }
    }
    getOptionsList=()=>{
        http.get('/copyright-config/options').then(res => {
            if (res) {
                this.setState({
                    optionsList: res.data.items,
                })
            }
        })
    }
    getInstitutionList=()=>{
        http.get('/institution/all').then(res => {
            if (res) {
                this.setState({
                    institutionList: res.data.items,
                })
            }
        })
    }
    close = () => {
        this.props.close();
    };
    onChangeType=()=> {

    };
    changeStatus=(val)=> {
        if(val) {
            this.setState({
                Disabled:0
            })
        } else {
            this.setState({
                Disabled:1
            })
        }
    }
    render() {
        return (
            <div className="edit-form certInfo ">
                        <Form
                            name="wrap"
                            ref={this.formRef}
                            onFinish={(values) => this.onFinish(values)}
                            onFinishFailed={() => this.onFinishFailed()}
                        >
                           <Form.Item
                                    label="名称"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: '该项不能为空',
                                        },
                                        {
                                            pattern: regexps_length_30,
                                            message: '请输入1-30个字符',
                                        }
                                    ]}
                                    validateFirst={true}
                                >
                                    <Input className={'name-input'} placeholder={'请输入'}/>
                                </Form.Item>
                            <Form.Item
                                label="存证信息"
                                name="option"
                                rules={[
                                    {
                                        required: true,
                                        message: '该项不能为空',
                                    }
                                ]}
                                validateFirst={true}
                            >
                                <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                    {
                                        this.state.optionsList.map((item) => {
                                            return <Option value={item.Option} key={item.ID} lable={item.Name}>{item.Name}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="所属机构"
                                name="institution_id"
                                rules={[
                                    {
                                        required: true,
                                        message: '该项不能为空',
                                    }
                                ]}
                                validateFirst={true}
                            >
                                <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                    {
                                        this.state.institutionList.map((item) => {
                                            return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="备注"
                                name="remark"
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        pattern: regexps_length_150,
                                        message: '请输入1-150个字符',
                                    }
                                ]}
                                validateFirst={true}
                            >
                                <TextArea className={'name-textArea'} placeholder={'请输入'}/>
                            </Form.Item>
                            {Object.keys(this.props.certInfoData).length >0&& <Form.Item
                                label="状态"
                                name="Disabled"
                                validateFirst={true}
                            >
                                <div>
                                    <Switch checked={this.state.Disabled === 0} onChange={(e)=>this.changeStatus(e)} />
                                    <span className='edit-switch-text'>{this.state.Disabled===0 ? '启用' : '禁用'}</span>
                                </div>
                            </Form.Item>}
                            <Form.Item className="dialog-footer-btn">
                                <Button className={'submit-btn detail-btn'} type="primary" htmlType="submit">
                                    提交
                                </Button>
                                <Button className={'submit-btn detail-btn reset-btn'} type="primary" onClick={this.close}>
                                    取消
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
        )
    }
}
