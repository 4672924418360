import React from 'react';
import {Popover, Select} from 'antd';
import {Link, withRouter} from "react-router-dom";
import './index.scss'
import * as echarts from 'echarts/core';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import iconCount1 from './../../resource/images/index/icon-count-1.svg'
import iconCount2 from './../../resource/images/index/icon-count-2.svg'
import iconCount3 from './../../resource/images/index/icon-count-3.svg'
import iconCount4 from './../../resource/images/index/icon-count-4.svg'
import iconCount5 from './../../resource/images/index/icon-count-5.svg'
import http from "../../lib/service/http";
import _ from 'lodash'
import CertList from '../BlockchainCert/index.jsx'
import {getUserRole, setNumber} from "../../lib/help/helper";



echarts.use([GridComponent, TooltipComponent, LineChart, CanvasRenderer,SVGRenderer,  UniversalTransition]);
const {Option} = Select;
const timeSelect = [
    {
        name: '30天',
        value: 0
    },
    {
        name: '7天',
        value: 1
    },
    {
        name: '24小时',
        value: 2
    }
]

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptor: [],
            option: {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            shadowColor: '#CCCCCC'
                        }
                    }
                ]
            },
            leftChart: '',
            rightChart: '',
            infoData: {},
            chartData: [],
            searchChartData: [],
            selectCopyright: 2,
            selectObtain: 2
        }
    }

    componentDidMount() {
        this.getInfoData();
        this.getChartData();
        this.getSearchChartData();
        let tmpInfo = localStorage.getItem('userInfo');
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            this.setState({
                userInfo: tmpInfo
            })
        }
        window.addEventListener("resize", _.debounce(() => {
            this.state.leftChart && this.state.leftChart.resize();
        }))
    }

    getInfoData = () => {
        http.get('/overview/all', ).then(res => {
            this.setState({
                infoData: res.data
            })
        })
    }
    getChartData = () => {
        http.get('/overview/copyright-chart', ).then(res => {
            this.setState({
                chartData: res.data
            })
            this.setTimeOutCharts(res.data[2], 'left-charts')
        })
    }
    getSearchChartData = () => {
        http.get('/overview/search-copyright-chart', ).then(res => {
            this.setState({
                searchChartData: res.data
            })
            this.setTimeOutCharts(res.data[2], 'right-charts')
        })
    }

    setTimeOutCharts = (data, elementName) => {
        setTimeout(()=> {
            this.setCharts(data, elementName)
        }, 20)
    }
    setCharts = (data={}, elementName)=> {
        if(!elementName){
            return;
        }
        var chartDom = document.getElementById(elementName);
        var myChart = echarts.init(chartDom,null, {renderer: 'svg'});
        if(elementName === 'left-charts') {
            this.setState({
                leftChart: myChart,
            })
        }else {
            this.setState({
                rightChart: myChart,
            })
        }
        var option;

        option = {
            grid: {
                top: 30,
                bottom: 24,
                left: 30,
                right: 20
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.ChartX
            },
            yAxis: {
                type: 'value'
            },
            width: 'auto',
            series: [
                {
                    data: data.ChartY,
                    type: 'line',
                    smooth: true,
                    areaStyle: {
                        normal: {
                            color: 'rgba(24, 144, 255, .2)',
                        }
                    },
                    // symbol: "none",

                    itemStyle: {
                        normal: {
                            color: '#56a1f8', //改变折线点的颜色
                            lineStyle: {
                                color: '#56a1f8' //改变折线颜色
                            }
                        }
                    }
                }
            ],
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            }
        };

        option && myChart.setOption(option);
        myChart.resize()
    }

    copyrightChange = (val, key) => {
        this.setState({
            [key]: val
        }, ()=> {
            if(key === 'selectCopyright') {
                this.setTimeOutCharts(this.state.chartData[val], 'left-charts')
            }else {
                this.setTimeOutCharts(this.state.searchChartData[val], 'right-charts')
            }
        })
    }

    render() {
        const {infoData, chartData, searchChartData, selectObtain, selectCopyright } = this.state;
        const roleOptions = getUserRole()

        return (
            <div className="index-container">
                <div className="index-item-box">
                    <p className="index-item-title">
                        数据概览
                    </p>
                    <div className="count-item">
                        <div className="index-count-item">
                            <img src={iconCount1} alt="" className="item-img"/>
                            <div className="text-box">
                                <p className="number">
                                    <Popover content={setNumber(infoData.block_height)} trigger="hover">
                                        <span className="count-number">{setNumber(infoData.block_height)}</span>
                                    </Popover>
                                </p>
                                <p className="desc">区块高度</p>
                            </div>
                        </div>
                        <div className="index-count-item">
                            <img src={iconCount2} alt="" className="item-img"/>
                            <div className="text-box">
                                <p className="number">
                                    <Popover content={setNumber(infoData.copyright_count)} trigger="hover">
                                        <span className="count-number">{setNumber(infoData.copyright_count)}</span>
                                    </Popover>
                                </p>
                                <p className="desc">存证总数</p>
                            </div>
                        </div>
                        <div className="index-count-item">
                            <img src={iconCount3} alt="" className="item-img"/>
                            <div className="text-box">
                                <p className="number">
                                    <Popover content={setNumber(infoData.copyright_search_count)} trigger="hover">
                                        <span className="count-number">{setNumber(infoData.copyright_search_count)}</span>
                                    </Popover>
                                </p>
                                <p className="desc">取证查询总数</p>
                            </div>
                        </div>
                        <div className="index-count-item">
                            <img src={iconCount4} alt="" className="item-img"/>
                            <div className="text-box">
                                <p className="number">
                                    <Popover content={setNumber(infoData.copyright_download_count)} trigger="hover">
                                        <span className="count-number">{setNumber(infoData.copyright_download_count)}</span>
                                    </Popover>
                                </p>
                                <p className="desc">存证下载总数</p>
                            </div>
                        </div>
                        <div className="index-count-item">
                            <img src={iconCount5} alt="" className="item-img"/>
                            <div className="text-box">
                                <p className="number">
                                    <Popover content={setNumber(infoData.user_count)} trigger="hover">
                                        <span className="count-number">{setNumber(infoData.user_count)}</span>
                                    </Popover>
                                </p>
                                <p className="desc">总用户数</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="index-item-box chart-box">
                    <div className="chart-box-item">
                        <div className="index-item-title title-chart">
                            存证数据趋势
                            <Select placeholder="请选择" onChange={(val)=> this.copyrightChange(val, 'selectCopyright')} defaultValue={this.state.selectCopyright} className={'search-select'}>
                                {
                                    timeSelect.map((item) => {
                                        return <Option value={item.value} key={item.value}>{item.name}</Option>
                                    })
                                }

                            </Select>
                        </div>
                        <div className="chart-count-box">
                            <div className="today-count">
                                <p className="today-num">{chartData[selectCopyright]?.Today}</p>
                                <p className="today-desc">今日数据</p>
                            </div>
                            <div className="today-average">
                                <p className="today-num">日平均数：<span>{chartData[selectCopyright]?.Average}</span></p>
                                <p className="today-num">累计总数：<span>{chartData[selectCopyright]?.Total}</span></p>
                            </div>
                        </div>
                        <div id="left-charts">
                        </div>
                    </div>

                    <div className="chart-box-item">
                        <div className="index-item-title title-chart">
                            取证数据趋势
                            <Select placeholder="请选择" onChange={(val)=> this.copyrightChange(val, 'selectObtain')} defaultValue={this.state.selectObtain} className={'search-select'}>
                                {
                                    timeSelect.map((item) => {
                                        return <Option value={item.value} key={item.value}>{item.name}</Option>
                                    })
                                }

                            </Select>
                        </div>
                        <div className="chart-count-box">
                            <div className="today-count">
                                <p className="today-num">{searchChartData[selectObtain]?.Today}</p>
                                <p className="today-desc">今日数据</p>
                            </div>
                            <div className="today-average">
                                <p className="today-num">日平均数：<span>{searchChartData[selectObtain]?.Average}</span></p>
                                <p className="today-num">累计总数：<span>{searchChartData[selectObtain]?.Total}</span></p>
                            </div>
                        </div>
                        <div id="right-charts">
                        </div>
                    </div>
                </div>
                {
                    roleOptions.includes('存证-查看') && <div className="cert-list-box index-item-box">
                        <p className="index-item-title title-chart">
                            新增存证记录
                            <Link to={'/block-chain-cert/list'}>查看全部</Link>
                        </p>
                        <CertList history={this.props.history} type={'index'} />
                    </div>
                }

            </div>
        )
    }
}
export default withRouter(Index)