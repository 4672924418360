import React from 'react';
import {Button} from "antd";
import './index.scss'
import noPage from "../../resource/images/common/404.svg"


export default class NoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="no-page-container">
                <div className="no-page-content">
                    <img src={noPage} alt="" className="no-page-img"/>
                    <p className="desc">
                        抱歉！您所访问的页面不存在，请重新加载
                    </p>
                    <Button type="primary" onClick={()=>this.props.history.goBack()}>
                        返回
                    </Button>
                </div>
            </div>
        )
    }
}
