import React, { Component } from 'react';
import './App.css';
import './resource/styles/public.scss'
import './resource/styles/animation.scss'
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import HeaderDom from './common/Header'
import Login from './pages/Login';
import Index from './pages/Index';
import Aside from './common/Aside'
import EvidenceAdd from "./pages/EvidenceAdd";
import EvidenceList from "./pages/EvidenceList";
import CopyrightAdd from "./pages/CopyrightAdd";
import CertDetail from "./pages/CertDetail";
import BlockDetail from "./pages/BlockDetail";
import CopyrightList from "./pages/CopyrightList";
import AccessQuery from "./pages/AccessQuery";
import AccessQueryList from "./pages/AccessQueryList";
import AgencyList from './pages/AgencyList'
import AgencyInfo from './pages/AgencyInfo'
import RoleList from './pages/RoleList'
import AddRole from './pages/AddRole'
import RoleInfo from './pages/RoleInfo'
import NoPage from "./pages/404";


import { Layout } from 'antd';
import BlockchainCert from "./pages/BlockchainCert";
import AddBlockchainCert from "./pages/BlockchainCert/add";
import BlockchainDetail from "./pages/BlockchainCert/details";
import CertInfoConfig from "./pages/CertInfoConfig/index";
import UserInfo from "./pages/UserInfo/index";
import AddUserInfo from "./pages/UserInfo/add";
import EditUserInfo from "./pages/UserInfo/edit"
import UserInfoDetail from "./pages/UserInfo/detail";
const { Header, Content } = Layout;


class App extends Component {
    render() {
        return (
            <Router>
                <Layout>
                    <Header>
                        <HeaderDom />
                    </Header>
                    <Layout>
                        <Aside />
                        <Content>
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route exact path="/index" component={Index} />
                                <Route exact path="/obtain-evidence/add" component={EvidenceAdd} />
                                <Route exact path="/obtain-evidence/list" component={EvidenceList} />
                                <Route exact path="/copyright-seal/add" component={CopyrightAdd} />
                                <Route exact path="/copyright-seal/list" component={CopyrightList} />
                                <Route exact path="/obtain-evidence/proof/:type/:id" component={CertDetail} />
                                <Route exact path="/obtain-evidence/blockchiain/transaction/:type/:id" component={BlockDetail} />
                                <Route exact path="/certificate-query" component={AccessQuery} />
                                <Route exact path="/agency-list" component={AgencyList} />
                                <Route exact path="/agency-list/info/:id" component={AgencyInfo} />
                                <Route exact path="/role-list" component={RoleList} />
                                <Route exact path="/role-list/add" component={AddRole} />
                                <Route exact path="/role-list/info/:id" component={RoleInfo} />
                                <Route exact path="/role-list/edit/:id" component={AddRole} />
                                <Route exact path="/access-query-list" component={AccessQueryList} />
                                <Route exact path="/certificate-query/:id" component={BlockchainDetail} />
                                <Route exact path="/block-chain-cert/list" component={BlockchainCert} />
                                <Route exact path="/block-chain-cert/add" component={AddBlockchainCert} />
                                <Route exact path="/block-chain-cert/detail/:id" component={BlockchainDetail} />
                                <Route exact path="/cert-info-config/list" component={CertInfoConfig}/>
                                <Route exact path="/user-info/list" component={UserInfo}/>
                                <Route exact path="/user-info/add" component={AddUserInfo}/>
                                <Route exact path="/user-info/edit/:id" component={EditUserInfo}/>
                                <Route exact path="/user-info/detail/:id" component={UserInfoDetail}/>
                                <Route
                                    exact
                                    path={"*"}
                                    component={NoPage}
                                />
                            </Switch>
                            {/* <CacheSwitch> */}
                            {/* </CacheSwitch> */}
                        </Content>
                    </Layout>
                </Layout>
            </Router>
        );
    }
}

export default App;
