import React, {Component} from 'react'
import {Input, Form, Space, Table, Button, Row, Col, Switch, Modal, message, Pagination, Popover} from 'antd';
import './index.scss'
import {getUserRole, localTime} from "../../lib/help/helper";
import {Link} from "react-router-dom";
import http from "../../lib/service/http";
import { PlusOutlined } from '@ant-design/icons'
import AddDialog from '../../components/AddAgencyDialog/Index'
import {ReactComponent as IconSearch} from  '../../resource/images/common/icon-search.svg'
import {ReactComponent as IconWarning} from "../../resource/images/common/icon-warning.svg";
let roleOptions = getUserRole()


export default class index extends Component {
    formRef = React.createRef();
    addDialogRef = React.createRef();
    state = {
        realTimeValue: '',
        clearBtn: false,
        total: 0,
        currentPage: 1,
        searchVal: {
            // keywords: '',
        },
        data: [],
        acceptor: [],
        checked: false,
        isModalVisible: true,
        editIndex: 0,
        editID: '',
        columns: [
            {
                title: '角色ID',
                dataIndex: 'ID',
                key: 'ID',
                width: 140,
                fixed: 'left'

            },
            {
                title: '角色名',
                key: 'RoleName',
                dataIndex: 'RoleName',
                width: 260,
                render: text => {
                    return <Popover content={text} trigger="hover">
                        <span className="list-desc">{text}</span>
                    </Popover>
                }
            },
            {
                title: '角色描述',
                width: 260,
                dataIndex: 'Remark',
                key: 'Remark',
                render: text => {
                    return text ? <Popover content={text} trigger="hover">
                        <span className="list-desc">{text}</span>
                    </Popover> : '- -'
                }
            },
            {
                title: '创建时间',
                width: 150,
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                render: time => {
                    return localTime(time)
                }
            },


            {
                title: '启用丨禁用',
                width: 200,
                key: 'Disabled',
                dataIndex: 'Disabled',
                render: (Disabled, rows, index)=> {
                    return <div>
                        {
                            rows.RoleType === 'SUPER_ROLE' ? '启用' :
                            roleOptions.includes('角色-编辑') ?
                            <div>
                                <Switch  checked={!Disabled} onClick={(checked, event)=> this.switchChange(checked, rows.ID, event, index)}  />
                                <span className='edit-switch-text'>{!Disabled? '启用' : '禁用'}</span>
                            </div> : !Disabled? '启用' : '禁用'
                        }
                    </div>
                }
            },
            {
                title: '操作',
                width: 150,
                key: 'ID',
                dataIndex: 'ID',
                fixed: 'right',
                render: (text, rows) => (
                    <Space size="small">
                        <Link
                            to={{
                                pathname: `/role-list/info/${text}`,
                            }}>
                            详情
                        </Link>
                        {
                            (roleOptions.includes('角色-编辑') && rows.RoleType !== 'SUPER_ROLE') &&
                            <Button type={"link"} className={'has-line-item'} onClick={() => {
                                this.props.history.push('/role-list/edit/'+text)
                            }}>编辑</Button>
                        }
                    </Space>
                ),
            },
        ]
    }


    componentDidMount() {
        this.getList();
    }

    setInfoTitle(icon, text) {
        return (
            <div className="block-title">
                <img src={icon} className={'icon-title'} alt=""/>
                <span className="title-text">{text}</span>
            </div>
        );
    }

    switchChange = (checked, ID, event, index) => {
        this.setState({
            editID: ID,
            editIndex: index,
            isConfirmVisible: true
        })
    }


    getList = () => {
        let params = {
            ...this.state.searchVal,
        };
        params.page = this.state.currentPage;
        http.get(`/role/list`, params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items ? res.data.items : [],
                    total: res.data.total,
                    currentPage: res.data.current_page,
                })
            }
        })
    }
    handleOk = () => {
        const {data, editIndex} = this.state;
        let params = {
            id: this.state.editID
        };
        params.page = this.state.currentPage;
        http.post(`/role/disable`, params).then(() => {
            message.success(data[editIndex]?.Disabled ? '启用成功' : '禁用成功')
            this.getList();
            this.setState({
                isConfirmVisible: false
            })
        })
    }
    handleCancel = () => {
        this.setState({
            isConfirmVisible: false
        })
    }
    pageChange = (page) => {
        this.setState({
            currentPage: page
        }, ()=> {
            this.getList()
        })
    }
    searchList = async () => {
        let val = await this.formRef.current.validateFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                ...val,
            },
            currentPage: 1
        }, ()=> {
            this.getList()
        })
    }


    render() {
        const {isConfirmVisible, data, editIndex} = this.state;
        roleOptions = getUserRole()
        return (
            <div className='agency-list-container'>
                <div className="page-title">
                    <div className="title-left">
                        <p className="title">角色管理</p>
                    </div>
                    {
                        roleOptions.includes('角色-创建') &&
                        <Button type="primary" icon={<PlusOutlined />} onClick={()=> {
                            this.props.history.push('/role-list/add')
                        }}>新增</Button>
                    }
                </div>
                <div className={'info-content container-shadow container'}>
                    <div className="list-search-box">
                        <Form
                            ref={this.formRef}
                        >
                            <Row gutter={16} >
                                <Col span={5}>
                                    {/*<Form.Item*/}
                                    {/*    label={'角色'}*/}
                                    {/*    name='search'*/}
                                    {/*>*/}
                                    {/*    <Select placeholder="请选择" className={'search-select'}>*/}
                                    {/*        {*/}
                                    {/*            this.state.acceptor.map((item) => {*/}
                                    {/*                return <Option value={item} key={item}>{item}</Option>*/}
                                    {/*            })*/}
                                    {/*        }*/}

                                    {/*    </Select>*/}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={4} className={'search-item'}>
                                    {/*<Form.Item*/}
                                    {/*>*/}
                                    {/*    <Button type="primary" htmlType="submit">*/}
                                    {/*        查询*/}
                                    {/*    </Button>*/}
                                    {/*    <Button>*/}
                                    {/*        重置*/}
                                    {/*    </Button>*/}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={6}  className='last-right-row last-row-input'>
                                    <Form.Item
                                        name='keywords'
                                    >
                                        <Input suffix={
                                            <IconSearch onClick={this.searchList} />
                                        } placeholder={'请输入角色名搜索'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <Table columns={this.state.columns}
                           scroll={{ x: 1200 }}
                           rowKey={'ID'}
                           pagination={false}
                           dataSource={this.state.data}/>
                    {this.state.total > 0 &&
                    <div className={'page-total-data'}>
                        <div>
                            共计 <span className={'total-num'}>{this.state.total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.pageChange}
                            defaultCurrent={1}
                            total={this.state.total}
                            pageSize={10}
                            current={this.state.currentPage}
                        />
                    </div>}
                </div>
                <AddDialog ref={this.addDialogRef} />
                <Modal
                    visible={isConfirmVisible}
                    cancelText={'取消'}
                    width={'458px'}
                    okText={'确定'}
                    title={'提示'}
                    centered
                    onOk={this.handleOk}
                    className="warning-dialog"
                    onCancel={this.handleCancel}>
                    <div className="warning-content">
                        <IconWarning />
                        <p className='account-modal-title logout-title'>
                            {
                                data[editIndex]?.Disabled ? '启用角色后，该角色的用户恢复使用，确定启用该角色？'
                                    : '禁用角色后，使用该角色的用户无法使用，确定禁用该角色？'
                            }

                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}
