import React from 'react';
import { Button } from 'antd';


import './index.scss'
import evidenceGuide from './../../resource/images/guide/img-evidence-guide.svg';
import iconForensics from './../../resource/images/guide/icon-evidence-1.svg';
import iconFulfil from './../../resource/images/guide/icon-evidence-2.svg';



export default class EvidenceGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowGuide: true
        }
    }

    componentDidMount() {
    }

    hideModule() {
        this.setState({
            isShowGuide: false,
        })
    }

    render() {
        return (
            <div className={ this.state.isShowGuide ? 'evidence-guide-container container-shadow': 'hide-container evidence-guide-container container-shadow'}>
               <div className="guide-img-box">
                   <img src={evidenceGuide} alt="" className="img-guide"/>
                   <span className="forensics-box">
                       <img src={iconForensics} alt="" className="icon-forensics"/>
                   </span>
                   <span className="fulfil-box">
                       <img src={iconFulfil} alt="" className="icon-fulfil"/>
                   </span>
               </div>
                <p className="text">
                    侵权取证产品，能够实时固化、保存侵权页面的电子数据，并上传至联盟链中。简单快捷，保障您的创作权益。
                </p>
                <Button className={'submit-btn'} type="primary" htmlType="submit" onClick={()=>this.hideModule()}>
                    立刻体验
                </Button>
            </div>
        )
    }
}