import {
    regexps_img_code, regexps_length_20,
    regexps_length_150,
    regexps_length_4,
    regexps_length_30,
    regexps_length_300
} from './validates'


export const rules = {
    not_null: [
        {
            required: true,
            message: '该项不能为空',
        }
    ],
    regexps_length_20: [
        {
            required: true,
            message: '该项不能为空',
        },
        {
            pattern:regexps_length_20,
            message: '请输入1-20个字符',
        }

    ],
    regexps_length_30: [
        {
            required: true,
            message: '该项不能为空',
        },
        {
            pattern:regexps_length_30,
            message: '请输入1-30个字符',
        }

    ],

    regexps_length_150: [
        {
            required: true,
            message: '该项不能为空',
        },
        {
            pattern:regexps_length_150,
            message: '请输入1-150个字符',
        }

    ],//regexps_length_300
    regexps_length_150_not_null: [
        {
            required: false,
            message: '该项不能为空',
        },
        {
            pattern:regexps_length_150,
            message: '请输入0-150个字符',
        }

    ],
    regexps_length_300: [
        {
            required: true,
            message: '该项不能为空',
        },
        {
            pattern:regexps_length_300,
            message: '请输入1-300个字符',
        }

    ],
    password: [
        {
            required: true,
            message: '该项不能为空',
        },
        {
            pattern: regexps_length_4,
            message: '请输入4-20个字符',
        },
    ],
    img_code: [
        {
            required: true,
            message: '该项不可为空',
        },
        {
            pattern: regexps_img_code,
            message: '格式错误',
        },
    ],
    not_null_select: [
        {
            required: true,
            message: '请选择',
        }
    ],
}
