import React, {Component} from 'react'
import {Input, Form, Select, Modal, Switch} from 'antd';
import {rules} from '../../lib/help/rules'
import {agencyRole} from '../../lib/help/helper'

const {not_null, regexps_length_30} = rules


const {Option} = Select

export default class index extends Component {
    formRef = React.createRef();
    state = {
        isModalVisible: false,
        editRowData: this.props.editRowData,
        checked: false
    }

    componentDidMount() {
    }

    show = () => {
        if(this.props.editRowData?.Name) {
            this.setState({
                isModalVisible: true
            }, ()=> {
                this.formRef.current.setFieldsValue({
                    permission: this.props.editRowData?.Permission,
                    name: this.props.editRowData?.Name,
                    enable:  !Boolean(this.props.editRowData?.Disabled)
                })
                this.setState({
                    checked: !this.props.editRowData?.Disabled
                })
            })
        }else {
            this.setState({
                isModalVisible: true
            })
        }
    }
    close = () => {
        this.formRef.current.resetFields()
        this.setState({
            isModalVisible: false
        })
    }
    handleOk = async () => {
        let val = await this.formRef.current.validateFields();
        this.props.callback && this.props.callback(val);
    }
    switchChange = () => {
        this.setState({
            checked: !this.state.checked
        })
        this.formRef.current.setFieldsValue({
            enable: !this.state.checked
        })
    }

    render() {
        const {isModalVisible, checked} = this.state;
        return (
            <Modal
                visible={isModalVisible}
                cancelText={'取消'}
                okText={'提交'}
                title={'新增机构'}
                width={'600px'}
                onOk={this.handleOk}
                onCancel={this.close}
                className="form-content agency-modal-container"
            >
                <Form
                    name="basic"
                    ref={this.formRef}
                    onFinish={(values) => this.onFinish(values)}
                >
                    <Form.Item
                        label="机构名称"
                        name="name"
                        rules={regexps_length_30}
                        validateFirst={true}
                    >
                        <Input size="middle" placeholder={'请输入'}/>
                    </Form.Item>
                    <Form.Item
                        label="权限"
                        name="permission"
                        className="select-label"
                        rules={not_null}
                        validateFirst={true}
                    >
                        <Select placeholder="请选择" className={'search-select'}>
                            {
                                agencyRole.map((item) => {
                                    return <Option value={item.key} name={item.key} key={item.key}>{item.value}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                    {
                        this.props.editRowData?.Name &&
                        <Form.Item
                            label="状态"
                            name="enable"
                            className="select-label"
                            validateFirst={true}
                        >
                            <div>
                                <Switch checked={checked}  onClick={this.switchChange} />
                                <span className='edit-switch-text'>{checked ? '启用' : '禁用'}</span>
                            </div>
                        </Form.Item>
                    }
                </Form>
            </Modal>

        )
    }
}
