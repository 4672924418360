import React from 'react';
import {Modal, message} from 'antd';
import {withRouter} from "react-router-dom";
import {ExclamationCircleOutlined} from '@ant-design/icons';

import './index.scss'
import defaultAvatar from './../../resource/images/header/default-avatar.svg'
import http from "../../lib/service/http";
import Cookie from "js-cookie";
import logo from "../../resource/images/header/header-logo.svg";


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}
        }
    }

    componentDidMount() {
        let tmpInfo = localStorage.getItem('userInfo');
        if (tmpInfo) {
            tmpInfo = JSON.parse(tmpInfo);
            this.setState({
                userInfo: tmpInfo
            })
        }
    }

    logout() {
        this.props.history.replace('/');
        http.post('/auth/logout').then(() => {
            localStorage.clear();
            Cookie.remove('token');
            message.success('登出成功');
            this.handleCancel()
        })
    }
    handleOk = () => {
        this.logout();
    }
    handleCancel = () => {
        this.setState({
            isConfirmVisible: false
        })
    }
    showModal = () => {
        this.setState({
            isConfirmVisible: true
        })
    }

    render() {
        let tmpQuery = this.props.location.search.split('&');
        let tokenQuery = tmpQuery.filter(item=>item.indexOf('token') > -1)
        if(tokenQuery.length > 0) {
            return null
        }
        let userInfo = localStorage.getItem('userInfo') || '{}';
        if(userInfo){
            userInfo = JSON.parse(userInfo);
        }
        const {isConfirmVisible} = this.state
        return (
            <div className={"header-container content-header"}>
                <div className="header-content">
                    <img src={logo} alt="" className="header-logo"/>
                    <div className="avatar-box">
                        <img src={defaultAvatar} alt="" style={{width: '23px'}} className="avatar-img"/>
                        <p className="user-name">{userInfo.UserName}</p>
                        <p className="logout-btn" onClick={this.showModal}>退出</p>
                    </div>
                </div>
                <Modal
                  visible={isConfirmVisible}
                  cancelText={'取消'}
                  okText={'确定'}
                  centered
                  onOk={this.handleOk}
                  className="set-padding"
                  onCancel={this.handleCancel}>
                    <p className='account-modal-title logout-title'>
                        <ExclamationCircleOutlined className="icon-waring" style={{'marginRight': '8px'}}/>
                        确定退出登录吗?</p>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Header)