import React, {Component} from 'react'
import {Input, Form, Space, Table, Button, Row, Col, Select, DatePicker, Pagination, Popover} from 'antd';
import './index.scss'
import {downloadBinaryFileStream, localTime, getUserRole} from "../../lib/help/helper";
import {Link} from "react-router-dom";
import http from "../../lib/service/http";
import iconInfo from '../../resource/images/common/icon-info.svg'
import iconQueryTitle from '../../resource/images/access-query/icon-query-title.svg'
import imgTipContent from '../../resource/images/access-query/img-tip-content.svg'
import moment from 'moment'
let roleOptions = getUserRole()

const {Option} = Select
const {RangePicker} = DatePicker;
export default class index extends Component {
    formRef = React.createRef();
    filterFormRef = React.createRef();
    state = {
        searchInputVal: '',
        clearBtn: false,
        total: 0,
        searchVal: {
            evidence_type: '',
            phase: '',
            start_date: '',
            end_date: '',
            keywords: ''
        },
        data: [],
        acceptor: [],
        selectedRowKeys: [],
        currentPage: 1,
        evidenceType: [],
        phaseId: [],
        columns: [
            {
                title: '存证编号',
                dataIndex: 'NO',
                key: 'NO',
                fixed: 'left',
                render: text => {
                    return text ? <Popover content={text} trigger="hover">
                        <span className="list-desc">{text}</span>
                    </Popover> : '- -'
                }
            },
            {
                title: '存证名称',
                dataIndex: 'Name',
                key: 'Name',
                render: text => {
                    return text ? <Popover content={text} trigger="hover">
                        <span className="list-desc">{text}</span>
                    </Popover> : '- -'
                }
            },
            {
                title: '存证类型',
                dataIndex: 'EvidenceType',
                key: 'EvidenceType',
                render: (row) => {
                    return row? <Popover content={row.name+' '+row.evidence_sub_name+' '+row.evidence_project_name} trigger="hover">
                        <span className="list-desc">{row.name+' '+row.evidence_sub_name+' '+row.evidence_project_name}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '存证阶段',
                dataIndex: 'Phase',
                key: 'Phase',
                render: (row) => {
                    return row? <Popover content={row.name} trigger="hover">
                        <span className="list-desc">{row.name}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '经办人',
                dataIndex: 'OperatorDepartment',
                key: 'OperatorDepartment',
                render: (row) => {
                    return row? <Popover content={row.name+' '+row.operator_name} trigger="hover">
                        <span className="list-desc">{row.name+' '+row.operator_name}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '存证描述',
                dataIndex: 'Description',
                key:'Description',
                render: (val) => {
                    return val? <Popover content={val} trigger="hover">
                        <span className="list-desc">{val}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '存证时间',
                dataIndex: 'CreatedAt',
                render: (val) => {
                    return val? <Popover content={localTime(val)} trigger="hover">
                        <span className="list-desc">{localTime(val)}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '存证标签',
                dataIndex: 'Label',
                key: 'Label',
                render: (row) => {
                    return row? <Popover content={row.name} trigger="hover">
                        <span className="list-desc">{row.name}</span>
                    </Popover>:'- -'
                }
            },
            {
                title: '存证状态',
                dataIndex: 'Status',
                key:'Status',
                render: (Status) => {
                    return <Popover content={Status==='PENDING'?'制证中':Status==='FINISHED'?'制证成功':Status==='FAILED'?'制证失败':'制证中'} trigger="hover">
                        <span className="list-desc">{Status==='PENDING'?'制证中':Status==='FINISHED'?'制证成功':Status==='FAILED'?'制证失败':'制证中'}</span>
                    </Popover>
                }
            },
            {
                title: '操作',
                width: 170,
                key: 'NO',
                fixed: 'right',
                render: (text, record) => (
                    <Space size="small">
                        <Link
                            to={{
                                pathname: `/certificate-query/${record.NO}`,
                            }}>
                            详情
                        </Link>

                        {
                            roleOptions.includes('取证-下载') &&
                            <p onClick={()=>this.export(record.NO)} className={'has-line-item'}
                               rel="noopener noreferrer">
                                下载报告
                            </p>
                        }
                    </Space>
                ),
            },
        ]
    }


    clearSearchValue = async () => {
        this.formRef.current.setFieldsValue({keywords: ''});
        this.filterFormRef.current.resetFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                keywords: '',
                evidence_type: '',
                phase: '',
                start_date: '',
                end_date: '',
            },
            clearBtn: false,
            currentPage: 1,
        }, () => {
        })
    }

    onChange = (e) => {
        if (e.target.value !== '') {
            this.setState({
                clearBtn: true
            })
        } else {
            this.setState({
                clearBtn: false
            })
        }
        this.setState({
            searchInputVal: e.target.value
        })
    }
    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };
    clearSelect = () => {
        this.setState({
            selectedRowKeys: []
        });
    }

    componentDidMount() {
        this.getSearchData()
    }

    getSearchData = () => {
        http.get('/search/copyright/config-filter').then(res => {
            if (res) {
                this.setState({
                    evidenceType: res.data.evidence_type.items||[],
                    phaseId: res.data.phase.items||[]
                })
            }
        })

    };


    getList = () => {
        let params = {
            ...this.state.searchVal,
            page: this.state.currentPage
        };
        for (let key in params) {
            if (!params[key]) {
                delete params[key]
            }
        }
        delete params.time;
        params.page = this.state.currentPage;
        http.get(`/search/copyright`, params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items ? res.data.items : [],
                    total: res.data.total,
                    page: res.data.total_page,
                    currentPage: res.data.current_page,
                    selectedRowKeys: []
                })
            }
        })
    }

    filterSearchList = async (values) => {
        if (values.time) {
            values.start_date = moment(values.time[0]).format("YYYY-MM-DD") + ' 00:00:00'
            values.end_date = moment(values.time[1]).format("YYYY-MM-DD") + ' 23:59:59'
        }
        const formValues = await this.formRef.current.validateFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                ...formValues,
                ...values,
            },
            currentPage: 1,
        }, () => {
            this.getList()
        })
    }

    resetFrom = () => {
        this.filterFormRef.current.resetFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                evidence_type: '',
                phase: '',
                start_date: '',
                end_date: '',
            },
            currentPage: 1,
        }, () => {
            this.getList()
        })
    }
    searchValFinish = async (keywords) => {
        const formValues = await this.filterFormRef.current.validateFields();
        const searchVal =  await this.formRef.current.validateFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                ...formValues,
                ...searchVal
            },
            currentPage: 1
        }, () => {
            this.getList();
        })
    }
    pageChange = (page) => {
        this.setState({
            currentPage: page,
            selectedRowKeys: []
        }, () => {
            this.getList()
        })
    }

    export = (id) => {
        const {selectedRowKeys, data} = this.state
        let no_arr = []
        for(let i=0; i<selectedRowKeys.length; i++) {
            let tmpItem = data.filter(item=>item.ID === selectedRowKeys[i])
            if(tmpItem.length > 0) {
                no_arr.push(tmpItem[0].NO)
            }
        }
        if(id) {
            no_arr.push(id)
        }

        // no_arr.push('664ac0f7-c23b-4e01-80c2-f95fd9527515', 'f05871ec-0397-46d0-b0d9-9361a19d5112')
        let params = {
            no_arr
        }
        http.post('search/copyright/export', params, {responseType: 'arraybuffer', isExport: true}).then(res => {
            if (res) {
                downloadBinaryFileStream(res)
            }
        })
    }

    render() {
        const {Search} = Input;
        const {selectedRowKeys, evidenceType, phaseId, searchVal} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        roleOptions = getUserRole()
        return (
            <div className='access-query'>
                <div className="page-title">
                    <div className="title-left">
                        <p className="title">取证查询</p>
                    </div>
                </div>
                <section className={searchVal.keywords? 'search-block' : 'search-block no-data-block'}>
                    <header>取证查询</header>
                    <Form
                        ref={this.formRef}
                        onFinish={this.searchValFinish}
                    >
                        <Form.Item
                            name='keywords'
                            className={'query-search-name'}
                            rules={[{required: true, message: '搜索内容不能为空！'}]}>
                            <Search
                                className='search-box'
                                placeholder="可根据存证名称进行模糊搜索"
                                enterButton="搜索"
                                size="large"
                                autoComplete={'off'}
                                addonAfter={
                                    (
                                        <>
                                            {
                                                this.state.clearBtn ?
                                                    <Button type={'link'} className="clear-btn"
                                                            onClick={this.clearSearchValue}>清空</Button>
                                                    : ''}
                                        </>
                                    )

                                }
                                onSearch={this.searchValFinish}
                                onChange={this.onChange}
                            />
                        </Form.Item>
                    </Form>
                    {
                        !searchVal.keywords && <div className="query-no-data-container">
                            <p className="title">
                                <img src={iconQueryTitle} alt=""/>
                                存证查询流程
                            </p>
                            <img src={imgTipContent} className={'content-tip'} alt=""/>
                        </div>
                    }
                </section>
                {
                    <div className={searchVal.keywords ? 'info-content container-shadow container' : 'info-content container-shadow container hide-table-list'} >
                        <div className="list-search-box">
                            <Form
                                ref={this.filterFormRef}
                                onFinish={this.filterSearchList}
                            >
                                <Row gutter={16}>
                                    <Col span={5}>
                                        <Form.Item
                                            label={'存证类型'}
                                            name='evidence_type'
                                            className={'search-select'}
                                        >
                                            <Select placeholder="请选择">
                                                {
                                                    evidenceType.map((item) => {
                                                        return <Option value={item.Name} key={item.Name}
                                                                       label={item.Name}>{item.Name}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label={'存证阶段'}
                                            name='phase'
                                            className={'search-select'}
                                        >
                                            <Select placeholder="请选择">
                                                {
                                                    phaseId.map((item) => {
                                                        return <Option value={item.Name} key={item.Name}
                                                                       label={item.Name}>{item.Name}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label={'存证时间'}
                                            name='time'
                                        >
                                            <RangePicker/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className={'search-item'}>
                                        <Form.Item
                                        >
                                            <Button type="primary" htmlType="submit">
                                                查询
                                            </Button>
                                            <Button onClick={this.resetFrom}>
                                                重置
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className='last-right-row'>
                                        {
                                            roleOptions.includes('取证-下载') &&
                                            <Button disabled={selectedRowKeys.length === 0} type="primary" onClick={() => this.export()}>
                                                导出
                                            </Button>
                                        }

                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        {
                            selectedRowKeys.length > 0 &&
                            <div className="select-number-box">
                                <img src={iconInfo} alt=""/>
                                <p className="text">
                                    已选择{selectedRowKeys.length}项
                                </p>
                                <Button type="link" onClick={this.clearSelect}>清空</Button>
                            </div>
                        }
                        <Table columns={this.state.columns}
                               rowKey={'ID'}
                               scroll={{ x: 1200 }}
                               pagination={false}
                               rowSelection={roleOptions.includes('取证-下载') && rowSelection}
                               dataSource={this.state.data}/>
                        {this.state.total > 0 &&
                        <div className={'page-total-data'}>
                            <div>
                                共计 <span className={'total-num'}>{this.state.total}</span> 条数据
                            </div>
                            <Pagination
                                className={'pageStyle'}
                                onChange={this.pageChange}
                                defaultCurrent={1}
                                total={this.state.total}
                                pageSize={10}
                                current={this.state.currentPage}
                            />
                        </div>}
                    </div>
                }


            </div>
        )
    }
}
