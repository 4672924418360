import React from 'react';
import {Table, ConfigProvider, Input,  Popover, Empty, Pagination} from 'antd';
import {SearchOutlined, PlusOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import CertDialog from "../../components/CertDialog";
import './index.scss'
import iconList from "../../resource/images/evidence-list/icon-obtain-evidence-list.svg";
import iconDownloadActive from "../../resource/images/evidence-list/icon-download-h.svg";
import iconDownloadNormal from "../../resource/images/evidence-list/icon-download-n.svg";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";



export default class EvidenceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true,
            dialogComponent: '',
            certUrl: '',
            dataList: [
            ],
            responseData: {
                total: 0,
            },
            page: 1,
            keywords: '',
            certNo: '',
            searchValue: ''
        }
    }

    showDialog(url, certNo) {
        this.setState({
            certUrl: url,
            certNo: certNo
        }, ()=>{
            this.child.showDialog();
        })
    }
    setPages(page, pageSize) {
        this.setState({
            page: page
        },  ()=>{
            this.getListData()
        })
    };
    getSearchList(e) {
        this.setState({
            searchValue: e.target.value,
        })
    }
    getSearchRes() {
        this.setState({
            keywords: this.state.searchValue,
            page: 1
        }, ()=> {
            this.getListData()
        })
    }

    getListData() {
        let params = {
            page: this.state.page,
            keywords: this.state.keywords
        }
        http.get('/infringement/list', params).then(res=>{
            this.setState({
                dataList: res.items,
                responseData: res,
            })
        })
    }
    componentDidMount() {
        this.getListData();
    }
    setListTitle() {
        return [
            {
                title: '取证网址',
                dataIndex: 'Url',
                key: 'Url',
                width: 300,
                render: text => {
                    return (
                        <Popover content={
                            <span>{text}</span>
                        }
                                 placement={'bottomLeft'}
                        >
                            <p className={'hash-content content-remark'}>{text}</p>
                        </Popover>
                    )
                },
            },
            {
                title: '取证时间',
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                render: time => {
                    return localTime(time)
                }
            },
            {
                title: '查看详情',
                dataIndex: 'TmpCertificateUrl',
                key: 'TmpCertificateUrl',
                render: (TmpCertificateUrl,rows) => {
                    return (
                        !TmpCertificateUrl ?
                            <span className={'detail-item item-default'}>暂无详情</span> :
                            <span onClick={()=>this.showDialog(TmpCertificateUrl, rows.NO)} className={'detail-item item-active'}>查看证书</span>
                    )
                }
            },
            {
                title: '备注',
                key: 'Remark',
                dataIndex: 'Remark',
                width: 300,
                render: text => {
                    return (
                        <Popover content={
                            <span>{text}</span>
                        }
                                 placement={'bottomLeft'}
                        >
                            <p className={'hash-content content-remark'}>{text}</p>
                        </Popover>
                    )
                },
            },
            {
                title: '操作',
                key: 'TmpFileUrl',
                dataIndex: 'TmpFileUrl',
                render: (TmpFileUrl, rows) => {
                    return (
                        <div>
                            {
                                rows.FileStatus === 'FINISHED' &&
                                <Popover
                                    className={'download-popover'}
                                    placement={'bottom'}
                                    content={
                                        <div className="tip-box">
                                            <div className="top">
                                                <img src={iconDownloadActive} alt="" className="top-icon" />
                                                <p className="tip-text">
                                                    下载完整证据包
                                                </p>
                                            </div>
                                            <div className="message">
                                                证据包包含网页截图、网页源码、加载资源及取证日志等文件
                                            </div>
                                        </div>
                                    }
                                >
                                    <a href={TmpFileUrl} target="_blank" rel="noopener noreferrer">
                                        <img className={'icon-download'} src={iconDownloadActive} alt=""/>
                                    </a>

                                </Popover>
                            }
                            {
                                rows.FileStatus === 'FAILED' && <p className={'download-file'}>证据包提取失败</p>
                            }
                            {
                                rows.FileStatus === 'PENDING' &&
                                <span>
                                    <img className={'icon-download'} src={iconDownloadNormal} alt=""/>
                                    <span className={'action-text'}>证据包正在提取中...</span>
                                </span>
                            }
                        </div>


                    );
                },
            },
        ];
    };
    emptyContent() {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <span>暂无数据</span>
            } />
        )
    }

    render() {
        const props = {
            current: this.state.page,
            position: 'bottomCenter',
            showSizeChanger: false,
            total: this.state.responseData.total,
            onChange: (page, pageSize) => this.setPages(page, pageSize)
        }
        return (
            <div className="evidence-list-container container-shadow">
                <div className="content-title">
                    <img src={iconList} alt="" className="title-icon"/>
                    <span className="text">
                        取证列表
                    </span>
                </div>
                <div className="evidence-list-content">
                    <div className="list-search-box">
                        <Input className={'search-input'} onChange={(event) => this.getSearchList(event)} placeholder="输入取证地址关键字搜索登记作品"/>
                        <div className="search-btn" onClick={()=>this.getSearchRes()}>
                            <SearchOutlined/>
                        </div>
                    </div>
                    <ConfigProvider renderEmpty={this.emptyContent}>
                        <Table className={this.state.dataList ? 'list-box' : 'list-box list-no-data-box'}
                               columns={this.setListTitle()}
                               pagination={false}
                               rowKey={'ID'}
                               dataSource={this.state.dataList}/>
                        <Link to={'/obtain-evidence/add'} className={'add-box'}>
                            <div className="add-icon-box">
                                <PlusOutlined />
                            </div>
                            添加区块链侵权取证
                        </Link>
                        <Pagination {...props} />
                    </ConfigProvider>
                </div>

                <CertDialog certNo={this.state.certNo} type={'evidence'} certLink={this.state.certUrl} onRef={(ref)=>{ this.child = ref}}  />
            </div>
        )
    }
}