import React from 'react';
import './index.scss'
import iconAddTitle from './../../resource/images/evidence-add/icon_qzlb.svg'
import http from "../../lib/service/http";
import {Link} from 'react-router-dom';



export default class EvidenceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responseData: {},
            renderData: {
                registrationDetails: [],
                worksDetail: [],
            },
            typeData:[]
        }
    }


    componentDidMount() {
        this.setState({
            typeData: [this.props.match.params.id, this.props.match.params.type]
        },()=>{
            this.getDetail();
        })
    }

    getDetail() {
        let sendUrl = '/infringement/detail/';
        if(this.state.typeData[1] === 'copyright') {
            sendUrl = '/copyright/detail/'
        }
        http.get(sendUrl+this.state.typeData[0]).then(res=>{
            this.setState({
                responseData: res.detail,
            })
            this.setRenderData(res.detail)
        }, ()=>{
            this.props.history.replace('/index');
        })
    }
    setRenderData(resData) {
        if(this.state.typeData[1] === 'copyright') {
            this.setState({
                renderData: {
                    registrationDetails: [
                        {
                            label: '作品',
                            content: `《${resData.Title}》`
                        },
                        {
                            label: '作者',
                            content: `${resData.Authors}`
                        },
                        {
                            label: '完成确权时间',
                            content: `区块链网络全球通用协调时间：${JSON.parse(resData.CertificateData).utc_time} \n同步北京时间（东八区）：${JSON.parse(resData.CertificateData).beijing_time}`
                        },
                        {
                            label: '区块链ID',
                            content: `${JSON.parse(resData.CertificateData).transaction_hash.substr(0, 12)}`
                        },
                        {
                            label: '原创权利登记声明',
                            content: `本电子数据经过区块链网络进行数据保全。本证书作为该电子数据保全的有效证明，具备相应的法律效力。`
                        },
                    ],
                    worksDetail: [
                        {
                            label: '作品',
                            content: `《${resData.Title}》`
                        },
                        {
                            label: '作者',
                            content: `${resData.Authors}`
                        },
                        {
                            label: '存证数据（文件md5码）',
                            content: `${JSON.parse(resData.CertificateData).evidence_hash}`
                        },
                    ]
                }
            })
        } else {
            this.setState({
                renderData: {
                    registrationDetails: [
                        {
                            label: '取证网址',
                            content: `${resData.Url}`
                        },
                        {
                            label: '完成确权时间',
                            content: `区块链网络全球通用协调时间：${JSON.parse(resData.CertificateData).utc_time} \n同步北京时间（东八区）：${JSON.parse(resData.CertificateData).beijing_time}`
                        },
                        {
                            label: '区块链ID',
                            content: `${JSON.parse(resData.CertificateData).transaction_hash.substr(0, 12)}`
                        },
                        {
                            label: '原创权利登记声明',
                            content: `本电子数据经过区块链网络进行数据保全，本证书作为该电子数据保全的有效证明，具备相应的法律效力。`
                        },
                    ],
                    worksDetail: [
                        {
                            label: '取证网址',
                            content: `${resData.Url}`
                        },
                        {
                            label: '侵权取证（文件MD5码）',
                            content: `${JSON.parse(resData.CertificateData).evidence_hash}`
                        },
                    ]
                }
            })
        }
    }

    render() {
        return (
            <div className="cert-detail-container container-shadow">
                <div className="content-title">
                    <img src={iconAddTitle} alt="" className="title-icon"/>
                    <span className="text">
                            证书详情
                        </span>
                </div>
                <div className="cert-content">
                    <div className="registration-details">
                        <div className="block-copyright-desc">
                            <p className="title">区块链说明</p>
                            <div className="detail-list">
                                <ul className="detail-list-text">
                                    <li className="detail-list-font">
                                        <span className="detail-list-icon"/> 根据“谁先创作、谁先申请，谁就拥有著作权”的原则，将电子数据信息加盖时间戳写入区块链，符合国际X9.95协议，证明电子数据的创作发布时间。
                                    </li>
                                    <li className="detail-list-font">
                                        <span
                                            className="detail-list-icon"/> 经由区块链网络，电子数据保全内容加密上传，拥有唯一区块链电子数据保全ID，数据将永久保存，不可篡改。
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="obtain-cert-block cert-block border-bottom-radius">
                        <div className="cert-con-title">登记详情</div>
                        <div className="obtain-table-list product-info-table">
                            <table>
                                <tbody>
                                {
                                    this.state.renderData.registrationDetails.map(item=>{
                                        return (
                                            <tr key={item.label}>
                                                <td className="left-tit">{item.label}</td>
                                                <td className="obtain-right-con">
                                                    <span>{item.content}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="original-info obtain-cert-block content-padding">
                        <div className="cert-con-title content-title">登记信息</div>
                        <div className="content-works">
                            <table>
                                <tbody>
                                {
                                    this.state.renderData.worksDetail.map(item=>{
                                        return (
                                            <tr key={item.label}>
                                                <td className="left-tit">{item.label}</td>
                                                <td className="obtain-right-con obtain-name">
                                                    <span>{item.content}</span></td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td className="left-tit">作品证书</td>
                                    <td className="obtain-right-con">
                                        <div className="td-download-card">
                                            <div className="download-img"><a
                                                href={this.state.responseData.TmpCertificateUrl}
                                                rel="noopener noreferrer"
                                                target={'_blank'}>
                                                {
                                                    this.state.responseData.TmpCertificateUrl &&
                                                    <img src={this.state.responseData.TmpCertificateUrl}
                                                        className="cert" alt={'cert'}/>
                                                }
                                            </a></div>
                                            <div className="download-text"><a
                                                href={this.state.responseData.TmpCertificateUrl}
                                                rel="noopener noreferrer"
                                                target="_blank" className="font-col-gray"><i
                                                className="iconfont"></i>&nbsp;&nbsp;点击查看下载证书</a></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="original-info obtain-cert-block content-padding">
                        <div className="content-title uk-clearfix">
                            <div className="cert-con-title uk-float-left">区块链数据信息</div>
                            <Link to={{
                                pathname:'/obtain-evidence/blockchiain/transaction/'+this.props.match.params.type+'/'+this.props.match.params.id,
                            }}

                               className="uk-float-right view-more-margin uk-display-inline-block font-size-14 font-col-red">查看更多<i
                                className="iconfont font-size-16 font-col-red"></i></Link></div>
                        <div className="content-chain">
                            {/*<div className="data-list"><p className="list-title">区块地址： <a*/}
                            {/*    href="http://browser.ziggurat.cn/#/blockinfo/8b16fc4190666e44872e62c7c00f0828b5604e0d8a8f8a6cc78ee61ba8a2912d"*/}
                            {/*    target="_blank"><span*/}
                            {/*    className="list-text">8b16fc4190666e44872e62c7c00f0828b5604e0d8a8f8a6cc78ee61ba8a2912d</span></a>*/}
                            {/*</p></div>*/}
                            <div className="data-list"><p className="list-title">交易地址： <span><span className="list-text">{this.state.responseData.Hash}</span></span>
                            </p></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}