import React from 'react';
import {Form, Input, Pagination, Select, Table, Space, Switch, Modal, message, Popover} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss'
import Edit from "./Modal/edit"
import http from './../../lib/service/http'
import searchInputIcon from './../../resource/images/common/input-search.jpg'
import closeImg from "./../../resource/images/common/cancle.svg";
import {getUserRole,} from "../../lib/help/helper";
import {ReactComponent as IconWarning} from "../../resource/images/common/icon-warning.svg";
const {Option} = Select;
let roleOptions = getUserRole()

export default class CertInfoConfig extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            total:1,
            optionsList:[],
            visible: false,
            certInfo:{
                name:1
            },
            data: [],
            currentPage: 1,
            keywords:'',
            isConfirmVisible:false,
            editIndex: 0,
            columns: [
                {
                    title: '名称',
                    dataIndex: 'Name',
                    key: 'Name',
                    MaxWidth: 220,
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '存证信息',
                    dataIndex: 'OptionName',
                    key: 'OptionName',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '所属机构',
                    dataIndex: 'InstitutionName',
                    key: 'InstitutionName',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '备注',
                    dataIndex: 'Remark',
                    key: 'Remark',
                    MaxWidth: 250,
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '启用丨禁止',
                    dataIndex: 'Disabled',
                    key: 'Disabled',
                    render: (val,row,index) => {
                        return <div>
                            {/*{*/}
                            {roleOptions.includes('存证信息配置-编辑')?
                                <Switch checked={val === 0}
                                        onChange={(checked, event) => this.changeStatus(checked, row.ID, event, index)}/>:''}
                            <span className='edit-switch-text'>{val === 0 ? '启用' : '禁用'}</span>
                            {/*    </div> : '- -'*/}
                            {/*}*/}
                        </div>;
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle" className={'operate-btn'}>
                            {
                                roleOptions.includes('存证信息配置-编辑') ?
                                <a onClick={this.edit.bind(this, record)}>编辑</a> : '- -'
                            }
                        </Space>
                    ),
                },
            ]
        }
    }

    componentDidMount() {
        let tmp= {
            page:1,
            keywords:'',
            option:'',
        };
        this.getList(tmp)
        this.getOptionsList()
    }
    changeStatus(checked, ID, event, index) {
        this.setState({
            editID: ID,
            editIndex: index,
            isConfirmVisible: true
        })
    }
    handleOk = () => {
        const {data, editIndex} = this.state;
        let params = {
            id: this.state.editID,
            page : this.state.currentPage
        };
        http.post('/copyright-config/disable', params).then(res => {
            message.success(data[editIndex]?.Disabled ? '启用成功' : '禁用成功')
            let tmp= {
                page:this.state.currentPage,
                option: '',
                keywords:''
            };
            this.getList(tmp)
            this.setState({
                isConfirmVisible: false
            })
        }, () => {

        });
    }
    edit = (item)=> {
        // this.setState({
        //     certInfo: item
        // });
        this.setState({
            visible: true,
            certInfo: item
        });
    };
    getOptionsList=()=>{
        http.get('/copyright-config/options').then(res => {
            if (res) {
                this.setState({
                    optionsList: res.data.items||[],
                })
            }
        })
    }
    downLoad(item) {
    };
    search = () => {
        let params= {
            option: this.formRef.current.getFieldValue('option')  ||  '',
            keywords:this.state.keywords,
            page: ''
        };
        this.getList(params);
        this.setState({
            currentPage: 1,
        });
    };
    searchInput=()=> {
        let params= {
            option: this.formRef.current.getFieldValue('option')  ||  '',
            keywords:this.state.keywords,
            page: ''
        };
        this.getList(params);
        this.setState({
          //  currentPage: 1,
        });
    }
    reset = () => {
        this.formRef.current.resetFields();
        let params= {
            option: '',
            keywords: '',
            page: ''
        };
        this.getList(params);
        this.setState({
            currentPage: 1,
            keywords: ''
        });
    };
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            option: this.formRef.current.getFieldValue('option')  ||  '',
            keywords:this.state.keywords,
            page:page,
        };
        this.getList(params)
    };
    getList = (params) => {
        for(var key in params){
            if(params[key] ===''){
                delete params[key]
            }
        }
        http.get('copyright-config/list',params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items||[],
                    total: res.data.total
                })
            }
        })
    };
    getSearchVal = (e) => {
        this.setState({
            keywords: e.target.value
        })
        if (e.target.value.length===0) {
            let params= {
                option: this.formRef.current.getFieldValue('option')  ||  '',
                keywords:'',
                page: ''
            };
            this.getList(params);
        }
        // this.setState({
        //     currentPage: 1,
        // });
    };
    add=()=> {
        this.setState({
            visible: true,
        });
        this.setState({
            certInfo: {}
        });
    }
    handleCancel=()=> {
        this.setState({
            visible:false,
         isConfirmVisible:false
        })
    }
    closeModal = () => {
        this.setState({
            visible: false
        });
        this.reset();
    };
    render() {
        const {data,total,visible,isConfirmVisible,editIndex } = this.state;
         roleOptions = getUserRole()

        return (
            <div className={'cert-info-container certInfo'}>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        存证信息配置
                    </div>
                    <div className={'btn'}>
                        {
                            roleOptions.includes('存证信息配置-创建') &&
                            <span onClick={()=>this.add()} className="detail-btn" >+ 新增</span>
                        }
                        {/*<span className="detail-btn" onClick={() => this.add()}>+ 新增</span>*/}
                    </div>
                </div>
                <div className={'search-box'}>
                    <div style={{display:"flex"}}>
                        <Form
                            className="search-form"
                            name="basic"
                            ref={this.formRef}
                            layout={'inline'}
                        >
                            <div style={{display:"flex"}}>
                                <span className={'search-title'}>存证信息:</span>
                                <Form.Item
                                    name="option"
                                    className={'input-select'}
                                >
                                    <Select placeholder="请选择">
                                        {
                                            this.state.optionsList.map((item) => {
                                                return <Option value={item.Option} key={item.Option} label={item.Name}>{item.Name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </div>
                        </Form>

                        <div className="item-enterprise btn">
                            <span className="detail-btn" onClick={() => this.search()}>查找</span>
                            <span className="detail-btn reset-btn" onClick={() => this.reset()}>重置</span>
                        </div>
                    </div>
                    <div className="search-input">
                        <Input size="large"
                               onChange={(event) => this.getSearchVal(event)}
                               value={this.state.keywords}
                               placeholder={'输入名称搜索'}
                               suffix={
                                   <img src={searchInputIcon}
                                        alt=""
                                        onClick={() => this.searchInput()}
                                        className='img-code'/>
                               }

                        />
                        {/*<div className="input-btn" onClick={() => this.search()}/>*/}
                    </div>
                </div>
               <div className={'table-data'}>
                    <Table columns={this.state.columns}  dataSource={data} pagination={false}  rowKey='ID'/>
                   {total>0&&
                    <div className={'page-total-data'}>
                        <div >
                            共计 <span className={'total-num'}>{total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.onChangePage.bind(this)}
                            defaultCurrent={1}
                            total={total}
                            pageSize={10}
                            current={this.state.currentPage}
                        />
                    </div>}
                </div>
                <Modal title={Object.keys(this.state.certInfo).length===0? '新增存证信息':'存证信息编辑'}
                       visible={visible}
                       closeIcon={<img src={closeImg} alt=""/>}
                       onCancel={this.handleCancel}
                       onOk={this.handleCancel}
                       okText={'确定'}
                       footer={null}
                       width={600}
                       wrapClassName='modal-edit'
                       defaultCurrent={10}
                       destroyOnClose={true}
                >
                    <Edit close={this.closeModal} certInfoData={this.state.certInfo}/>
                </Modal>
                <Modal
                    visible={isConfirmVisible}
                    cancelText={'取消'}
                    width={'458px'}
                    okText={'确定'}
                    title={'提示'}
                    centered
                    onOk={this.handleOk}
                    className="warning-dialog"
                    onCancel={this.handleCancel}>
                    <div className="warning-content">
                        <IconWarning />
                        <p className='account-modal-title logout-title'>
                            {
                                data[editIndex]?.Disabled ? '启用存证信息配置后，该存证信息配置恢复使用，确定启用该存证信息配置？'
                                    : '禁用存证信息配置后，该存证信息配置无法使用，确定禁用该存证信息配置？'
                            }

                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}