import React from 'react';
import {Form, Input,  Select, message, Button, Switch} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http'
import backUrl from "../../resource/images/common/go-back.svg"
import {rules} from "../../lib/help/rules";
import {regexps_length_30, regexps_length_4} from "../../lib/help/validates";
const { password} = rules;
const {Option} = Select;

export default class editUser extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            type:[
                {name: '类型1',value:1}
            ],
            subType: [],
            isEdit: false,
            passwordRules: [
                ...password,
                {
                    validator: this.handleCheckPwd,
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
            detailList:{},
            institution:{},
            roleData:[],
            institutionList:[],
            institutionID:null,
            Disabled:null
          //  roleId:null
        }
    }

    componentDidMount() {
        this.getInstitutionList()
        this.getListData(this.props.match.params.id)
    }
    getListData(params) {
        http.get(`/user/detail/${params}`).then(res => {
            let initialValues = {
                "username": res.data.user_obj.UserName,
                "institution_id":res.data.user_obj.InstitutionID,
                "role_id":res.data.user_obj.RoleID,
                "department":res.data.user_obj.Department,
            }
            this.setState({
                initialValues,
                institutionID: res.data.user_obj.InstitutionID,
                Disabled:res.data.user_obj.Disabled,

            });
            this.formRef.current.setFieldsValue(initialValues);
            this.getRoleList({institution_id:res.data.user_obj.InstitutionID})
        })
    };

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            callback()
        }
    };
    getInstitutionList=()=>{
        http.get('/institution/all').then(res => {
            if (res) {
                this.setState({
                    institutionList: res.data.items,
                })
            }
        })
    }
    getRoleList=(tmp)=>{
        http.get('/role/all',tmp).then(res => {
            if (res) {
                this.setState({
                    roleData: res.data.items,
                })
            }
        })
    }
    onFinish =(values) =>{
        const that =this;
        let  params = {
            ...values,
            user_id:Number(this.props.match.params.id),
            enable:this.state.Disabled === 0
        };

        http.post('/user/update', params).then(res => {
            message.success({
                content: '操作成功',
                duration:2,
                onCancel() {
                    that.props.history.push('/user-info/list');
                },
            });
            that.props.history.push('/user-info/list');
        }, () => {

        });
        this.setState({
            visible: false,
        });
    };
    handleCancel=()=> {
        this.props.history.push('/user-info/list');
    };
    onFinishFailed(errorInfo) {
    };
    goBack() {
        this.props.history.goBack();
    };
    onChangeType(e){
        this.setState({
            institutionID:e
        })
        this.getRoleList({institution_id:e})
    }
    onChangeRole() {
        if(this.state.institutionID===null) {
            message.error({
                content: '请先选择所属机构',
            });
        }
    }
    changeStatus=(val)=> {
        if(val) {
            this.setState({
                Disabled:0
            })
        } else {
            this.setState({
                Disabled:1
            })
        }
    }
    render() {
        const {institutionID} = this.state;
        return (
            <div className={'add-user-container certInfo'}>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        <img src={backUrl} alt="" onClick={() => this.goBack()} className={'breadcrumb-icon'}/>
                       编辑用户
                    </div>
                </div>
                <div className={'add-form'}>
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                    >
                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern: regexps_length_4,
                                    message: '请输入4-20个字符',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Input className={'name-input'} placeholder={'请输入用户名'}/>
                        </Form.Item>
                        <Form.Item
                            label="所属机构"
                            name="institution_id"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.institutionList.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="所属部门"
                            name="department"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    pattern: regexps_length_30,
                                    message: '请输入1-30个字符',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Input className={'add-select'} placeholder={'请输入部门'}/>
                        </Form.Item>
                        <Form.Item
                            label="角色"
                            name="role_id"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select disabled={institutionID===null} placeholder="请选择" className={'add-select'} onClick={() =>this.onChangeRole()}>
                                {
                                    this.state.roleData.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.RoleName}>{item.RoleName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="Disabled"
                            validateFirst={true}
                        >
                            <div>
                                <Switch checked={this.state.Disabled===0?true:false} onChange={(e)=>this.changeStatus(e)} />
                                <span className='edit-switch-text'>{this.state.Disabled===0 ? '启用' : '禁用'}</span>
                            </div>
                        </Form.Item>
                        <Form.Item className="footer-btn">
                            <Button className={'submit-btn detail-btn'} type="primary" htmlType="submit">
                                提交
                            </Button>
                            <Button className={'submit-btn detail-btn reset-btn'} type="primary" onClick={this.handleCancel}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}