import React from 'react';
import {Form,Input,Select,message, Button,Modal} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http'
import UploadFile from "../../components/Upload";
import backUrl from "../../resource/images/common/go-back.svg"
import {regexps_length_150,regexps_length_20,regexps_length_300} from "../../lib/help/validates";
const { TextArea } = Input;
const {Option} = Select;

export default class addChain extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            type:[
                {name: '类型1',value:1}
            ],
            subType: [],
            evidenceType:[],
            operator:[{Name:'人员1',ID:1}],
            label:[{Name:'标签1',ID:1}],
            phase:[]
        }
    }

    componentDidMount() {
        this.getOptionsData()
        this.isEmpty()
    }
    isEmpty=()=> {
        const that =this;
        http.get('/copyright/config').then(res => {
            if (res) {
                let tmp=Object.keys(res.data)
                try {
                    tmp.forEach((item, index) => {
                        if (res.data[item].items.length===0) {
                            Modal.confirm({
                                className: 'modal-confirm',
                                width: '416px',
                                title: (
                                    <div className={'disable-title'}>请先完善存证信息配置</div>
                                ),
                                okText: '去配置',
                                cancelText: '取消',
                                onCancel() {

                                },
                                onOk() {
                                    that.props.history.push('/cert-info-config/list');
                                },
                            });
                            throw new Error();
                        }
                    })
                } catch (e) {

                    return
                };
            }
        })
    }
    onFinish =(values) =>{
        const fileList=[]
        if (values.attachment) {
            const attr = values.attachment.filter((file)=>{
                    return file.status === 'done';
            });
            attr.forEach(item=> {
                let tmp={}
                tmp.file_path=item.url
                tmp.file_size=item.size
                tmp.file_name = item.name
                fileList.push(tmp)
            })
        }
        const label={id:values.label}
        const phase = {id:values.phase}
        const evidence= {
            id: values.evidence_type,
            evidence_sub_name:values.evidence_sub_name,
            evidence_project_name:values.evidence_project_name
        }
        const operator= {
            id:values.operator,
            operator_name:values.operator_name
        }
        const that =this;
        let  params = {
            name:values.name,
            attachments:fileList,
            label:label,
            phase:phase,
            evidence_type: evidence,
            operator_department:operator,
            description:values.description,
            enterprise_info:values.enterprise_info
        };
        http.post('copyright/store', params).then(res => {
            message.success('新增成功');
            that.props.history.push('/block-chain-cert/list');
        }, () => {

        });
        this.setState({
            visible: false,
        });
    };
    getOptionsData=()=>{
        http.get('/copyright/config').then(res => {
            if (res) {
                this.setState({
                    evidenceType:res.data.evidence_type.items,
                    operator:res.data.operator_department.items,
                    label:res.data.label.items,
                    phase:res.data.phase.items
                })
            }
        })
    }
    onFinishFailed(errorInfo) {
    };

    goBack() {
        this.props.history.push('/block-chain-cert/list');
    };
    onChangeType(e){
    }
    getFileInfo() {
    }

    render() {
        return (
            <div className={'add-chain-container certInfo'}>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        <img src={backUrl} alt="" onClick={() => this.goBack()} className={'breadcrumb-icon'}/> 新增存证
                    </div>
                </div>
                <div className={'add-form'}>
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}>
                        <Form.Item
                            label="存证名称"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern:regexps_length_150,
                                    message: '请输入1-150个字符'
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Input className={'name-input'} placeholder={'请输入存证名称'}/>
                        </Form.Item>
                        <Form.Item
                            label="存证类型"
                            name="evidence_type"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.evidenceType.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label=" "
                            name="evidence_sub_name"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern:regexps_length_20,
                                    message: '请输入1-20个字符'
                                }
                            ]}
                            validateFirst={true}
                            className={'add-sub-select'}
                        >
                            <Input className={'item-input'} placeholder={'请输入子类型'}/>
                        </Form.Item>
                        <Form.Item
                            label=" "
                            name="evidence_project_name"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern:regexps_length_20,
                                    message: '请输入1-20个字符'
                                }
                            ]}
                            validateFirst={true}
                            className={'add-sub-select'}
                        >
                            <Input className={'item-input'} placeholder={'请输入项目名称'}/>
                        </Form.Item>
                        <Form.Item
                            label="存证阶段"
                            name="phase"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.phase.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="存证文件"
                            name="attachment"
                            rules={[
                                {
                                    required: false,
                                    message: '',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <UploadFile
                               onChange={() => this.getFileInfo()}
                                // onRef={(ref)=>{ this['upload'+index] = ref}}
                               // fileKey={'fileInfo'}
                            />
                        </Form.Item>
                        <Form.Item
                            label="存证描述"
                            name="description"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    pattern:regexps_length_300,
                                    message: '请输入1-300个字符'
                                }
                            ]}
                            validateFirst={true}
                        >
                            <TextArea className={'des-input'} placeholder={'请输入'}/>
                        </Form.Item>
                        <Form.Item
                            label="存证标签"
                            name="label"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.label.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="经办人"
                            name="operator"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.operator.map((item) => {
                                        return <Option value={item.ID} key={item.ID} lable={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label=" "
                            name="operator_name"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern:regexps_length_20,
                                    message: '请输入1-20个字符'
                                }
                            ]}
                            validateFirst={true}
                            className={'add-sub-select'}
                        >
                            <Input className={'item-input'} placeholder={'请输入姓名'}/>
                        </Form.Item>
                        <Form.Item
                            label="存证公司信息"
                            name="enterprise_info"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    pattern:regexps_length_300,
                                    message: '请输入1-300个字符'
                                }
                            ]}
                            validateFirst={true}
                        >
                            <TextArea className={'des-input'} placeholder={'请输入'}/>
                        </Form.Item>
                        <Form.Item className="footer-btn">
                            <Button className={'submit-btn detail-btn'} type="primary" htmlType="submit">
                                提交
                            </Button>
                            <Button className={'submit-btn detail-btn reset-btn'} type="primary" onClick={()=>{ this.props.history.push('/block-chain-cert/list')}}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}