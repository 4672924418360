import React from 'react';
import {Form, Input, Pagination, Select, Table, Space, message, Modal, Popover} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http';
import searchInputIcon from './../../resource/images/common/input-search.jpg';
import {getUserRole, localTime} from "../../lib/help/helper";
import CertDialog from "../../components/CertDialog";
const {Option} = Select;
let roleOptions = getUserRole()

export default class BlockchainCert extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            total:1,
            EvidenceType:[],
            phaseId:[],
            data: [],
            currentPage: 1,
            keywords:'',
            certNo: '',
            certUrl:'',
            columns: [
                {
                    title: '存证编号',
                    dataIndex: 'NO',
                    key: 'NO',
                    fixed: 'left',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '存证名称',
                    dataIndex: 'Name',
                    key: 'Name',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '存证类型',
                    dataIndex: 'EvidenceType',
                    key:'EvidenceType',
                    render: (row) => {
                        return row? <Popover content={row.name+' '+row.evidence_sub_name+' '+row.evidence_project_name} trigger="hover">
                            <span className="list-desc">{row.name+' '+row.evidence_sub_name+' '+row.evidence_project_name}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '存证阶段',
                    dataIndex: 'Phase',
                    key:'Phase',
                    render: (row) => {
                        return row? <Popover content={row.name} trigger="hover">
                            <span className="list-desc">{row.name}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '经办人 ',
                    dataIndex: 'OperatorDepartment',
                    key:'OperatorDepartment',
                    render: (row) => {
                        return row? <Popover content={row.name+' '+row.operator_name} trigger="hover">
                            <span className="list-desc">{row.name+' '+row.operator_name}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '存证描述',
                    dataIndex: 'Description',
                    key:'Description',
                    render: (val) => {
                        return val? <Popover content={val} trigger="hover">
                            <span className="list-desc">{val}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '存证时间',
                    dataIndex: 'CreatedAt',
                    render: (val) => {
                        return val? <Popover content={localTime(val)} trigger="hover">
                            <span className="list-desc">{localTime(val)}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '存证标签',
                    dataIndex: 'Label',
                    key:'Label',
                    render: (row) => {
                        return row? <Popover content={row.name} trigger="hover">
                            <span className="list-desc">{row.name}</span>
                        </Popover>:'- -'
                    }
                },
                {
                    title: '存证状态',
                    dataIndex: 'Status',
                    key:'Status',
                    render: (Status) => {
                        return <Popover content={Status==='PENDING'?'制证中':Status==='FINISHED'?'制证成功':Status==='FAILED'?'制证失败':'制证中'} trigger="hover">
                        <span className="list-desc">{Status==='PENDING'?'制证中':Status==='FINISHED'?'制证成功':Status==='FAILED'?'制证失败':'制证中'}</span>
                        </Popover>
                    }
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: 'right',
                    width: 250,
                    render: (text, record) => (
                        <Space size="middle" className={'operate-btn'}>
                            <a onClick={this.goDetails.bind(this, record)}>详情</a>
                            {
                                roleOptions.includes('存证-删除') &&
                                <a onClick={this.del.bind(this, record)}>删除</a>
                            }
                            {record.Status==='FINISHED'?  <a onClick={()=>this.downLoadCertFile(record)} target="_blank" rel="noopener noreferrer">下载证书</a>: <span style={{color: "rgba(116, 116, 116, .7)"}}>下载证书</span>}
                        </Space>
                    ),
                },
            ]
        }
    }
    componentWillMount() {
        let params = {
            page: 1,
        };
        this.getList(params)
        if(this.props.type !== 'index') {
            this.getSearchData()
        }
    };
    goDetails = (item)=> {
        this.props.history.push({
            pathname:'/block-chain-cert/detail/'+item.NO,
        });
    };
    del=(item)=> {
        const that = this;
        Modal.confirm({
            className: 'modal-confirm',
            width: '416px',
            title: (
                <div className={'disable-title'}>确定删除该数据吗?</div>
            ),
            content: (
                <div className={'disable-text'}>删除后将无法找回</div>
            ),
            okText: '确定',
            cancelText: '取消',
            onCancel() {

            },
            onOk() {
                http.post('/copyright/delete', {no: item.NO}).then(() => {
                    message.success('删除成功');
                    that.reset();
                });
            },
        });
    };
    downLoadCertFile=(record)=> {
        http.get(`/copyright/detail/${record.NO}/certificate-file`).then(res => {
            if (res) {
                const a = document.createElement("a")
                a.style.display = "none";
                a.setAttribute("target", "_blank");
                a.href = res.data.url;
                document.body.appendChild(a);
                a.click();
            }
        })
    };
    search = () => {
        let params= {
            evidence_type: this.formRef.current.getFieldValue('evidence_type') || '',
            phase: this.formRef.current.getFieldValue('phase_id')  ||  '',
            keywords:this.state.keywords,
            page: ''
        };
        this.getList(params);
    };
    reset = () => {
        this.formRef.current.resetFields();
        let params= {
            keywords: "",
            page: 1
        };
        this.getList(params)
        this.setState({
            currentPage: 1,
            keywords: ''
        });
    };
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            evidence_type: this.formRef.current.getFieldValue('evidence_type') || '',
            phase: this.formRef.current.getFieldValue('phase_id')  ||  '',
            keywords:this.state.keywords,
            page:page,
        };
        this.getList(params)
    };
    getList = (params) => {
        for(var key in params){
            if(params[key] ===''){
                delete params[key]
            }
        }
        http.get('/copyright/list',params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items,
                    total: res.data.total,
                  // currentPage: res.data.currentPage,
                })
            }
        })
    };
    getSearchData=()=>{
        http.get('/copyright/config-filter').then(res => {
            if (res) {
                this.setState({
                    EvidenceType:res.data.evidence_type.items||[],
                    phaseId: res.data.phase.items||[]
                })
            }
        })

    };
    getSearchVal = (e) => {
        this.setState({
            keywords: e.target.value
        })
        if (e.target.value.length===0) {
            let params= {
                evidence_type: this.formRef.current.getFieldValue('evidence_type') || '',
                phase: this.formRef.current.getFieldValue('phase_id')  ||  '',
                keywords:'',
                page: ''
            };
            this.getList(params);
        }
        // this.setState({
        //     currentPage: 1,
        // });
    };
    add=()=> {
          const that =this;
          that.props.history.push('/block-chain-cert/add');
    }
    approve=()=> {
        Modal.info({
            className: 'modal-confirm',
            width: '416px',
            title: (
                <div className={'disable-title'}>正在对接中</div>
            ),
            okText: '确定',
           // cancelText: '取消',
            onCancel() {

            },
            onOk() {
            },
        });
    }
    render() {
        const {data,total } = this.state;
        roleOptions = getUserRole()
        return (
            <div className={this.props.type === 'index' ? 'block-chain-container certInfo index-cert-list' : 'block-chain-container certInfo' }>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        区块链存证
                    </div>
                    <div className={'btn'}>
                        {
                            roleOptions.includes('存证-创建') &&
                            <span onClick={()=>this.add()} className="detail-btn" >+ 新增</span>
                        }
                        {/*<span className="detail-btn reset-btn" onClick={() => this.approve()}>前往公证</span>*/}
                    </div>
                </div>
                <div className={'search-box'}>
                    <div style={{display:"flex"}}>
                            <Form
                                className="search-form"
                                name="basic"
                                ref={this.formRef}
                                layout={'inline'}
                            >
                                <div style={{display:"flex"}}>
                                    <span className={'search-title'}>存证类型:</span>
                                    <Form.Item
                                        name="evidence_type"
                                        className={'input-select'}
                                    >
                                        <Select placeholder="请选择">
                                            {
                                                this.state.EvidenceType.map((item) => {
                                                    return <Option value={item.Name} key={item.ID} label={item.Name}>{item.Name}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </div>
                                <div style={{display:"flex"}}>
                                    <span className={'search-title'}>存证阶段:</span>
                                    <Form.Item
                                        name="phase_id"
                                        className={'input-select'}
                                    >
                                        <Select placeholder="请选择">
                                            {
                                                this.state.phaseId.map((item) => {
                                                    return <Option value={item.Name} key={item.ID} label={item.Name}>{item.Name}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </div>
                            </Form>

                        <div className="item-enterprise btn">
                            <span className="detail-btn" onClick={() => this.search()}>查找</span>
                            <span className="detail-btn reset-btn" onClick={() => this.reset()}>重置</span>
                        </div>
                    </div>
                    <div className="search-input">
                        <Input size="large"
                               onChange={(event) => this.getSearchVal(event)}
                               placeholder={'输入存证名称、经办人、存证标签、存证描述搜索'}
                               value={this.state.keywords}
                               suffix={
                                   <img src={searchInputIcon}
                                        onClick={() => this.search()}
                                        className='img-code'/>
                               }

                        />
                        {/*<div className="input-btn" onClick={() => this.search()}/>*/}
                    </div>
                </div>
              <div className={'table-data'}>
                  <Table columns={this.state.columns}  scroll={{ x: 1200 }} dataSource={data} pagination={false}  rowKey='ID'/>
                  {total>0 && this.props.type !== 'index' &&
                  <div className={'page-total-data'}>
                      <div >
                          共计 <span className={'total-num'}>{total}</span> 条数据
                      </div>
                      <Pagination
                          className={'pageStyle'}
                          onChange={this.onChangePage.bind(this)}
                          defaultCurrent={1}
                          total={total}
                          pageSize={10}
                          current={this.state.currentPage}
                      />
                  </div>}

                </div>
                <CertDialog certNo={this.state.certNo} type={'copyright'} certLink={this.state.certUrl} onRef={(ref)=>{ this.child = ref}}  />
            </div>
        )
    }
}