import React from 'react';
import {Button, Form, Input, message} from "antd";
import "../../resource/styles/certInfo.scss"
import "./index.scss"
import http from './../../lib/service/http'
import {rules} from "../../lib/help/rules";
import iconPassword from "../../resource/images/login/icon_password.svg";
const { password} = rules;
const layout = {
    wrapperCol: {
        offset: 0,
        span: 24,
    },
}
export default class changePaw extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            passwordRules: [
                ...password,
                {
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
        }
    }

    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        if (loginPass && value && loginPass !== value) {
            callback(new Error('两次密码不一致'))
        } else {
            if(!this.formRef.current.isFieldValidating(['confirm_password']) && loginPass) {
                this.formRef.current.validateFields(['confirm_password']);
            }
            callback()
        }
    }
    setIcon(url) {
        return (
            <div className="icon-box">
                <img className={'icon'} src={url} alt=""/>
            </div>
        )
    }
    onFinish =(values) =>{
        // let  params = {
        // };
        values.user_id = this.props.userID
        http.post('/user/update-password', values).then(res => {
            message.success(
               '修改成功',
               );
            this.props.close();
        }, () => {

        });
        this.setState({
            visible: false,
        });
    };
    onFinishFailed(errorInfo) {
    };
    componentDidMount () {
    }
    close = () => {
        this.props.close();
    };
    onChangeType=()=> {

    };
    render() {
        const {passwordRules,confirmPasswordRules}= this.state;
        return (
            <div className="password-form certInfo ">
                <Form
                    name="wrap"
                    ref={this.formRef}
                    labelAlign="left"
                    {...layout}
                    onFinish={(values) => this.onFinish(values)}
                    onFinishFailed={() => this.onFinishFailed()}
                >
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={passwordRules}
                        validateFirst={true}
                    >
                        <Input.Password className={'password-input'} placeholder={'请输入'}
                                        prefix={this.setIcon(iconPassword)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="确认密码"
                        name="confirm_password"
                        rules={confirmPasswordRules}
                        validateFirst={true}
                    >
                        <Input.Password className={'password-input'} placeholder={'请输入'}
                                        prefix={this.setIcon(iconPassword)}
                        />
                    </Form.Item>
                    <Form.Item className="dialog-footer-btn">
                        <Button className={'submit-btn detail-btn'} type="primary" htmlType="submit">
                            提交
                        </Button>
                        <Button className={'submit-btn detail-btn reset-btn'} type="primary" onClick={this.close}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
