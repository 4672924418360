import React, {Component} from 'react'
import {Input, Form, Checkbox, Button, Select, Switch, message} from 'antd';
import './index.scss'
import http from "../../lib/service/http";
import {rules} from '../../lib/help/rules'
import iconBack from "../../resource/images/common/icon-back.svg";
import {optionsIndex, optionsCopyright, optionsUser, optionsSearch, optionsRole, optionsConfig} from '../../lib/help/helper'

const {regexps_length_30, regexps_length_150_not_null, not_null_select, not_null} = rules
const {TextArea} = Input;
const {Option} = Select;


export default class index extends Component {
    formRef = React.createRef();
    addDialogRef = React.createRef();
    state = {
        acceptor: [],
        indexSelect: [],
        copyrightSelect: [],
        searchSelect: [],
        roleSelect: [],
        userSelect: [],
        configSelect: [],
        indexStatus: true,
        copyrightStatus: true,
        searchStatus: true,
        roleStatus: true,
        userStatus: true,
        configStatus: true,
        detailData: {
            role_obj: {},
            role_options: [],
        },
        role_items: [],
        editRoleStatus: true
    }

    async componentDidMount() {
        await this.getAgencyList();
    }
    getInfo = () => {
        http.get(`/role/detail/${this.props.match.params.id}`).then(res => {
            let tmpArr = this.state.acceptor.filter(item => item.ID === res.data.role_obj?.InstitutionID)
            if(tmpArr.length > 0) {
                this.formRef.current.setFieldsValue({
                    role_items:  res.data.role_options,
                    role_name: res.data.role_obj?.RoleName,
                    remark: res.data.role_obj?.Remark,
                    enable: !res.data.role_obj?.Disabled,
                    institution_id: res.data.role_obj?.InstitutionID,
                })
            }else {
                this.formRef.current.setFieldsValue({
                    role_items:  res.data.role_options,
                    role_name: res.data.role_obj?.RoleName,
                    remark: res.data.role_obj?.Remark,
                    enable: !res.data.role_obj?.Disabled,
                    institution_id: res.data.role_obj?.InstitutionName,
                })
            }


            let {indexSelect, copyrightSelect, searchSelect, roleSelect, userSelect,configSelect}  = this.state
            let tmp_role_options = res.data.role_options || []
            for(let i=0; i<tmp_role_options.length; i++) {
                if(tmp_role_options[i].indexOf('总览页')>-1) {
                    indexSelect.push(tmp_role_options[i])
                }
                if(tmp_role_options[i].indexOf('存证')>-1 && tmp_role_options[i].indexOf('存证信息配置') === -1) {
                    copyrightSelect.push(tmp_role_options[i])
                }
                if(tmp_role_options[i].indexOf('取证')>-1) {
                    searchSelect.push(tmp_role_options[i])
                }
                if(tmp_role_options[i].indexOf('角色')>-1) {
                    roleSelect.push(tmp_role_options[i])
                }
                if(tmp_role_options[i].indexOf('用户')>-1) {
                    userSelect.push(tmp_role_options[i])
                }
                if(tmp_role_options[i].indexOf('存证信息配置')>-1) {
                    configSelect.push(tmp_role_options[i])
                }
            }
            if (res) {
                this.setState({
                    detailData: res.data,
                    role_items: res.data.role_options,
                    indexSelect,
                    copyrightSelect,
                    searchSelect,
                    roleSelect,
                    userSelect,
                    configSelect,
                    editRoleStatus: !res.data.role_obj?.Disabled,
                }, ()=> {
                })
            }
        })
    }
    getAgencyList = () => {
        http.get(`/institution/all`).then(res => {
            if (res) {
                this.setState({
                    acceptor: res.data.items ? res.data.items : [],
                }, ()=> {
                    if(this.props.match.params?.id) {
                        this.getInfo()
                    }
                })
            }
        })
    }
    switchChange = (val, type, typeSelect) => {
        if(typeSelect) {
            this.setState({
                [type]: val,
                [typeSelect]: [],
            }, ()=> {
                this.setSelectFields()
            })
        }else {
            this.setState({
                [type]: val
            })
        }
    }
    onCheckboxChange(checkedValues, type) {
        let searchItem = checkedValues.filter(item=>item.indexOf('查看') > -1)
        let oldSearchItem = this.state[type].filter(item=>item.indexOf('查看') > -1)
        if(checkedValues.length > 0 && searchItem.length === 0 && oldSearchItem.length === 0) {
            checkedValues.push(checkedValues[0].split('-')[0]+'-查看')
        }
        if(checkedValues.length > 0 && searchItem.length === 0 && oldSearchItem.length > 0) {
            checkedValues = []
        }
        this.setState({
            [type]: checkedValues
        }, ()=> {
            this.setSelectFields()
        })
    }
    setSelectFields = () => {
        const {indexSelect, copyrightSelect, searchSelect, roleSelect, userSelect, configSelect} = this.state;
        let newArr = [...new Set([...indexSelect, ...copyrightSelect, ...searchSelect, ...roleSelect, ...userSelect, ...configSelect])]
        this.setState({
            role_items: newArr
        })
        this.formRef.current.setFieldsValue({'role_items': newArr})
    }
    formFinish = (val) => {
        let params = {
            ...val,
            enable: val.enable
        }
        if(this.props.location.pathname.indexOf('role-list/edit') > -1) {
            params.id = Number(this.props.match.params.id)
            let tmpInstitution = this.state.acceptor.filter(item => item.ID === Number(params.institution_id))
            if(tmpInstitution.length === 0) {
                params.institution_id = this.state.detailData.role_obj?.InstitutionID
            }
            http.post(`/role/update`, params).then(res => {
                if (res) {
                    message.success('更新成功');
                    this.props.history.push('/role-list')
                }
            })
        }else {
            http.post(`/role/create`, params).then(res => {
                if (res) {
                    message.success('创建成功');
                    this.props.history.push('/role-list')
                }
            })
        }

    }

    switchEditUserChange = () => {
        this.setState({
            editRoleStatus: !this.state.editRoleStatus
        })
        this.formRef.current.setFieldsValue({
            enable: !this.state.editRoleStatus
        })
    }

    render() {
        const {copyrightStatus, searchStatus, roleStatus, userStatus, configStatus, role_items, editRoleStatus} = this.state

        return (
            <div className='add-role-container'>
                <div className="page-title">
                    <div className="title-left">
                        <img src={iconBack} onClick={()=> this.props.history.goBack()} className="icon-back" alt=""/>
                        <p className="title">新增角色</p>
                    </div>
                </div>
                <div className={'add-content container'}>
                    <Form
                        ref={this.formRef}
                        onFinish={this.formFinish}
                    >
                        <div className={'user-name-item'}>
                            <Form.Item
                                label={'角色名'}
                                name='role_name'
                                rules={regexps_length_30}
                            >
                                <Input placeholder={'请输入'}/>
                            </Form.Item>
                        </div>
                        {
                            this.props.match.params?.id &&
                            <div className={'user-name-item'}>
                                <Form.Item
                                    label={'状态'}
                                    name='enable'
                                    rules={not_null}
                                >
                                    <div className="switch-box checkout-bg" style={{justifyContent: "flex-start"}}>
                                        <Switch checked={editRoleStatus} size={'middle'} onClick={() => this.switchEditUserChange()}/>
                                        <span className='edit-switch-text'>{editRoleStatus ? '启用' : '禁用'}</span>
                                    </div>
                                </Form.Item>
                            </div>
                        }
                        <div className={'switch-position'}>
                            <Form.Item
                                label={'角色描述'}
                                name='remark'
                                rules={regexps_length_150_not_null}
                            >
                                <TextArea rows={4}/>
                            </Form.Item>
                        </div>
                        <div className="user-agency">
                            <Form.Item
                                width={'264px'}
                                label={'所属机构'}
                                name='institution_id'
                                rules={not_null_select}
                            >
                                <Select placeholder="请选择" className={'search-select'}>
                                    {
                                        this.state.acceptor.map((item) => {
                                            return <Option name={item.ID} value={item.ID} key={item.ID}>{item.Name}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </div>
                        <div className={'switch-position'}>
                            <Form.Item
                                label={'权限管理'}
                                name='role_items'
                                rules={not_null_select}
                            >
                                <div className="role-item-box first-role-item-box">
                                    <p className="item-title first-item">总览页</p>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsIndex} onChange={(val)=> this.onCheckboxChange(val, 'indexSelect')} />
                                </div>

                                <div className={copyrightStatus ? 'role-item-box' : 'role-item-box hide-checkbox'}>
                                    <div className="item-title">存证管理
                                        <div className="switch-box checkout-bg">
                                            <Switch defaultChecked={copyrightStatus} size={'middle'} onChange={(val) => this.switchChange(val, 'copyrightStatus', 'copyrightSelect')}/>
                                            <span className='edit-switch-text'>{copyrightStatus ? '启用' : '禁用'}</span>
                                        </div>
                                    </div>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsCopyright} onChange={(val)=> this.onCheckboxChange(val, 'copyrightSelect')} />
                                </div>
                                <div className={searchStatus ? 'role-item-box' : 'role-item-box hide-checkbox'}>
                                    <div className="item-title">取证管理
                                        <div className="switch-box checkout-bg">
                                            <Switch defaultChecked={searchStatus} size={'middle'} onChange={(val) => this.switchChange(val, 'searchStatus', 'searchSelect')}/>
                                            <span className='edit-switch-text'>{searchStatus ? '启用' : '禁用'}</span>
                                        </div>
                                    </div>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsSearch}  onChange={(val)=> this.onCheckboxChange(val, 'searchSelect')} />
                                </div>
                                <div className={roleStatus ? 'role-item-box' : 'role-item-box hide-checkbox'}>
                                    <div className="item-title">角色管理
                                        <div className="switch-box checkout-bg">
                                            <Switch defaultChecked={roleStatus} size={'middle'} onChange={(val) => this.switchChange(val, 'roleStatus', 'roleSelect')}/>
                                            <span className='edit-switch-text'>{roleStatus ? '启用' : '禁用'}</span>
                                        </div>
                                    </div>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsRole}  onChange={(val)=> this.onCheckboxChange(val, 'roleSelect')} />
                                </div>
                                <div className={userStatus ? 'role-item-box' : 'role-item-box hide-checkbox'}>
                                    <div className="item-title">用户管理
                                        <div className="switch-box checkout-bg">
                                            <Switch defaultChecked={userStatus} size={'middle'} onChange={(val) => this.switchChange(val, 'userStatus', 'userSelect')}/>
                                            <span className='edit-switch-text'>{userStatus ? '启用' : '禁用'}</span>
                                        </div>
                                    </div>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsUser}  onChange={(val)=> this.onCheckboxChange(val, 'userSelect')} />
                                </div>
                                <div className={configStatus ? 'role-item-box' : 'role-item-box hide-checkbox'}>
                                    <div className="item-title">存证信息配置
                                        <div className="switch-box checkout-bg">
                                            <Switch defaultChecked={configStatus} size={'middle'} onChange={(val) => this.switchChange(val, 'configStatus', 'configSelect')}/>
                                            <span className='edit-switch-text'>{configStatus ? '启用' : '禁用'}</span>
                                        </div>
                                    </div>
                                    <Checkbox.Group defaultValue={role_items} key={role_items} options={optionsConfig}  onChange={(val)=> this.onCheckboxChange(val, 'configSelect')} />
                                </div>
                            </Form.Item>
                        </div>
                        <div className={'submit-item'}>
                            <Form.Item
                            >
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                                <Button onClick={()=>this.props.history.goBack()}>
                                    取消
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>

            </div>
        )
    }
}
