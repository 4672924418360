import React from 'react';
import { Form, Input, Radio, Button} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CopyrightGuide from "../CopyrightGuide";
import UploadFile from "../../components/Upload";
import './index.scss'
import iconAddTitle from "../../resource/images/evidence-add/icon-add-copyright-title.png";
import iconDelete from "../../resource/images/copyright-add/icon-delete.png";
import {regexps_length_50, regexps_length_20} from "../../lib/help/validates";
import http from "../../lib/service/http";
import SuccessDialog from "../../components/SuccessDialog";

const {TextArea} = Input;


export default class CopyrightAdd extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            expandIconPosition: 'right',
            validates: {
                regexps_name: [
                    {
                        required: true,
                        message: '请输入作品名称',
                    },
                    {
                        pattern: regexps_length_20,
                        message: '限制字数在20之内',
                    }
                ],
                regexps_remark: [
                    {
                        required: true,
                        message: '请输入作品描述',
                    },
                    {
                        pattern: regexps_length_50,
                        message: '限制字数在50之内',
                    }
                ],
                regexps_author_name: [
                    {
                        required: true,
                        message: '请输入作者名称',
                    },
                    {
                        pattern: regexps_length_20,
                        message: '限制字数在20之内',
                    }
                ],
            },
            authorType: 'individual',
            username: '',
            fileList: [
                {
                    key: 'fileList0',
                    nameKey: 'title0',
                    remarkKey: 'remark0'
                }
            ],
            authorName: [
                {
                    key: 'authors0'
                }
            ],
            fileUrlList: {
                fileInfo0: ''
            },
            isClickSubmit: false,
            fromData: {},
            imgType: ['jpg', 'jpeg', 'png', 'tmp'],
            isFinishInfo: false,
            successTip: {
                desc: '您的存证申请已提交，请在存证列表中查询存证结果',
                btnText: '前往存证列表',
                routeLink: '/copyright-seal/list'
            },
            maxFileSize: 50.00
        }
    }

    changeType = e => {
        let tmpArr = this.state.authorName;
        if (e.target.value === 'cooperation') {
            tmpArr.push({key: 'authors' + tmpArr.length})
        } else {
            tmpArr = tmpArr.splice(0, 1);
        }
        this.setState({
            authorName: tmpArr,
            authorType: e.target.value,

        })
    };

    getFileInfo(key, fileInfo) {
        let tmpUrl = this.state.fileUrlList;
        tmpUrl[key] = fileInfo;
        this.setState({
            fileUrlList: tmpUrl,
            // isClickSubmit: false
        })
    }

    addFile() {
        if (this.state.fileList.length <= 6) {
            let tmpArr = this.state.fileList;
            let lastItem = tmpArr[tmpArr.length-1].key;
            let lastNumber = Number(lastItem.substr(8, ))+1; // 8 是fileList+ n(n为数字) 中 fileList长度
            tmpArr.push({key: 'fileList'+lastNumber, nameKey: 'title'+lastNumber, remarkKey: 'remark'+lastNumber})
            this.setState({
                fileList: tmpArr
            })
        }
    }

    addAuthor() {
        if (this.state.authorName.length < 5) {
            let tmpArr = this.state.authorName;
            let lastItem = tmpArr[tmpArr.length-1].key;
            let lastNumber = Number(lastItem.substr(7, ))+1; // 7 是fileList+ n(n为数字) 中 fileList长度
            tmpArr.push({key: 'authors'+lastNumber})
            this.setState({
                authorName: tmpArr
            })
        }
    }

    deleteFileItem(index) {
        let tmpArr = this.state.fileList;
        this.formRef.current.setFieldsValue({[tmpArr[index].nameKey]: '', [tmpArr[index].remarkKey]: ''})
        tmpArr.splice(index, 1);
        this.setState({
            fileList: tmpArr
        })
    }

    deleteAuthorItem(index) {
        let tmpArr = this.state.authorName;
        this.formRef.current.setFieldsValue({[tmpArr[index].key]: ''})
        tmpArr.splice(index, 1);
        this.setState({
            authorName: tmpArr
        })
    }

    getFileName(changedValues, allValues) {
    }

    setNumber(index) {
        switch (index) {
            case 0 :
                return '一';
            case 1 :
                return '二';
            case 2 :
                return '三';
            case 3 :
                return '四';
            case 4 :
                return '五';
            case 5 :
                return '六';
            default:
                return '';
        }
    }

    isShowError(index) {
        return !this.state.fileUrlList['fileInfo' + index] && this.state.isClickSubmit
    }

    componentDidMount() {
    }

    setFileItem() {
        return (
            this.state.fileList.map((item, index) => {
                return (
                    <div className="file-item container-shadow" key={item.key}>
                        <p className="item-title">
                            作品{this.setNumber(index)}
                        </p>
                        <div className="form-item-box">
                            <div className="item-left">
                                <div className="upload-box">
                                    <UploadFile
                                        onChange={(key, fileInfo) => this.getFileInfo(key, fileInfo)}
                                        onRef={(ref)=>{ this['upload'+index] = ref}}
                                        fileKey={'fileInfo' + index}
                                    />
                                    {
                                        this.isShowError(index) &&
                                        <p className="file-none error-text">请上传文件</p>

                                    }
                                </div>
                                <div className="upload-desc">
                                    <p className={'desc-item'}>说明：</p>
                                    <p className={'desc-item'}>1.上传单个文件大小不超过50MB</p>
                                    <p className={'desc-item'}>2.文件格式必须符合条件，支持上传格式：png、jpg、jpeg、pdf、docx、doc、xls、xlsx、ppt、mp4、avi等</p>
                                    <p className={'desc-item'}>3.支持连续6个文件上传，提交后将登记为6个独立作品请确保您权利归属及作者信息无误后提交</p>
                                </div>
                            </div>
                            <div className="item-right">
                                <Form.Item
                                    name={item.nameKey}
                                    colon={false}
                                    rules={this.state.validates.regexps_name}
                                >
                                    <Input
                                        size="large"
                                        placeholder={'请输入作品名称'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={item.remarkKey}
                                    colon={false}
                                    rules={this.state.validates.regexps_remark}
                                    className={'desc-box'}
                                >
                                    <TextArea
                                        size="large"
                                        placeholder={'请输入作品描述'}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {
                            index > 0 &&
                            <img src={iconDelete} onClick={() => this.deleteFileItem(index)} alt=""
                                 className="icon-delete"/>
                        }
                    </div>

                )
            })
        )
    }

    setFilePreview() {
        return (
            this.state.fileList.map((item, index) => {
                return (
                    <div className="file-item container-shadow file-item-preview" key={item.key}>
                        <p className="item-title">
                            作品{this.setNumber(index)}
                        </p>
                        <div className="form-item-box">
                            <div className="item-left">
                                <div className="upload-box">
                                    {
                                        <div className="file-pre-box">
                                            <div className="file-bg">
                                                {this.getFileType(this.state.fileUrlList['fileInfo' + index])}
                                            </div>
                                            {this.state.fileUrlList['fileInfo' + index] && this.state.fileUrlList['fileInfo' + index].name}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="item-right">
                                <p className="label-text">作品名称：<span>{this.state.fromData[this.state.fileList[index].nameKey]}</span></p>
                                <p className="label-text">作品描述：<span>{this.state.fromData[this.state.fileList[index].remarkKey]}</span></p>
                            </div>
                        </div>
                    </div>

                )
            })
        )
    }

    setAuthorPreview() {
        return (
            <div className="author-content author-content-pre container-shadow">
                <div className="content-title">
                    <img src={iconAddTitle} alt="" className="title-icon"/>
                    <span className="text">
                                        作品基本信息
                                    </span>
                </div>
                <div className="author-box">
                    <p className="label-text">权利归属：<span>{this.state.fromData.authorType === 'individual' ? '个人作品' : '合作作品'}</span></p>
                    {
                        this.state.authorName.map((item, index) => {
                            return (
                                <p key={item.key} className="label-text">作者名称：<span>{this.state.fromData[this.state.authorName[index].key]}</span></p>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    setAuthorName() {
        return (
            this.state.authorName.map((item, index) => {
                return (
                    <div key={item.key} className={'author-item-box'}>
                        <Form.Item
                            label={'作者名称'}
                            name={item.key}
                            className={index !== 0 && 'hide-label'}
                            colon={false}
                            rules={this.state.validates.regexps_author_name}
                        >
                            <Input
                                size="large"
                                placeholder={'请输入作者名称'}
                            />
                        </Form.Item>
                        {
                            index > 1 &&
                            <img src={iconDelete} onClick={() => this.deleteAuthorItem(index)} alt=""
                                 className="icon-delete"/>
                        }
                    </div>
                )
            })
        )
    }

    getFileType(imageName) {
        if (imageName && imageName.name) {
            let tmpName = imageName.name;
            return tmpName.substr(tmpName.lastIndexOf('.') + 1,)
        }
    }
    editInfo() {
        this.setState({
            isFinishInfo: false
        })
    }

    onFinish(values) {
        this.setState({
            isClickSubmit: true,
            fromData: values
        })
        let defState = true;

        for(let i=0; i<this.state.fileList.length; i++) {
            if(!this.state.fileUrlList['fileInfo'+i]) {
                defState = false;
                break;
            }
        }
        if(defState) {
            this.setState({
                isFinishInfo: true
            })
        }
    };
    formatData() {
        let tmpAuthorName = '';
        for(let key in this.state.fromData) {
            if(key.indexOf('authors') > -1) {
                tmpAuthorName = tmpAuthorName+this.state.fromData[key]+','
            }
        }
        tmpAuthorName = tmpAuthorName.substr(0, tmpAuthorName.length-1);
        for(let i=0; i<this.state.fileList.length; i++) {
            let tmpSendObj = {};
            tmpSendObj.title = this.state.fromData[this.state.fileList[i].nameKey];
            tmpSendObj.remark = this.state.fromData[this.state.fileList[i].remarkKey]
            tmpSendObj.file_path = this.state.fileUrlList['fileInfo'+i].url
            tmpSendObj.authors = tmpAuthorName;
            this.submitData(tmpSendObj);
        }
    }

    submitData(data) {
        let _this = this;
        http.post('/copyright/store',data).then(res=>{
            _this.child.showModal()
            _this.resetState();
        })
    }
    resetState() {
        this.setState({
            fileList: [{
                key: 'fileList0',
                nameKey: 'title0',
                remarkKey: 'remark0'
            }],
            authorName: [{key: 'authors0'}],
            fileUrlList: {fileInfo0: ''},
            isClickSubmit: false,
            fromData: {},
            isFinishInfo: false,
            authorType: 'individual',
        },  ()=> {
            this.formRef.current.resetFields();
            for(let i=0; i<this.state.fileList.length; i++) {
                this['upload'+i].resetUpload();
            }
        })
    }

    onFinishFailed(errorInfo) {
        this.setState({
            isClickSubmit: true,
        })
    };

    render() {
        return (
            <div className="copyright-add-container">
                <CopyrightGuide />
                <div className={!this.state.isFinishInfo ? 'copyright-add-content' : 'copyright-add-content hide-from-content'}>
                    <Form
                        name="basic"
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}
                        className={'form-content'}
                        initialValues={{'authorType': this.state.authorType}}
                        onValuesChange={(changedValues, allValues) => this.getFileName(changedValues, allValues)}
                        ref={this.formRef}
                    >
                        <div className="file-content container-shadow">
                            <div className="content-title">
                                <img src={iconAddTitle} alt="" className="title-icon"/>
                                <span className="text">
                                    作品文件上传
                                </span>
                            </div>
                            {
                                this.setFileItem()
                            }
                            {
                                this.state.fileList.length < 6 &&
                                <div className="add-file-box" onClick={() => this.addFile()}>
                                    <div className="add-icon-box">
                                        <PlusOutlined/>
                                    </div>
                                    添加作品
                                </div>
                            }

                        </div>
                        <div className="author-content container-shadow">
                            <div className="content-title">
                                <img src={iconAddTitle} alt="" className="title-icon"/>
                                <span className="text">
                                        作品基本信息
                                    </span>
                            </div>
                            <div className="author-box">
                                <Form.Item
                                    label={'权利归属'}
                                    name="authorType"
                                    rules={[{'required': true}]}

                                >
                                    <Radio.Group name={'authorType'} onChange={this.changeType}>
                                        <Radio value={'individual'}>个人作品</Radio>
                                        <Radio value={'cooperation'}>合作作品</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {
                                    this.setAuthorName()
                                }
                                {
                                    this.state.authorName.length < 5 && this.state.authorType === 'cooperation' &&
                                    <div className="add-file-box" onClick={() => this.addAuthor()}>
                                        <div className="add-icon-box">
                                            <PlusOutlined/>
                                        </div>
                                        添加作者
                                    </div>
                                }
                            </div>
                        </div>
                        <Form.Item className={'submit-box'}>
                            <Button type="primary" className={'submit-btn'} htmlType="submit">
                                提交
                            </Button>
                            <Button type="primary" className={'submit-btn btn-bg-white'} onClick={()=>this.resetState()}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                {/*确认信息*/}
                <div className={!this.state.isFinishInfo ? 'copyright-add-content copyright-add-content-pre' : 'copyright-add-content copyright-add-content-pre show-confirm-content'}>
                        <div className="file-content container-shadow">
                            <div className="content-title">
                                <img src={iconAddTitle} alt="" className="title-icon"/>
                                <span className="text">
                                    作品文件上传
                                </span>
                            </div>
                            {
                                this.setFilePreview()
                            }
                        </div>

                        {
                            this.setAuthorPreview()
                        }
                        <div className="submit-box">
                            <Button type="primary" className={'submit-btn btn-bg-white'} onClick={()=>this.editInfo()} >
                                返回修改信息
                            </Button>
                            <Button type="primary" className={'submit-btn'} onClick={()=>this.formatData()}>
                                确认并继续
                            </Button>
                        </div>
                </div>
                <SuccessDialog onRef={(ref)=>{ this.child = ref}} successTip={this.state.successTip} />
            </div>
        )
    }
}