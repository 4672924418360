import React from 'react';
import {Spin, Upload} from 'antd';
import './index.scss'
import iconUpload from "../../resource/images/upload/file-upload-icon-add.svg";
import iconFile from "../../resource/images/upload/file-img.svg";
import http from "../../lib/service/http";
import load from "../../resource/images/upload/load.svg"
import axios from "axios";
const { Dragger } = Upload;


export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OSSData: {},
            successData: {},
            uploadStatus: '',
            fileList: [],
            fileSizeStatus: false,
            loading:false,
            spin: "none"

        }
        if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    async componentDidMount() {
    }

    init = async (filename) => {
        let OSSData = (await this.mockGetOSSData(filename));
        OSSData.AWSAccessKeyId = OSSData.accessid;
        await this.setState({
            OSSData: OSSData,
        });
    };

    mockGetOSSData = async (filename) => {
        const suffix = filename.slice(filename.lastIndexOf('.'));
        const tmpFilename = Date.now() + suffix;
        return await http.get('/upload/sign', {file_name: tmpFilename}).then(res=>{
            return res.data
        })
    };

    onChange = ({ fileList }) => {
        const { onChange} = this.props;
        this.setState({
          //  successData: fileList[fileList.length-1],
          //  uploadStatus: fileList[fileList.length-1].status,
           // fileList: [fileList[fileList.length-1]]
        }, ()=> {
        })
        fileList=fileList.filter((file)=> {
            return file.status !== 'error';

        })
        fileList=fileList.map((file)=> {
            if(file.response) {
                file.url = file.response.obj[0]
            }
            return file;
        })
        fileList = fileList.filter((file)=>{
            if(file.response) {
                return file.response.success === true;
            }
            return true
        });
        this.setState({fileList})
        if (onChange) {
            onChange([...fileList]);
        }
    }
    transformFile = file => {
        const { OSSData } = this.state;
        file.url = OSSData.dir;

        return file;
    };

    getExtraData = file => {
        const { OSSData } = this.state;
        return {
            key: file.url,
            AWSAccessKeyId: OSSData.AWSAccessKeyId,
            policy: OSSData.policy,
            Signature: OSSData.signature,
            acl: OSSData.acl
        };
    };

    beforeUpload = async (file) => {
        await this.init(file.name);
        const { OSSData } = this.state;
        const expire = OSSData.expire * 1000;
        return new Promise(async (resolve, reject) => {
            if (expire < Date.now()) {
                await this.init();
            }
            let fileType = ["png","jpg", "jpeg", "pdf", "docx", "doc", "xls", "xlsx", "ppt", "mp3","mp4", "avi"]
            if(fileType.indexOf(this.getFileType(file)) === -1) {
                this.setState({
                    fileSizeStatus: 'type',
                    uploadStatus: ''
                })
                reject(file);
                return
            }
            if(file.size > OSSData.max_upload_size) {
                this.setState({
                    fileSizeStatus: 'size',
                    uploadStatus: ''
                })
                reject(file);
            } else {
                this.setState({
                    fileSizeStatus: false,
                })
                resolve(file);
            }
        });
    };

    getFileType(imageName) {
        if (imageName && imageName.name) {
            let tmpName = imageName.name;
            return tmpName.substr(tmpName.lastIndexOf('.') + 1,)
        }
    }
    resetUpload() {
        this.setState({
            uploadStatus: ''
        })
    }

    iconRender=file => {
        const {uploadStatus} = this.state
    return <div className={'upload-file-img'}>
        <img src={uploadStatus===''?load:iconFile} className={uploadStatus===''?'round':''} alt=""/>
        </div>
     }
    del = file => {
        const {onChange } = this.props;
        this.state.fileList = this.state.fileList.filter(v => v.url !== file.url);
        if (onChange) {
            onChange(this.state.fileList);
        }
    };
    look(file) {
        http.get('/private-file', {path:file.url}).then(res => {
            if (res) {
                let length = file.name.length
                let type = file.name.substring(length-3,length)
                if(type === 'mp4') {
                    this.setState({
                        spin: '',
                        loading: true
                    })
                    axios({
                        url: res.data.url,
                        method: 'get',
                        responseType: 'blob',
                    })
                        .then(res=>{
                            let blobUrl = window.URL.createObjectURL(res.data);
                            let link = document.createElement('a');
                            document.body.appendChild(link);
                            link.href = blobUrl;
                            link.download = file.name;
                            link.click();
                            window.URL.revokeObjectURL(blobUrl);
                            this.setState({
                                spin: 'none',
                                loading: false
                            })
                        })
                } else {
                    const a = document.createElement("a")
                    a.style.display = "none";
                    a.setAttribute("target", "_blank");
                    a.href = res.data.url;
                    a.click();
                }
            }
        })
    }
    itemRender=(originNode, file)=> {
        return (
                <div className={'file-list'}>
                    {(file.status!==undefined&&this.state.OSSData.host!==null) &&<div  style={{display:'flex',marginTop:"12px"}}>
                        <div>
                        <img src={file.status!=='done'?load:iconFile} className={file.status!=='done'?'round':''} alt=""/>
                        <span className={'file-name'}>{file.name}</span>
                        </div>
                        {file.status==='done'&&<div style={{display:'flex'}}>
                            <span className={'btn-text'} onClick={()=>this.del(file)}>删除</span>
                            <div className={'split-line'}>|</div>
                            <span className={'btn-text'} onClick={()=>this.look(file)}>查看</span>
                        </div>}
                    </div>}
                </div>
        );
    }
    handlePreview = async file => {
        if (!file.url && !file.preview) {
          //  file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    render() {
        const props = {
            name: 'file',
            fileList: this.state.fileList,
            action: this.state.OSSData.host,
           // iconRender: this.iconRender,
           // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            method: 'post',
            onChange: this.onChange,
            onRemove: this.onRemove,
            transformFile: this.transformFile,
            data: this.getExtraData,
            beforeUpload: this.beforeUpload,
            multiple: false,
            itemRender:this.itemRender,
            onPreview:this.handlePreview,
            showUploadList: {
                showRemoveIcon: false,
                showDownloadIcon: false,
            },
        };
        return (
            <div className="upload-container">
                <Spin spinning={this.state.loading} size="large" style={{display: this.state.spin}} className={'loading'}/>
                <Dragger {...props} className={'upload-box'}>
                    {
                        // this.state.uploadStatus === 'done' ?
                        //     <div className="upload-dialog">
                        //         <div className="file-type">
                        //             { this.getFileType(this.state.fileList[0])}
                        //         </div>
                        //         <div className="change-dialog">
                        //             <img src={iconUpload} className={'icon-change'} alt=""/>
                        //             <span className={'change-text'}>更换文件</span>
                        //         </div>
                        //     </div>
                        //     :
                            <div className="upload-dialog">
                                <img src={iconUpload} alt="" className="icon-upload"/>
                                <p className="upload-text">拖动上传或点击打开文件上传</p>
                            </div>
                    }
                    <div className="upload-desc">
                        <p className={'desc-item'}>支持上传格式：png、jpg、jpeg、pdf、docx、doc、xls、xlsx、ppt、mp3、mp4、avi</p>
                    </div>
                </Dragger>
                {
                    this.state.uploadStatus === 'error' &&
                    <p className="error-text">文件上传失败请稍后重试</p>
                }
                {
                    this.state.fileSizeStatus === 'size' &&
                    <p className="error-text error-size">请上传小于{
                        this.state.OSSData.max_upload_size && (this.state.OSSData.max_upload_size/1024/1024).toFixed(2)
                    }MB的文件</p>
                }
                {
                    this.state.fileSizeStatus === 'type' &&
                    <p className="error-text error-size">文件类型错误</p>
                }
            </div>
        )
    }
}