import React from 'react';
import {Row, Col, Pagination, Select, message, Button, Table, Space,Spin} from 'antd';
import axios from 'axios';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http'
import backUrl from "../../resource/images/common/go-back.svg"
import download from "../../resource/images/block-chain-cert/download.svg"
import fileImg from "../../resource/images/upload/file-img.svg"
import {localTime, downloadBinaryFileStream, getUserRole} from "../../lib/help/helper";

export default class BlockchainDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList: {},
            total:1,
            operationRecord: [],
            currentPage:1,
            evidenceType:{},
            phase:{},
            label:{},
            operatorDepartment:{},
            attachment:[],
            externalAttachment:[],
            transactionObj:{},
            tmpUrl:'',
            loading: false,
            spin: 'none',
            columns: [
                {
                    title: '操作人 ',
                    dataIndex: 'Operator',
                    key: 'Operator',
                    width: 500,
                },
                {
                    title: '时间',
                    dataIndex: 'UpdatedAt',
                    render: (val) => {
                        return (localTime(val))
                    }
                },
                {
                    title: '执行操作',
                    dataIndex: 'Operation',
                    key: 'Operation',
                    // render: (val,row) => {
                    //     return <span>{row.ResourceType==='COPYRIGHT'?val: val+row.Resource}</span>
                    // }
                },
            ],
            isCopyright:''
        }
    }
    componentWillMount () {
        const id = this.props.match.params.id;
        if (this.props.match.path === '/block-chain-cert/detail/:id') {
            this.getDetail('copyright/detail',this.props.match.params.id)
            this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
            this.setState({
                isCopyright: true
            })
        } else {
            this.getDetail('search/copyright/detail',this.props.match.params.id)
            this.setState({
                isCopyright: false
            })
            this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
        }
    };
    getDetail = (url,params) => {
        http.get(`/${url}/${params}`).then(res => {
            if (res) {
                this.setState({
                    detailList: res.data.copyright_obj,
                    transactionObj: res.data.transaction_obj || {},
                    evidenceType: res.data.copyright_obj.EvidenceType,
                    phase: res.data.copyright_obj.Phase,
                    label: res.data.copyright_obj.Label,
                    operatorDepartment:res.data.copyright_obj.OperatorDepartment,
                    attachment:res.data.copyright_obj.Attachment || [],
                    externalAttachment:res.data.copyright_obj.ExternalAttachment||[]
                })
            }
        })
    };
    getOperationLog=(url,params)=> {
        http.get(`/${url}`,params).then(res => {
            if (res) {
                this.setState({
                    operationRecord: res.data.items,
                    total: res.data.total
                })
            }
        })
    }
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            page:page,
        };
        if (this.state.isCopyright) {
            this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`,params)
            this.setState({
                isCopyright: true
            })
        } else {
            this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`,params)
        }

        // if( this.state.isCopyright) {
        //     this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`)
        // } else {
        //     this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`)
        // }
    };
    goBack() {
        this.props.history.goBack();
    };
    downLoad() {
        const no_arr=[]
        no_arr.push(this.props.match.params.id)
        let params = {
            no_arr
        }
        let url=''
        if( this.state.isCopyright) {
            url='/copyright/export'
        } else {
            url='/search/copyright/export'
        }
        http.post(url,params,{responseType: 'arraybuffer',isExport:true}).then(res => {
            if (res) {
               downloadBinaryFileStream(res)
                if (this.state.isCopyright) {
                    this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                    this.setState({
                        isCopyright: true
                    })
                } else {
                    this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                }
            }
        })
    }
    getCertUrl(item,res) {
        let length = item.file_name.length
        let type = item.file_name.substring(length-3,length)
        if(type== 'mp4') {
            this.setState({
                spin: '',
                loading: true
            })
            axios({
                url: res.data.url,
                method: 'get',
                responseType: 'blob',
            })
                .then(res=>{
                    let blobUrl = window.URL.createObjectURL(res.data);
                    let link = document.createElement('a');
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.setAttribute("target", "_blank");
                    link.href = blobUrl;
                    link.download = item.file_name;
                    link.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.setState({
                        spin: 'none',
                        loading: false
                    })
                })
        } else {
            const a = document.createElement("a")
            a.style.display = "none";
            a.setAttribute("target", "_blank");
            a.href = res.data.url;
            document.body.appendChild(a);
            a.click();
        }
    }
    downLoadCert(item,isExter) {
        if (isExter) {
            if(this.state.isCopyright) {
                http.get(`/copyright/detail/${this.props.match.params.id}/file/${item.id}`).then(res => {
                    if (res) {
                        this.getCertUrl(item,res)
                        this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                        this.setState({
                            isCopyright: true
                        })
                    }
                })
            } else {
                http.get(`search/copyright/detail/${this.props.match.params.id}/file/${item.id}`).then(res => {
                    if (res) {
                        this.getCertUrl(item,res)
                        this.setState({
                            isCopyright: false
                        })
                        this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                    }
                })
            }
        } else {
            let length = item.file_name.length
            let type = item.file_name.substring(length-3,length)
            if(type== 'mp4') {
                this.setState({
                    spin: '',
                    loading: true
                })
                axios({
                    url: item.file_url,
                    method: 'get',
                    responseType: 'blob',
                })
                    .then(res=>{
                        let blobUrl = window.URL.createObjectURL(res.data);
                        let link = document.createElement('a');
                        link.style.display = "none";
                        document.body.appendChild(link);
                        link.setAttribute("target", "_blank");
                        link.href = blobUrl;
                        link.download = item.file_name;
                        link.click();
                        window.URL.revokeObjectURL(blobUrl);
                        this.setState({
                            spin: 'none',
                            loading: false
                        })
                    })
            } else {
                const a = document.createElement("a")
                a.style.display = "none";
                a.setAttribute("target", "_blank");
                a.href = item.file_url;
                document.body.appendChild(a);
                a.click();
            }
        }
    }
    downLoadCertFile(item) {
        if (item.Status ===  'FINISHED') {
            if(this.state.isCopyright) {
                http.get(`/copyright/detail/${this.props.match.params.id}/certificate-file`).then(res => {
                    if (res) {
                        const a = document.createElement("a")
                        a.style.display = "none";
                        a.setAttribute("target", "_blank");
                        a.href = res.data.url;
                        document.body.appendChild(a);
                        a.click();
                        this.getOperationLog(`copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                        this.setState({
                            isCopyright: true
                        })
                    }
                })
            } else {
                http.get(`/search/copyright/detail/${this.props.match.params.id}/certificate-file`).then(res => {
                    if (res) {
                        const a = document.createElement("a")
                        a.style.display = "none";
                        a.setAttribute("target", "_blank");
                        a.href = res.data.url;
                        document.body.appendChild(a);
                        a.click();
                        this.setState({
                            isCopyright: false
                        })
                        this.getOperationLog(`search/copyright/detail/${this.props.match.params.id}/operation-log/list`,{page:this.state.currentPage})
                    }
                })
            }
        }
    }
    render() {
        const {detailList,transactionObj,evidenceType,phase,label,operatorDepartment,attachment,externalAttachment,operationRecord,total} = this.state;
        const roleOptions = getUserRole()
        return (
            <div className={'detail-chain-container certInfo'}>
                <Spin spinning={this.state.loading} size="large" style={{display: this.state.spin}} className={'loading'}/>
                <div className={'header-navigation detail'}>
                    <div className={'breadcrumb'}>
                        <img src={backUrl} alt="" onClick={() => this.goBack()} className={'breadcrumb-icon'}/> {this.state.isCopyright?'存证详情':'取证详情'}
                    </div>
                    <div>
                        {
                            ( roleOptions.includes('取证-下载') || roleOptions.includes('存证-查看') )&&
                            <Button className="detail-btn" onClick={() => this.downLoad()} >
                                <img src={download} alt="" className={'breadcrumb-icon'}/>
                                导出</Button>
                        }
                    </div>
                </div>
                <div className={'detail-card'}>
                    <div className={'title'}>基础信息</div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证编号：</div>
                        {/*<div className={'name-text'}>{evidenceType.name||'--'}</div>*/}
                        <div className={'name-text'}>{detailList.NO||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证名称：</div>
                        {/*<div className={'name-text'}>{evidenceType.name||'--'}</div>*/}
                        <div className={'name-text'}>{detailList.Name||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证类型：</div>
                        <div className={'name-text'}>{evidenceType.name+' '+evidenceType.evidence_sub_name+' '+evidenceType.evidence_project_name||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证阶段：</div>
                        <div className={'name-text'}>{phase.name||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证文件：</div>
                        <div className={'name-text-other'}>
                            {(attachment.length>0 || externalAttachment.length>0)?
                                <div>
                                    {
                                        attachment.length>0? attachment.map(item => {
                                            return (
                                                <div  key={item.id} onClick={()=>this.downLoadCert(item,true)}>
                                                        <img src={fileImg}/>
                                                        <span>{item.file_name}</span>
                                                </div>
                                            )
                                        }):''
                                    }
                                    {
                                        externalAttachment.length>0? externalAttachment.map(item => {
                                            return (
                                                <div  key={item.id} onClick={()=>this.downLoadCert(item,false)}>
                                                    <img src={fileImg}/>
                                                    <span>{item.file_name}</span>
                                                </div>
                                            )
                                        }):''
                                    }
                                </div>:<span style={{color: '#4A4A4A'}}>--</span>
                            }
                        </div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证描述：</div>
                        <div className={'name-text'}>{detailList.Description||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证标签：</div>
                        <div className={'name-text'}>{label.name||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>经办人：</div>
                        <div className={'name-text'}>{operatorDepartment.name+' '+operatorDepartment.operator_name||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'} style={{width:"100px"}}>存证公司信息：</div>
                        <div className={'name-text'}>{detailList.EnterpriseInfo||'--'}</div>
                    </div>
                </div>
                <div className={'detail-card'}>
                    <div className={'title'}>操作记录</div>
                    <Table columns={this.state.columns} dataSource={operationRecord} pagination={false}  rowKey='ID'/>
                    {total>0&&
                    <div className={'page-total-data'}>
                        <div >
                            共计 <span className={'total-num'}>{total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.onChangePage.bind(this)}
                            defaultCurrent={1}
                            total={total}
                            pageSize={10}
                            current={this.state.currentPage}
                        />
                    </div>}
                </div>
                <div className={'detail-card'}>
                    <div className={'title'}>区块链信息</div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>证书编号：</div>
                        <div className={'name-text'}>{transactionObj.CertificateNO||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>上链时间：</div>
                        <div className={'name-text'}>{transactionObj.Timestamp||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>区块地址：</div>
                        <div className={'name-text'}>{transactionObj.DataHash||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>交易地址：</div>
                        <div className={'name-text'}>{transactionObj.Hash||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>存证证书：</div>
                        {Object.keys(transactionObj).length===0? '--':
                            <a className={'name-text-other downButton'} onClick={()=>this.downLoadCertFile(transactionObj)} target="_blank" rel="noopener noreferrer" >
                                {transactionObj.Status==='PENDING'?'制证中':transactionObj.Status==='FINISHED'?'下载证书':transactionObj.Status==='FAILED'?'制证失败':'制证中'}
                            </a>}

                    </div>
                </div>
            </div>
        )
    }
}