import React, {Component} from 'react'
import {Descriptions, Table, Button, Radio, message, Pagination} from 'antd';
import './index.scss'
import {localTime, setAgencyRole} from "../../lib/help/helper";
import http from "../../lib/service/http";
import AddDialog from '../../components/AddAgencyDialog/Index'
import iconBack from '../../resource/images/common/icon-back.svg'


export default class index extends Component {
    formRef = React.createRef();
    addDialogRef = React.createRef();
    state = {
        data: [],
        detailData: {},
        currentPage: 1,
        columns: [
            {
                title: '用户ID',
                dataIndex: 'ID',
                key: 'ID',
            },
            {
                title: '用户名',
                dataIndex: 'UserName',
                key: 'UserName',
                width: 200,
                ellipsis: true,
            },
            {
                title: '所属机构',
                dataIndex: 'InstitutionName',
                key: 'InstitutionName',
                ellipsis: true,
            },
            {
                title: '角色',
                dataIndex: 'RoleName',
                key: 'RoleName',
            },
            {
                title: '创建时间',
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                width:220,
                render: time => {
                    return localTime(time)
                }
            },
        ],
        userList: [],
        current_page: 1,
        total: 0,
    }

    componentDidMount() {
        this.getInfo();
        this.getInfoUser();
    }

    getInfo = () => {
        let params = {};
        http.get(`/institution/detail/${this.props.match.params.id}`, params).then(res => {
            if (res) {
                this.setState({
                    detailData: res.data
                })
            }
        })
    }
    getInfoUser = () => {
        let params = {
            page: this.state.currentPage
        }
        http.get(`/institution/detail/${this.props.match.params.id}/user`, params).then(res => {
            if (res) {
                this.setState({
                    userList: res.data.items || [],
                    total: res.data.total,
                    currentPage: res.data.current_page,
                })
            }
        })
    }

    dialogCallback = (data) => {
        data.id = this.state.detailData.ID
        http.post(`/institution/update`, data).then(res => {
            if (res) {
                message.success('更新成功');
                this.addDialogRef.current.close();
                this.getInfo();
                this.getInfoUser();
            }
        })
    }
    pageChange = (page) => {
        this.setState({
            currentPage: page
        }, ()=> {
            this.getInfoUser()
        })
    }

    render() {
        const {detailData, total, currentPage} = this.state;
        return (
            <div className='agency-info-container'>
                <div className="page-title">
                    <div className="title-left">
                        <img src={iconBack} onClick={() => this.props.history.goBack()} className="icon-back" alt=""/>
                        <p className="title">机构详情</p>
                    </div>
                    {
                        detailData.InstitutionType !== 'SUPER_INSTITUTION' &&
                        <Button type="link" onClick={() => {
                            this.addDialogRef.current.show()
                        }}>修改编辑</Button>
                    }
                </div>
                <div className="agency-info-box">
                    <Descriptions title="基础信息" column={1}>
                        <Descriptions.Item label="机构ID">{detailData.ID}</Descriptions.Item>
                        <Descriptions.Item label="机构名称">{detailData.Name}</Descriptions.Item>
                        <Descriptions.Item label="机构人数">{detailData.UserCount}</Descriptions.Item>
                        <Descriptions.Item label="数据权限">{setAgencyRole(detailData.Permission)}</Descriptions.Item>
                        <Descriptions.Item label="状态"><Radio size={'small'} disabled={detailData.Disabled}
                                                             checked={true}/>{detailData.Disabled ? '禁用' : '启用'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">{localTime(detailData.CreatedAt)}</Descriptions.Item>
                    </Descriptions>
                </div>
                <div className={'agency-info-box user-list'}>
                    <Descriptions title="用户列表" column={1}>
                    </Descriptions>
                    <Table columns={this.state.columns}
                           rowKey={'ID'}
                           pagination={false}
                           dataSource={this.state.userList}/>
                    {total > 0 &&
                    <div className={'page-total-data'}>
                        <div>
                            共计 <span className={'total-num'}>{total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.pageChange}
                            defaultCurrent={1}
                            total={total}
                            pageSize={10}
                            current={currentPage}
                        />
                    </div>}
                </div>
                <AddDialog ref={this.addDialogRef} callback={this.dialogCallback} editRowData={detailData}/>

            </div>
        )
    }
}
