import React from 'react';
import {Form,Input,Select,message, Button} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http'
import backUrl from "../../resource/images/common/go-back.svg"
import {rules} from "../../lib/help/rules";
import {regexps_length_30, regexps_length_4} from "../../lib/help/validates";
const { password} = rules;
const {Option} = Select;


export default class addUser extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            type:[
                {name: '类型1',value:1}
            ],
            subType: [],
            isEdit: false,
            passwordRules: [
                ...password,
                {
                    trigger: 'onBlur'
                }
            ],
            confirmPasswordRules: [
                ...password,
                {
                    validator: this.handleCfmPwd,
                    trigger: 'onBlur'
                }
            ],
            detailList:{},
            institution:{},
            roleData:[],
            institutionList:[],
            institutionID:null
        }
    }
    //确认密码校验一致
    handleCfmPwd = (_, value, callback) => {
        let loginPass = this.formRef.current.getFieldValue('password');
        let newPass =  this.formRef.current.getFieldValue('confirm_password');
        if (loginPass && newPass && loginPass !== newPass) {
            callback(new Error('两次密码不一致'))
        } else if(loginPass && newPass && loginPass === newPass) {
           // this.formRef.current.setFieldsValue({password:loginPass});
            callback()
        }
    };
    componentDidMount() {
        this.getInstitutionList()
    }
    getInstitutionList=()=>{
        http.get('/institution/all').then(res => {
            if (res) {
                this.setState({
                    institutionList: res.data.items,
                })
            }
        })
    }
    getRoleList=(tmp)=>{
        http.get('/role/all',tmp).then(res => {
            if (res) {
                this.setState({
                    roleData: res.data.items,
                })
            }
        })
    }
    onFinish =(values) =>{
        const that =this;
        // let  params = {
        // };
        http.post('/user/create', values).then(res => {
            that.props.history.push('/user-info/list');
        }, () => {

        });
        this.setState({
            visible: false,
        });
    };
    onFinishFailed(errorInfo) {
    };
    goBack() {
        this.props.history.goBack();
    };
    onChangeType(e){
        this.setState({
            institutionID:e
        })
        this.getRoleList({institution_id:e})
    }
    onChangeRole() {
        if(this.state.institutionID===null) {
            message.error({
                content: '请先选择所属机构',
            });
        }
    }
    render() {
        const {passwordRules,confirmPasswordRules,institutionID} = this.state;
        return (
            <div className={'add-user-container certInfo'}>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        <img src={backUrl} alt="" onClick={() => this.goBack()} className={'breadcrumb-icon'}/>
                      新增用户
                    </div>
                </div>
                <div className={'add-form'}>
                    <Form
                        name="basic"
                        ref={this.formRef}
                        onFinish={(values) => this.onFinish(values)}
                        onFinishFailed={() => this.onFinishFailed()}>
                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                },
                                {
                                    pattern: regexps_length_4,
                                    message: '请输入4-20个字符',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Input className={'name-input'} placeholder={'请输入用户名'}/>
                        </Form.Item>
                        <div>
                            <Form.Item
                                label="密码"
                                name="password"
                                rules={passwordRules}
                                validateFirst={true}
                            >
                                <Input className={'item-input'} placeholder={'请输入密码'}/>
                            </Form.Item>
                            <Form.Item
                                label="确认密码"
                                name="confirm_password"
                                rules={confirmPasswordRules}
                                validateFirst={true}
                            >
                                <Input className={'item-input'} placeholder={'请输入密码'}/>
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="所属机构"
                            name="institution_id"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select placeholder="请选择" className={'add-select'} onChange={(e) =>this.onChangeType(e)}>
                                {
                                    this.state.institutionList.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="所属部门"
                            name="department"
                            rules={[
                                {
                                    required: false,
                                },
                                {
                                    pattern: regexps_length_30,
                                    message: '请输入1-30个字符',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Input className={'add-select'} placeholder={'请输入部门'}/>
                        </Form.Item>
                        <Form.Item
                            label="角色"
                            name="role_id"
                            rules={[
                                {
                                    required: true,
                                    message: '该项不能为空',
                                }
                            ]}
                            validateFirst={true}
                        >
                            <Select disabled={institutionID===null} placeholder="请选择" className={'add-select'} onClick={() =>this.onChangeRole()}>
                                {
                                    this.state.roleData.map((item) => {
                                        return <Option value={item.ID} key={item.ID} label={item.RoleName}>{item.RoleName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item className="footer-btn">
                            <Button className={'submit-btn detail-btn'} type="primary" htmlType="submit">
                                提交
                            </Button>
                            <Button className={'submit-btn detail-btn reset-btn'} type="primary" onClick={()=>{ this.props.history.push('/user-info/list')}}>
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}