import React, {Component} from 'react'
import {Input, Form, Space, Table, Button, Row, Col, Switch, message, Modal, Pagination} from 'antd';
import './index.scss'
import {localTime, setAgencyRole} from "../../lib/help/helper";
import {Link} from "react-router-dom";
import http from "../../lib/service/http";
import { PlusOutlined } from '@ant-design/icons'
import AddDialog from '../../components/AddAgencyDialog/Index'
import {ReactComponent as IconSearch} from "../../resource/images/common/icon-search.svg";
import {ReactComponent as IconWarning} from "../../resource/images/common/icon-warning.svg";




export default class index extends Component {
    formRef = React.createRef();
    addDialogRef = React.createRef();
    state = {
        realTimeValue: '',
        clearBtn: false,
        total: 0,
        searchVal: {
        },
        data: [],
        acceptor: [],
        checked: false,
        isModalVisible: true,
        editRowData: {},

        columns: [
            {
                title: '机构ID',
                dataIndex: 'ID',
                key: 'ID',
                width: 100
            },
            {
                title: '机构名',
                key: 'Name',
                dataIndex: 'Name',
                width: 300
            },
            {
                title: '机构人数',
                dataIndex: 'UserCount',
                key: 'UserCount',
                width: 120
            },
            {
                title: '数据权限',
                key: 'Permission',
                dataIndex: 'Permission',
                width: 120,
                render: text => {
                    return setAgencyRole(text)
                }
            },
            {
                title: '创建时间',
                width: 260,
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                render: time => {
                    return localTime(time)
                }
            },

            {
                title: '启用丨禁用',
                key: 'Disabled',
                width: 150,
                dataIndex: 'Disabled',
                render: (Disabled, rows, index)=> {
                    return rows.InstitutionType === 'SUPER_INSTITUTION' ? '启用' :
                        <div>
                            <Switch checked={!Disabled} onClick={(checked, event)=> this.switchChange(checked, rows.ID, event, index)}  />
                            <span className='edit-switch-text'>{!Disabled? '启用' : '禁用'}</span>
                        </div>

                }
            },
            {
                title: '操作',
                width: 150,
                key: 'ID',
                dataIndex: 'ID',
                render: (text, record) => (
                    <Space size="small">
                        <Link to={{pathname: `/agency-list/info/${text}`}}>
                            详情
                        </Link>
                        {
                            record.InstitutionType !== 'SUPER_INSTITUTION' &&
                            <Button className={'has-line-item'} type={"link"}  onClick={()=>this.editShowDialog(record)}>编辑</Button>

                        }
                    </Space>
                ),
            },
        ]
    }

    componentDidMount() {
        this.getList()
    }

    clearSearchValue = () => {
        this.formRef.current.resetFields();
        this.setState({realTimeValue: "", clearBtn: false})
    }


    getList = () => {
        let params = {
            ...this.state.searchVal,
        };
        params.page = this.state.currentPage;
        http.get(`/institution/list`, params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items ? res.data.items : [],
                    total: res.data.total,
                    page: res.data.total_page,
                    currentPage: res.data.current_page,
                })
            }
        })
    }

    pageChange = (page) => {
        this.setState({
            currentPage: page
        }, ()=> {
            this.getList()
        })
    }

    dialogCallback = (data) => {
        if(this.state.editRowData?.Name) {
            data.ID = this.state.editRowData.ID
            http.post(`/institution/update`, data).then(res => {
                if (res) {
                    message.success('更新成功');
                    this.addDialogRef.current.close();
                    this.formRef.current.resetFields();
                    this.searchList()
                }
            })
        }else {
            http.post(`/institution/create`, data).then(res => {
                if (res) {
                    message.success('新增成功');
                    this.addDialogRef.current.close();
                    this.formRef.current.resetFields();
                    this.searchList()
                }
            })
        }
    }
    addShowDialog = () => {
        this.setState({
            editRowData: {},
        }, () => {
            this.addDialogRef.current.show()
        })
    }
    editShowDialog = (row) => {
        this.setState({
            editRowData: row,
        }, () => {
            this.addDialogRef.current.show()
        })
    }
    searchList = async () => {
        let val = await this.formRef.current.validateFields();
        this.setState({
            searchVal: {
                ...this.state.searchVal,
                ...val,
            },
            currentPage: 1
        }, ()=> {
            this.getList()
        })
    }
    switchChange = (checked, ID, event, index) => {
        this.setState({
            editID: ID,
            editIndex: index,
            isConfirmVisible: true
        })
    }
    handleCancel = () => {
        this.setState({
            isConfirmVisible: false
        })
    }
    handleOk = () => {
        const {data, editIndex} = this.state;
        let params = {
            id: this.state.editID
        };
        params.page = this.state.currentPage;
        http.post(`/institution/disable`, params).then(() => {
            message.success(data[editIndex]?.Disabled ? '启用成功' : '禁用成功')
            this.getList();
            this.setState({
                isConfirmVisible: false
            })
        })
    }


    render() {
        const {editRowData, isConfirmVisible, data, editIndex} = this.state;
        return (
            <div className='agency-list-container'>
                <div className="page-title">
                    <div className="title-left">
                        <p className="title">机构管理</p>
                    </div>
                    <Button type="primary" icon={<PlusOutlined />} onClick={this.addShowDialog}>新增</Button>
                </div>
                <div className={'info-content container-shadow container'}>
                    <div className="list-search-box">
                        <Form
                            ref={this.formRef}
                        >
                            <Row gutter={16} >
                                <Col span={5}>
                                    {/*<Form.Item*/}
                                    {/*    label={'角色'}*/}
                                    {/*    name='search'*/}
                                    {/*>*/}
                                    {/*    <Select placeholder="请选择" className={'search-select'}>*/}
                                    {/*        {*/}
                                    {/*            this.state.acceptor.map((item) => {*/}
                                    {/*                return <Option value={item} key={item}>{item}</Option>*/}
                                    {/*            })*/}
                                    {/*        }*/}

                                    {/*    </Select>*/}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={5}>
                                    {/*<Form.Item*/}
                                    {/*    label={'部门'}*/}
                                    {/*    name='search'*/}
                                    {/*>*/}
                                    {/*    <Select placeholder="请选择" className={'search-select'}>*/}
                                    {/*        {*/}
                                    {/*            this.state.acceptor.map((item) => {*/}
                                    {/*                return <Option value={item} key={item}>{item}</Option>*/}
                                    {/*            })*/}
                                    {/*        }*/}

                                    {/*    </Select>*/}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={4} className={'search-item'}>
                                    {/*<Form.Item*/}
                                    {/*>*/}
                                    {/*    <Button type="primary" htmlType="submit">*/}
                                    {/*        查询*/}
                                    {/*    </Button>*/}
                                    {/*    <Button>*/}
                                    {/*        重置*/}
                                    {/*    </Button>*/}
                                    {/*</Form.Item>*/}
                                </Col>
                                <Col span={6}  className='last-right-row last-row-input'>
                                    <Form.Item
                                        name='keywords'
                                    >
                                        <Input suffix={
                                            <IconSearch onClick={this.searchList} />
                                        } placeholder={'请输入机构名搜索'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <Table columns={this.state.columns}
                           rowKey={'ID'}
                           pagination={false}
                           dataSource={this.state.data}/>
                    {this.state.total > 0 &&
                    <div className={'page-total-data'}>
                        <div>
                            共计 <span className={'total-num'}>{this.state.total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.pageChange}
                            defaultCurrent={1}
                            total={this.state.total}
                            pageSize={10}
                            current={this.state.currentPage}
                        />
                    </div>}
                </div>
                <AddDialog ref={this.addDialogRef} callback={this.dialogCallback} editRowData={editRowData} />
                <Modal
                    visible={isConfirmVisible}
                    cancelText={'取消'}
                    width={'458px'}
                    okText={'确定'}
                    title={'提示'}
                    centered
                    onOk={this.handleOk}
                    className="warning-dialog"
                    onCancel={this.handleCancel}>
                    <div className="warning-content">
                        <IconWarning />
                        <p className='account-modal-title logout-title'>
                            {
                                data[editIndex]?.Disabled ? '启用机构后，该机构用户将正常使用，确定启用机构？'
                                    : '禁用机构后，该机构用户无法使用，确定禁用机构？'
                            }

                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}
