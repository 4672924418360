import React from 'react';
import { Modal } from 'antd';
import {withRouter} from "react-router-dom";
import './index.scss'

import iconAddSuccess from './../../resource/images/evidence-add/icon-add-success.svg'


class SuccessDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
        if(props.onRef){//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    handleOk = e => {
        this.setState({
            visible: false,
        });
        this.props.history.push(this.props.successTip.routeLink)
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    componentDidMount() {
    }

    render() {
        return (
            <div className={'success-dialog-container'}>
                <Modal
                    centered={true}
                    width={560}
                    wrapClassName={'success-dialog-content'}
                    visible={this.state.visible}
                    onOk={(event)=>this.handleOk(event)}
                    onCancel={this.handleCancel}
                    okText={this.props.successTip.btnText}
                >
                    <img src={iconAddSuccess} alt="" className="icon-success"/>
                    <p className="text-title">已提交</p>
                    <p className="desc">{this.props.successTip.desc}</p>
                </Modal>
            </div>
        )
    }
}
export default withRouter(SuccessDialog)