export function localTime(str) {
    if (!str) return '';
    let date;
    if (str.indexOf('T') !== -1) { // date格式的字符串
        date = new Date(str);
    } else {
        // ios 无法识别 'YYYY-MM-DD HH:mm:ss' - ，转为 /
        date = new Date(str.replace(/-/g, "/"));
    }
    let month = (date.getMonth() + 1 + '').padStart(2, '0');
    let day = (date.getDate() + '').padStart(2, '0');
    let hour = (date.getHours() + '').padStart(2, '0');
    let minute = (date.getMinutes() + '').padStart(2, '0');
    let second = (date.getSeconds() + '').padStart(2, '0');

    return date.getFullYear() + "-" + month + "-" + day + " " +  hour + ":" + minute + ":" + second;
}

export const agencyRole = [
    {
        value: '全部机构',
        key: 'WHOLE'
    },
    {
        value: '仅机构数据',
        key: 'INSTITUTION'
    }
]

export function setAgencyRole (type) {
    let role = agencyRole.filter(item => item.key === type)

    return role[0] ? role[0].value : '- -'
}

export const optionsIndex = [
    { label: '查看', value: '总览页-查看' },
];
export const optionsCopyright = [
    { label: '查看', value: '存证-查看' },
    { label: '创建', value: '存证-创建' },
    { label: '删除', value: '存证-删除' },

];
export const optionsSearch = [
    { label: '查看', value: '取证-查看' },
    { label: '下载', value: '取证-下载' },

];
export const optionsRole = [
    { label: '查看', value: '角色-查看' },
    { label: '创建', value: '角色-创建' },
    { label: '编辑', value: '角色-编辑' },
    // { label: '删除', value: '角色-删除' },

];
export const optionsUser = [
    { label: '查看', value: '用户-查看' },
    { label: '创建', value: '用户-创建' },
    { label: '编辑', value: '用户-编辑' },
];
export const optionsConfig = [
    { label: '查看', value: '存证信息配置-查看' },
    { label: '创建', value: '存证信息配置-创建' },
    { label: '编辑', value: '存证信息配置-编辑' },
];

export const roleOptions = [
    "总览页-查看",
    "存证-查看",
    "存证-创建",
    "存证-删除",
    "取证-查看",
    "取证-下载",
    "角色-查看",
    "角色-创建",
    "角色-编辑",
    // "角色-删除",
    "用户-查看",
    "用户-创建",
    "用户-编辑",
    "存证信息配置-查看",
    "存证信息配置-创建",
    "存证信息配置-编辑"
]

export function jumpLink(roles=[]) {
    if(roles.includes('总览页-查看')){
        return '/index'
    }
    if(roles.includes('存证-查看')){
        return '/block-chain-cert/list'
    }
    if(roles.includes('取证-查看')){
        return '/certificate-query'
    }
    if(roles.includes('角色-查看')){
        return '/role-list'
    }
    if(roles.includes('用户-查看')){
        return '/user-info/list'
    }
    if(roles.includes('存证信息配置-查看')){
        return '/cert-info-config/list'
    }
}
export function downloadBinaryFileStream(fileStream, type = 'excel') {
    const disposition = fileStream.headers['content-disposition']
    let fileName = disposition.substring(disposition.indexOf('filename*=UTF-8') + 17, disposition.length);
    fileName = decodeURIComponent(fileName);
    fileName = fileName.replace(/"/g, "");
    let blobType = ''
    if (type === 'excel') {
        blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
    const url = window.URL.createObjectURL(new Blob([fileStream.data], { type: blobType }))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function getUserRole() {
    return localStorage.getItem('userRole')&&localStorage.getItem('userRole') !== 'undefined' ? JSON.parse(localStorage.getItem('userRole')) : []
}

export function setNumber(num ) {
    if (num !== null && num !== undefined) {
        return  Number(num)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return "- -";
    }
}