import React from 'react';
import {Layout, Menu} from 'antd';

import './index.scss'
import {Link, withRouter} from "react-router-dom";

import icon_home_default from './../../resource/images/aside/icon-home-n.svg'
import icon_home_active from './../../resource/images/aside/icon-home-h.svg'
import icon_query_default from './../../resource/images/aside/icon-search-n.svg'
import icon_query_active from './../../resource/images/aside/icon-search-h.svg'
import icon_agency_n from './../../resource/images/aside/icon-agency-n.svg'
import icon_agency_h from './../../resource/images/aside/icon-agency-h.svg'
import icon_configure_h from './../../resource/images/aside/icon-configure-h.svg'
import icon_configure_n from './../../resource/images/aside/icon-configure-n.svg'
import icon_copyright_h from './../../resource/images/aside/icon-copyright-h.svg'
import icon_copyright_n from './../../resource/images/aside/icon-copyright-n.svg'
import icon_role_h from './../../resource/images/aside/icon-role-h.svg'
import icon_role_n from './../../resource/images/aside/icon-role-n.svg'
import icon_user_h from './../../resource/images/aside/icon-user-h.svg'
import icon_user_n from './../../resource/images/aside/icon-user-n.svg'
import {getUserRole} from '../../lib/help/helper'

const {Sider} = Layout;
class Aside extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openKeys: ['/index'],
            defaultSelectedKeys: ['/index'],
            currentUrl: '/index'
        }
    }



    componentDidMount() {
        let openKeys = this.props.history.location.pathname.split('/')
        this.setState({
            currentUrl: '/'+openKeys[1],
            openKeys: ['/'+openKeys[1]]
        })

        this.props.history.listen(() => {
            let tmpOpenKeys = this.props.history.location.pathname.split('/')
            this.setState({
                openKeys:'/'+ tmpOpenKeys[1],
                currentUrl:'/'+ tmpOpenKeys[1]
            })
        })

    }


    setMenuIcon(url, classNameType = 0) {
        let tmpName = classNameType ? 'menu-icon-active' : 'menu-icon-default';
        return (
            <img src={url} alt="" className={'menu-icon ' + tmpName}/>
        )
    }

    onOpenChange = openKeys => {
        this.setState({
            openKeys: [openKeys]
        })
    };

    setSelected(item) {
        if (item.key === '/index') {
            this.setState({
                currentUrl: item.key,
                openKeys: ['/index']
            })
        } else {
            this.setState({
                currentUrl: item.key
            })
        }

    }



    render() {

        const roleOptions = getUserRole()
        return (
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width={200}
                className={'aside-container'}
            >
                <div className="aside-menu-box">
                    <Menu
                        mode="inline"
                        openKeys={this.state.openKeys}
                        defaultSelectedKeys={this.state.defaultSelectedKeys}
                        onOpenChange={this.onOpenChange}
                        style={{width: '100%'}}
                        selectedKeys={this.state.currentUrl}
                        onClick={(item) => this.setSelected(item)}
                    >
                        {
                            roleOptions.includes('总览页-查看') &&
                            <Menu.Item key="/index" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_home_default)
                                }
                                {
                                    this.setMenuIcon(icon_home_active, 1)
                                }
                                <Link to={'/index'}>总览</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('存证-查看') &&
                            <Menu.Item key="/block-chain-cert" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_copyright_n)
                                }
                                {
                                    this.setMenuIcon(icon_copyright_h, 1)
                                }
                                <Link to={'/block-chain-cert/list'}>区块链存证</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('取证-查看') &&
                            <Menu.Item key="/certificate-query" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_query_default)
                                }
                                {
                                    this.setMenuIcon(icon_query_active, 1)
                                }
                                <Link to={'/certificate-query'}>取证查询</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('机构-查看') &&
                            <Menu.Item key="/agency-list" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_agency_n)
                                }
                                {
                                    this.setMenuIcon(icon_agency_h, 1)
                                }
                                <Link to={'/agency-list'}>机构管理</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('角色-查看') &&
                            <Menu.Item key="/role-list" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_role_n)
                                }
                                {
                                    this.setMenuIcon(icon_role_h, 1)
                                }
                                <Link to={'/role-list'}>角色管理</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('用户-查看') &&
                            <Menu.Item key="/user-info" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_user_n)
                                }
                                {
                                    this.setMenuIcon(icon_user_h, 1)
                                }
                                <Link to={'/user-info/list'}>用户管理</Link>
                            </Menu.Item>
                        }
                        {
                            roleOptions.includes('存证信息配置-查看') &&
                            <Menu.Item key="/cert-info-config" className={'home-item'}>
                                {
                                    this.setMenuIcon(icon_configure_n)
                                }
                                {
                                    this.setMenuIcon(icon_configure_h, 1)
                                }
                                <Link to={'/cert-info-config/list'}>存证信息配置</Link>
                            </Menu.Item>

                        }

                    </Menu>
                </div>
                <div className="aside-footer">
                    <div className="aside-footer-content">
                        Copyright © {new Date().getFullYear()} <br/>
                    </div>
                </div>
            </Sider>

        )
    }
}

export default withRouter(Aside)