import React from 'react';
import {Table, Input, Popover, Empty, ConfigProvider, Pagination} from 'antd';
import {SearchOutlined, PlusOutlined } from '@ant-design/icons';
import CertDialog from "../../components/CertDialog";
import './index.scss'
import {Link} from "react-router-dom";

import iconList from "../../resource/images/evidence-list/icon-obtain-evidence-list.svg";
import iconDownloadActive from "../../resource/images/evidence-list/icon-download-h.svg";
import http from "../../lib/service/http";
import {localTime} from "../../lib/help/helper";



export default class CopyrightList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true,
            dialogComponent: '',
            dataList: [
            ],
            responseData: {
                total: 0,
            },
            page: 1,
            keywords: '',
            certNo: '',
            searchValue: ''
        }
    }

    showDialog(url, certNo) {
        this.setState({
            certUrl: url,
            certNo: certNo
        },  ()=>{
            this.child.showDialog()
        })

    }
    setPages(page, pageSize) {
        this.setState({
            page: page
        },  ()=>{
            this.getListData()
        })
    };
    getSearchList(e) {
        this.setState({
            searchValue: e.target.value,
        })
    }
    getSearchRes() {
        this.setState({
            keywords: this.state.searchValue,
            page: 1
        }, ()=> {
            this.getListData()
        })
    }
    getListData() {
        let params = {
            page: this.state.page,
            keywords: this.state.keywords
        }
        http.get('/copyright/list', params).then(res=>{
            this.setState({
                dataList: res.items,
                responseData: res,
            })
        })
    }
    componentDidMount() {
        this.getListData();
    }

    setListTitle() {
        return [
            {
                title: '作品名称',
                dataIndex: 'Title',
                key: 'Title',
                render: text => {
                    return (
                        <Popover content={
                            <span>{text}</span>
                        }
                                 placement={'bottomLeft'}
                        >
                            《{text}》
                        </Popover>
                    )
                },
            },
            {
                title: '作者名称',
                dataIndex: 'Authors',
                key: 'Authors',
                width: 300,
                render: text => {
                    return (
                        <Popover content={
                            <span>{text}</span>
                        }
                                 placement={'bottomLeft'}
                        >
                            <p className={'hash-content content-remark'}>{text}</p>
                        </Popover>
                    )
                },
            },
            {
                title: '登记时间',
                dataIndex: 'CreatedAt',
                key: 'CreatedAt',
                render: time => {
                    return localTime(time)
                }
            },
            {
                title: '登记状态',
                dataIndex: 'Status',
                key: 'Status',
                render: Status => {
                    return (
                        Status === 'FINISHED' ?
                            <span className={'detail-item item-default'}>制证发证</span> :
                            <span className={'detail-item item-default'}>已通过</span>
                    )
                }
            },
            {
                title: '查看详情',
                dataIndex: 'TmpCertificateUrl',
                key: 'TmpCertificateUrl',
                render: (TmpCertificateUrl, rows) => {
                    return (
                        !TmpCertificateUrl ?
                            <span className={'detail-item item-default'}>暂无详情</span> :
                            <span onClick={()=>this.showDialog(TmpCertificateUrl, rows.NO)} className={'detail-item item-active'}>查看证书</span>
                    )
                }
            },
            {
                title: '操作',
                key: 'TmpFileUrl',
                dataIndex: 'TmpFileUrl',
                render: (TmpFileUrl) => {
                    return (
                                <a target='_blank' href={TmpFileUrl} rel="noopener noreferrer">
                                    <img className={'icon-download'} src={iconDownloadActive} alt=""/>
                                </a>
                    );
                },
            },
        ];
    };


    emptyContent() {
        return (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <span>暂无数据</span>
            } />
        )
    }


    render() {
        const props = {
            current: this.state.page,
            position: 'bottomCenter',
            showSizeChanger: false,
            total: this.state.responseData.total,
            onChange: (page, pageSize) => this.setPages(page, pageSize)
        }
        return (
            <div className="evidence-list-container container-shadow">
                <div className="content-title">
                    <img src={iconList} alt="" className="title-icon"/>
                    <span className="text">
                        存证列表
                    </span>
                </div>
                <div className="evidence-list-content">
                    <div className="list-search-box">
                        <Input className={'search-input'} onChange={(event)=>this.getSearchList(event)} placeholder="输入作品名称关键字搜索登记作品"/>
                        <div className="search-btn" onClick={()=>this.getSearchRes()}>
                            <SearchOutlined/>
                        </div>
                    </div>
                    <ConfigProvider renderEmpty={this.emptyContent}>
                        <Table className={this.state.dataList ? 'list-box' : 'list-box list-no-data-box'}
                               columns={this.setListTitle()}
                               pagination={false}
                               rowKey={'ID'}
                               dataSource={this.state.dataList}/>
                    </ConfigProvider>
                    <Link to={'/copyright-seal/add'} className={'add-box'}>
                        <div className="add-icon-box">
                            <PlusOutlined />
                        </div>
                        添加区块链存证
                    </Link>
                    <Pagination {...props} />
                </div>
                <CertDialog certNo={this.state.certNo} type={'copyright'} certLink={this.state.certUrl} onRef={(ref)=>{ this.child = ref}}  />
            </div>
        )
    }
}