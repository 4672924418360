import React from 'react';
import { Link } from "react-router-dom";

import './index.scss'

import iconClose from './../../resource/images/cert-dialog/seal-close.svg'
import iconDownload from './../../resource/images/cert-dialog/seal-download.svg'
import iconDetail from './../../resource/images/cert-dialog/seal-detail.svg'


export default class CertDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDialog: true,
        }
        if (props.onRef) {//如果父组件传来该方法 则调用方法将子组件this指针传过去
            props.onRef(this)
        }
    }

    componentDidMount() {
    }
    closeDialog() {
        this.setState({
            hideDialog: true,
        });
    }
    showDialog() {
        this.setState({
            hideDialog: false,
        });
    }

    render() {
        return (
            <div className={!this.state.hideDialog ? "cert-dialog-container hide-dialog" : "cert-dialog-container"}>
                <div className="cert-dialog-content">
                    <img src={this.props.certLink} alt="" className="cert-img" />
                    <div className="seal-operates">
                        <div className="modal-close seal-btn-href">
                            <div className="seal-operate ">
                                <div className="btn-wid clearfix" onClick={() => this.closeDialog()}>
                                    <div className="icon-operate"><img
                                        src={iconClose} alt="关闭" /></div>
                                    <div className="operate-text">关闭</div>
                                </div>
                            </div>
                        </div>
                        <a className="seal-btn-href" href={this.props.certLink} target="_blank" rel="noopener noreferrer">
                            <div className="seal-operate seal-operate-mar">
                                <div className="btn-wid clearfix">
                                    <div className="icon-operate"><img
                                        src={iconDownload} alt="下载证书" /></div>
                                    <div className="operate-text">下载证书</div>
                                </div>
                            </div>
                        </a>
                        <Link to={{
                            pathname: '/obtain-evidence/proof/'+this.props.type+'/' + this.props.certNo,
                        }} className="seal-btn-href" >
                            <div className="seal-operate seal-operate-mar">
                                <div className="btn-wid uk-clearfix">
                                    <div className="icon-operate"><img
                                        src={iconDetail} alt="查看详情" /></div>
                                    <div className="operate-text">查看详情</div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}