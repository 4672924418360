import React from 'react';
import { Form, Input, Button } from 'antd';
import {regexps_url, regexps_length_50} from "../../lib/help/validates";
import EvidenceGuide from "../EvidenceGuide";
import SuccessDialog from "../../components/SuccessDialog";

import './index.scss'
import iconAddTitle from './../../resource/images/evidence-add/icon_qzlb.svg'
import http from "../../lib/service/http";

const { TextArea } = Input;


export default class EvidenceAdd extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            validates: {
                regexps_url: [
                    {
                        required: true,
                        message: '请输入正确网址',
                    },
                    {
                        pattern: regexps_url,
                        message: '请输入正确网址',
                    }
                ],
                regexps_remark: [
                    {
                        required: false,
                        message: '限制字数在50之内',
                    },
                    {
                        pattern: regexps_length_50,
                        message: '限制字数在50之内',
                    }
                ]
            },
            successTip: {
                desc: '您的侵权取证申请已提交，请在取证列表中查询取证结果',
                btnText: '前往取证列表',
                routeLink: '/obtain-evidence/list'
            }
        }
    }
    onFinish(values){
        let _this = this;
        http.post('/infringement/store',values).then(res=>{
            _this.child.showModal()
            _this.formRef.current.resetFields();
        })
    };


    onFinishFailed(errorInfo){
    };

    componentDidMount() {
    }


    render() {
        return (
            <div className="obtain-evidence-container container-shadow">
                <EvidenceGuide />
                <div className="obtain-evidence-content">
                    <div className="content-title">
                        <img src={iconAddTitle} alt="" className="title-icon"/>
                        <span className="text">
                            取证申请
                        </span>
                    </div>
                    <Form
                        name="basic"
                        onFinish={values=>this.onFinish(values)}
                        onFinishFailed={(value)=>this.onFinishFailed(value)}
                        className={'form-content'}
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="输入网址"
                            name="url"
                            colon={false}
                            rules={this.state.validates.regexps_url}
                        >
                            <Input
                                size="large"
                                placeholder={'请输入您要取证的URL'}
                            />
                        </Form.Item>
                        <Form.Item
                            label="备注"
                            name="remark"
                            colon={false}
                            rules={this.state.validates.regexps_remark}
                        >
                            <TextArea
                                size="large"
                                placeholder={'您可以添加备注，以方便在列表中进行展示，建议字数不超过50字'}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className={'submit-btn'} htmlType="submit">
                                取证
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
               <SuccessDialog onRef={(ref)=>{ this.child = ref}} successTip={this.state.successTip} />
            </div>
        )
    }
}