import React from 'react';
import { Button } from 'antd';


import './../EvidenceGuide/index.scss'
import './index.scss'
import copyrightGuide from '../../resource/images/guide/img-copyright-guide.svg';
import iconEmC from '../../resource/images/guide/img-em-c.svg';
import iconEmR from '../../resource/images/guide/img-em-r.svg';
import iconEmZ from '../../resource/images/guide/img-em-z.svg';



export default class EvidenceGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowGuide: true
        }
    }

    componentDidMount() {
    }

    hideModule() {
        this.setState({
            isShowGuide: false,
        })
    }

    render() {
        return (
            <div className={ this.state.isShowGuide ? 'evidence-guide-container copyright-guide container-shadow': 'hide-container copyright-guide evidence-guide-container container-shadow'}>
               <div className="guide-img-box">
                   <img src={copyrightGuide} alt="" className="img-guide"/>
                   <span className="forensics-box">
                       <img src={iconEmC} alt="" className="icon-forensics"/>
                   </span>
                   <span className="fulfil-box">
                       <img src={iconEmZ} alt="" className="icon-fulfil"/>
                   </span>
                   <span className="img-rotate-box">
                       <img src={iconEmR} alt="" className="icon-rotate"/>
                   </span>
               </div>
                <p className="text">
                    存证可对全类型证据进行保全，通过提取数据信息并上传至联盟链中。 同步生成时间戳，完成存证。形成具备法律效力的电子证据，更加简单高效。
                </p>
                <Button className={'submit-btn'} type="primary" htmlType="submit" onClick={()=>this.hideModule()}>
                    立刻体验
                </Button>
            </div>
        )
    }
}