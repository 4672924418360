import React from 'react';
import './index.scss'
import iconBlockData from './../../resource/images/block-chain-detail/icon-block-data.png'
import iconBlockDetail from './../../resource/images/block-chain-detail/icon-block-detail.png'
import iconBlockInfo from './../../resource/images/block-chain-detail/icon-block-info.png'
import http from "../../lib/service/http";


export default class EvidenceAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responseData: {},
            descData: [],
            typeData: []
        }
    }


    componentDidMount() {
        this.setState({
            typeData: [this.props.match.params.id, this.props.match.params.type]
        }, () => {
            this.getDetail();
        })
    }

    getDetail() {
        let sendUrl = '/infringement/detail/';
        if (this.state.typeData[1] === 'copyright') {
            sendUrl = '/copyright/detail/'
        }
        http.get(sendUrl + this.state.typeData[0]).then(res => {
            this.setState({
                responseData: res.detail,
            }, () => {
                this.setRenderData(res.detail)
            }, () => {
                this.props.history.replace('/index');
            })
        })
    }

    setRenderData(resData) {
        resData = JSON.parse(resData.Data)
        if (this.state.typeData[1] === 'copyright') {
            this.setState({
                descData: [
                    {
                        label: '数据版本号：',
                        content: `${resData.version}`
                    },
                    {
                        label: '系统流水号：',
                        content: `${resData.uuid}`
                    },
                    {
                        label: '作品名称：',
                        content: resData.evidence_title
                    },
                    {
                        label: '作品署名：',
                        content: `${resData.evidence_authors}`
                    },
                    {
                        label: '作品样本md5：',
                        content: `${resData.evidence_hash}`
                    },
                    {
                        label: '登记标识：',
                        content: resData.organization_name
                    },
                    {
                        label: '域名：',
                        content: resData.organization_domain
                    },
                ]
            })
        } else {
            this.setState({
                descData: [
                    {
                        label: '数据版本号：',
                        content: `${resData.version}`
                    },
                    {
                        label: '交易号：',
                        content: `${resData.uuid}`
                    },
                    {
                        label: '取证网址：',
                        content: `${resData.evidence_title}`
                    },
                    {
                        label: '侵权取证（文件MD5码）：',
                        content: `${resData.evidence_hash}`
                    },
                    {
                        label: '登记标识：',
                        content: resData.organization_name
                    },
                    {
                        label: '域名：',
                        content: resData.organization_domain
                    },
                ]
            })
        }
    }


    render() {
        return (
            <div className="block-chain-info-container container-shadow">
                <div className="content-title">
                    <img src={iconBlockDetail} alt="" className="title-icon" />
                    <span className="text">
                        区块链数据信息
                    </span>
                </div>
                <div className="block-chain-info-content">
                    {/*<div className="info-item-box container-shadow">*/}
                    {/*    <div className="title-box">*/}
                    {/*        <img src={iconBasic} alt="" className="title-icon"/>*/}
                    {/*        <p className="title">基本信息</p>*/}
                    {/*    </div>*/}
                    {/*    <div className="info-list">*/}
                    {/*        <span className="label-text">交易地址：</span>*/}
                    {/*        <span className="desc-text">ca186a825d3046c8c0678fcb3a3ddec512c61bf966b49a1c4f464667c00ed6e6</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="info-list">*/}
                    {/*        <span className="label-text">发送地址：</span>*/}
                    {/*        <span className="desc-text">ca186a825d3046c8c0678fcb3a3ddec512c61bf966b49a1c4f464667c00ed6e6</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="info-item-box container-shadow">
                        <div className="title-box">
                            <img src={iconBlockInfo} alt="" className="title-icon" />
                            <p className="title">区块信息</p>
                        </div>
                        <div className="info-list">
                            <span className="label-text">HASH：</span>
                            <span className="desc-text">{this.state.responseData.Hash}</span>
                        </div>
                        <div className="info-list">
                            <span className="label-text">区块高度：</span>
                            <span className="desc-text">{this.state.responseData.BlockHeight}</span>
                        </div>
                        <div className="info-list">
                            <span className="label-text">创建时间：</span>
                            <span className="desc-text">{this.state.responseData.Timestamp}</span>
                        </div>
                    </div>
                    <div className="info-item-box container-shadow">
                        <div className="title-box">
                            <img src={iconBlockData} alt="" className="title-icon" />
                            <p className="title">附加数据</p>
                            {/*<Popover content={<span className={'poper-text'}>复制下方16进制码前往第三方解码</span>}>*/}
                            {/*    <span className="link-text">*/}
                            {/*    在线解码地址*/}
                            {/*    <a href="http://tool.lu/hexstr" title="复制下方16进制码前往第三方解码" className="link">*/}
                            {/*        http://tool.lu/hexstr*/}
                            {/*    </a>*/}
                            {/*</span>*/}
                            {/*</Popover>*/}
                        </div>
                        <div className="attach-data">
                            <div className="attach-list">
                                <div className="attach-title">
                                    附加数据：
                                </div>
                                <p className="attach-content">
                                    {this.state.responseData.Data}
                                </p>
                            </div>
                            <div className="attach-list">
                                <div className="attach-title">
                                    解码数据说明：
                                </div>
                                {
                                    this.state.descData.map(item => {
                                        return (
                                            <p key={item.label} className="attach-content-desc">
                                                {item.label + item.content}
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}