import React, { Component } from 'react'
import { Input, Tabs, Table, Space,  Form } from 'antd';
import { Link } from "react-router-dom";
import http from "../../lib/service/http";
import { localTime } from "../../lib/help/helper";
import iconSearch from '../../resource/images/access-query/icon_search.svg';
import './index.scss';

export default class index extends Component {
    formRef = React.createRef();
    state = {
        tabsActiveKey: '1',
        clearBtn: false,
        type: 'copyright',
        certNo: '',
        searchValue: '',
        realTimeValue: '',
        depositColumns: [
            {
                title: '作品名称',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
            },
            {
                title: '作者名称',
                key: 'Authors',
                dataIndex: 'Authors',
                ellipsis: true,
            },
            {
                title: '登记时间',
                width: 260,
                dataIndex: 'time',
                key: 'time',
                render: time => {
                    return localTime(time)
                }
            },
            {
                title: '详情',
                width: 170,
                dataIndex: 'TmpCertificateUrl',
                key: 'TmpCertificateUrl',
                render: (TmpCertificateUrl, rows) => {
                    return (
                        !TmpCertificateUrl ?
                            <span className={'detail-item item-default'}>暂无详情</span> :
                            <span >已制证</span>
                        // <span onClick={() => this.showDialog(TmpCertificateUrl, rows.NO)} className={'detail-item item-active'}>查看证书</span>
                    )
                }
            },
            {
                title: '操作',
                width: 150,
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Link
                            style={{ color: '#EA4335' }}
                            to={{
                                pathname: `/access-details/&type=${this.state.type}&id=${record.id}`,
                            }} >
                            查看详情
                        </Link>
                        {/* <a target='_blank' href={record.TmpFileUrl} rel="noopener noreferrer">
                            下载
                                </a> */}
                    </Space>
                ),
            },
        ],

        takeColumns: [
            {
                title: '取证地址',
                dataIndex: 'address',
                key: 'address',
                ellipsis: true,
                width: '25%'
            },
            {
                title: '取证时间',
                dataIndex: 'time',
                key: 'time',
                width: 220,
                render: time => {
                    return localTime(time)
                }
            },
            {
                title: '详情',
                width: 150,
                dataIndex: 'TmpCertificateUrl',
                key: 'TmpCertificateUrl',
                render: (TmpCertificateUrl, rows) => {
                    return (
                        !TmpCertificateUrl ?
                            <span className={'detail-item item-default'}>暂无详情</span> :
                            <span >已制证</span>
                        // <span onClick={() => this.showDialog(TmpCertificateUrl, rows.NO)} className={'detail-item item-active'}>查看证书</span>
                    )
                }
            },
            {
                title: '备注',
                key: 'des',
                dataIndex: 'des',
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: 150,
                render: (text, record) => (
                    <Space size="middle">
                        <Link
                            style={{ color: '#EA4335' }}
                            to={{
                                pathname: `/access-details/&type=${this.state.type}&id=${record.id}`,
                            }} >
                            查看详情
                        </Link>
                        {/* <div>
                            {
                                record.FileStatus === 'FINISHED' &&
                                <Popover
                                    className={'download-popover'}
                                    placement={'bottom'}
                                    content={
                                        <div className="tip-box">
                                            <div className="top">
                                                <img src={iconDownloadActive} alt="" className="top-icon" />
                                                <p className="tip-text">
                                                    下载完整证据包
                                                </p>
                                            </div>
                                            <div className="message">
                                                证据包包含网页截图、网页源码、加载资源及取证日志等文件
                                            </div>
                                        </div>
                                    }
                                >
                                    <a href={record.TmpFileUrl} target="_blank" rel="noopener noreferrer">
                                        下载
                                    </a>

                                </Popover>
                            }
                            {
                                record.FileStatus === 'FAILED' && <p className={'download-file'}>证据包提取失败</p>
                            }
                            {
                                record.FileStatus === 'PENDING' &&
                                <span>
                                    <span className={'action-text'}>证据包正在提取中...</span>
                                </span>
                            }
                        </div> */}
                    </Space>
                ),

            },
        ],
        copyrightTotal: 0,
        infringementTotal: 0,
        depositDataSource: [],
        takeDataSource: [],

    }

    tabsChange = (activeKey) => {
        this.setState({
            tabsActiveKey: activeKey,
            type: activeKey === '1' ? 'copyright' : 'infringement'
        })
    }


    componentDidMount() {
        let value = this.props.location.pathname.split('&value=')[1];

        this.setState({
            realTimeValue: value,
            clearBtn: true
        })

        this.formRef.current.setFieldsValue({
            search: value,
        });
        this.getDetail(value);
    }

    showDialog(url, certNo) {
        this.setState({
            certUrl: url,
            certNo: certNo
        }, () => {
            this.child.showDialog()
        })

    }

    getDetail(value) {
        window.history.replaceState(null, null, `/#/access-query-list/&value=${value}`)

        // value !== '' ?
        Promise.all([http.get('/search/copyright', { keywords: value }), http.get('/search/infringement', { keywords: value })]).then((result) => {
            let depositDataSource = result[0].items?.map((item, i) => {
                return {
                    key: item.ID,
                    address: item.Title,
                    time: item.CreatedAt,
                    TmpCertificateUrl: item.CertificatePath,
                    Authors: item.Authors,
                    id: item.NO,
                    TmpFileUrl: item.TmpFileUrl,
                    FileStatus: item.FileStatus
                }
            });
            let takeDataSource = result[1].items?.map((item, i) => {
                return {
                    key: item.ID,
                    address: item.Url,
                    time: item.CreatedAt,
                    TmpCertificateUrl: item.CertificatePath,
                    des: item.Remark,
                    id: item.NO,
                    TmpFileUrl: item.TmpFileUrl,
                    FileStatus: item.FileStatus
                }
            })

            this.setState({
                depositDataSource,
                takeDataSource,
                searchValue: value,
                copyrightTotal: result[0].total,
                infringementTotal: result[1].total,
                total: result[0].total + result[1].total
            })
        }).catch((error) => {
        })
        // : message.error('搜索内容为空');
    }
    onSearch = (value) => {
        this.formRef.current.validateFields().then(values => {
            this.getDetail(value);
        })
            .catch(errorInfo => {
            });
    }


    clearSearchValue = () => {
        this.formRef.current.resetFields();
        this.setState({ realTimeValue: "", clearBtn: false })
    }

    onChange = (e) => {
        if (e.target.value !== '') {
            this.setState({
                clearBtn: true
            })
        } else {
            this.setState({
                clearBtn: false
            })
        }
        this.setState({
            realTimeValue: e.target.value
        })
    }


    pageChange = (page, pageSize, type) => {
        if (type === 'copyright') {
            http.get('/search/copyright', { keywords: this.state.realTimeValue, page }).then(res => {
                let depositDataSource = res.items?.map((item, i) => {
                    return {
                        key: item.ID,
                        address: item.Title,
                        time: item.CreatedAt,
                        TmpCertificateUrl: item.CertificatePath,
                        Authors: item.Authors,
                        id: item.NO,
                        TmpFileUrl: item.TmpFileUrl,
                        FileStatus: item.FileStatus
                    }
                });
                this.setState({ depositDataSource, copyrightTotal: res.total })
            })
        } else {
            http.get('/search/infringement', { keywords: this.state.realTimeValue, page }).then(res => {
                let takeDataSource = res.items?.map((item, i) => {
                    return {
                        key: item.ID,
                        address: item.Url,
                        time: item.CreatedAt,
                        TmpCertificateUrl: item.CertificatePath,
                        des: item.Remark,
                        id: item.NO,
                        TmpFileUrl: item.TmpFileUrl,
                        FileStatus: item.FileStatus
                    }
                });
                this.setState({ takeDataSource, infringementTotal: res.total })
            })

        }

    }


    render() {
        const { TabPane } = Tabs;
        const { Search } = Input;


        const noData = (
            <div className='noData'>
                <div className='content'>
                    <section>
                        <i></i><span>暂无数据</span>
                    </section>
                </div>
            </div>
        );

        return (
            <div className='access-query-list'>
                <section className='search-block'>
                    <header>区块链存取证查询</header>
                    <Form
                        style={{ width: '720px', margin: '0 auto' }}
                        ref={this.formRef}
                    >
                        <Form.Item
                            name='search'
                            rules={[{ required: true, message: '搜索内容不能为空！' }]}>
                            <Search
                                // value={this.state.realTimeValue}
                                className='search-box'
                                placeholder="可以根据作品名称/作者姓名/区块存证编号进行搜索"
                                enterButton="搜索"
                                size="large"
                                addonAfter={
                                    (
                                        <>
                                            {
                                                this.state.clearBtn ? <span className="clear-btn"
                                                    onClick={this.clearSearchValue}
                                                > 清除</span> : ''}
                                        </>
                                    )

                                }
                                onSearch={this.onSearch}
                                onChange={this.onChange}
                            />
                        </Form.Item>
                    </Form>

                    {/* <div className="clear-btn" onClick={this.clearSearchValue} >清除</div> */}
                </section>

                <div className='search-info-title'>为您找到<span>{this.state.searchValue}</span>相关结果约<span>{this.state.total}</span>
                    个</div>
                <section className='table-box'>
                    <div className="content-title">
                        <img src={iconSearch} alt="" className="title-icon" />
                        <span className="text">
                            查询结果
                    </span>
                    </div>
                    <div className='tabs'>
                        <Tabs defaultActiveKey="1" centered
                            onChange={this.tabsChange}
                        >
                            <TabPane tab="区块链存证" key="1">
                                {this.state?.depositDataSource?.length > 0 ? <Table columns={this.state.depositColumns}
                                    pagination={{
                                        showSizeChanger: false,
                                        total: this.state.copyrightTotal,
                                        onChange: (page, pageSize) => this.pageChange(page, pageSize, 'copyright'),
                                        position: ['none', 'bottomCenter'],
                                        pageSize: 10,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <a style={{ color: '#EA4335' }}>上一页</a>;
                                            }
                                            if (type === 'next') {
                                                return <a style={{ color: '#EA4335' }}>下一页</a>;
                                            }
                                            return originalElement;
                                        }
                                    }}
                                    dataSource={this.state.depositDataSource} /> : noData}
                            </TabPane>
                            <TabPane tab="区块链侵权取证" key="2">
                                {this.state?.takeDataSource?.length > 0 ? <Table columns={this.state.takeColumns}
                                    pagination={{
                                        showSizeChanger: false,
                                        total: this.state.infringementTotal,
                                        onChange: (page, pageSize) => this.pageChange(page, pageSize, 'infringement'),
                                        position: ['none', 'bottomCenter'],
                                        pageSize: 10,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <a style={{ color: '#EA4335' }}>上一页</a>;
                                            }
                                            if (type === 'next') {
                                                return <a style={{ color: '#EA4335' }}>下一页</a>;
                                            }
                                            return originalElement;
                                        }
                                    }}
                                    dataSource={this.state.takeDataSource} /> : noData}
                            </TabPane>
                        </Tabs>
                    </div>
                </section>
                {/* <CertDialog certNo={this.state.certNo} type={'copyright'} certLink={this.state.certUrl} onRef={(ref) => { this.child = ref }} /> */}
            </div>
        )
    }
}
