import React, {Component} from 'react'
import {Descriptions, Radio, Button, Checkbox} from 'antd';
import './index.scss'
import {getUserRole, localTime} from "../../lib/help/helper";
import http from "../../lib/service/http";
import iconBack from '../../resource/images/common/icon-back.svg'
import {optionsIndex, optionsCopyright, optionsUser, optionsSearch, optionsRole, optionsConfig} from '../../lib/help/helper'
let roleOptions = getUserRole()

export default class Index extends Component {
    formRef = React.createRef();
    state = {
        detailData: {
            role_obj: {},
            role_options: [],
        },
        acceptor: []
    }

    componentDidMount() {
        this.getInfo();
    }

    getInfo = () => {
        http.get(`/role/detail/${this.props.match.params.id}`).then(res => {
            if (res) {
                this.setState({
                    detailData: res.data
                })
            }
        })
    }
    render() {
        const {detailData} = this.state;
        const {role_obj, role_options} = detailData;
        roleOptions = getUserRole()
        return (
            <div className='agency-info-container'>
                <div className="page-title">
                    <div className="title-left">
                        <img src={iconBack} onClick={() => this.props.history.goBack()} className="icon-back" alt=""/>
                        <p className="title">角色详情</p>
                    </div>
                    {
                        roleOptions.includes('角色-编辑') && role_obj.RoleType !== 'SUPER_ROLE' &&
                        <Button type="link" onClick={() => {
                            this.props.history.push('/role-list/edit/'+this.props.match.params.id)
                        }}>修改编辑</Button>
                    }
                </div>
                <div className="agency-info-box">
                    <Descriptions title="基础信息" column={1}>
                        <Descriptions.Item label="角色名">{role_obj.RoleName}</Descriptions.Item>
                        <Descriptions.Item label="角色描述">{role_obj.Remark}</Descriptions.Item>
                        <Descriptions.Item label="所属机构">{role_obj.InstitutionName}</Descriptions.Item>
                        <Descriptions.Item label="状态"><Radio size={'small'} disabled={role_obj.Disabled} checked={true} />{role_obj.Disabled ? '禁用' : '启用'}</Descriptions.Item>
                        <Descriptions.Item label="角色ID">{role_obj.ID}</Descriptions.Item>
                        <Descriptions.Item label="创建时间">{localTime(role_obj.CreatedAt)}</Descriptions.Item>
                    </Descriptions>
                </div>
                <div className={'agency-info-box user-list'}>
                    <Descriptions title="权限详情" column={1}>
                        <Descriptions.Item label="总览页">
                            <Checkbox.Group disabled value={role_options} options={optionsIndex}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="存证管理">
                            <Checkbox.Group disabled value={role_options} options={optionsCopyright} />
                        </Descriptions.Item>
                        <Descriptions.Item label="取证管理">
                            <Checkbox.Group disabled value={role_options} options={optionsSearch} />
                        </Descriptions.Item>
                        <Descriptions.Item label="角色管理">
                            <Checkbox.Group disabled value={role_options} options={optionsRole} />
                        </Descriptions.Item>
                        <Descriptions.Item label="用户管理">
                            <Checkbox.Group disabled value={role_options} options={optionsUser} />
                        </Descriptions.Item>
                        <Descriptions.Item label="存证信息配置">
                            <Checkbox.Group disabled value={role_options} options={optionsConfig} />
                        </Descriptions.Item>
                    </Descriptions>
                </div>

            </div>
        )
    }
}
