import React from 'react';
import {Radio} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss';
import http from './../../lib/service/http'
import backUrl from "../../resource/images/common/go-back.svg"
import {localTime, getUserRole} from "../../lib/help/helper";

export default class UserIfoDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList: {
                Name:'姓名',
                files: [{
                    name: '1.txt'
                },
                    {
                        name: '2.txt'
                    }],
                Record: [
                    {
                        name: 'de',
                        type: 'h',
                        people:'12'
                    },
                ]
            },
            isShow: false,
            isDisabled: false,
            ID:'',
            institution: {}
        }
    }

    componentDidMount() {
        this.setState({
            ID:this.props.match.params.id
        })
        this.getListData(this.props.match.params.id)
    }
    getListData(params) {
        http.get(`/user/detail/${params}`).then(res => {
            this.setState({
                detailList: res.data.user_obj,
                institution:res.data.institution_obj
            });
        })
    };
    goBack() {
        this.props.history.goBack();
    };
    showPaw(){
       this.setState({
           isShow: !this.state.isShow
       })
    }
    goEdit = ()=> {
        this.props.history.push({
            pathname:'/user-info/edit/'+this.state.ID,
        });
    };
    // goEdit() {
    //     this.props.history.push({
    //         pathname:'/user-info/add/'+this.state.ID,
    //     });
    // }
    render() {
        const roleOptions = getUserRole()
        const {detailList ,institution} = this.state;
        return (
            <div className={'detail-user-container certInfo'}>
                <div className={'header-navigation detail'}>
                    <div className={'breadcrumb'}>
                        <img src={backUrl} alt="" onClick={() => this.goBack()} className={'breadcrumb-icon'}/> 用户详情
                    </div>
                    <div>
                        {
                            roleOptions.includes('用户-编辑') && detailList.UserType !== 'SUPER_USER' &&
                            <div className="detail-btn-text" onClick={()=>this.goEdit()}>
                                编辑</div>
                        }

                    </div>
                </div>
                <div className={'detail-card'}>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>用户名：</div>
                        <div className={'name-text'}>{detailList.UserName}</div>
                    </div>
                    {/*<div className={'detail-row'}>*/}
                    {/*    <div className={'name-title'}>密码：</div>*/}
                    {/*    <div className={'name-text password'}>*/}
                    {/*        <div style={{width:"60px"}}>{isShow? detailList.Name:"******"}</div>*/}
                    {/*        <span className={'name-text-other show'} onClick={()=>this.showPaw()}>*/}
                    {/*            {isShow? '隐藏':"显示"}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'detail-row'}>
                        <div className={'name-title'}>状态：</div>
                        {
                            detailList.Disabled===1?<div>
                                <Radio defaultChecked disabled className={'disable'}/>
                                 禁用
                            </div>:<div>
                                <Radio defaultChecked disabled className={'using'}/>启用
                            </div>
                        }
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>角色ID：</div>
                        <div className={'name-text'}>{detailList.RoleID}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>创建时间：</div>
                        <div className={'name-text'}>{localTime(detailList.CreatedAt)}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>所属机构：</div>
                        <div className={'name-text'}>{institution.Name}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>所属部门：</div>
                        <div className={'name-text'}>{detailList.Department||'--'}</div>
                    </div>
                    <div className={'detail-row'}>
                        <div className={'name-title'}>角色：</div>
                        <div className={'name-text'}>{detailList.RoleName}</div>
                    </div>
                </div>
            </div>
        )
    }
}