import React from 'react';
import {Button, Form, Input} from 'antd';
import {regexps_length_4, regexps_code_4} from '../../lib/help/validates'
import './index.scss'
import iconAccount from './../../resource/images/login/icon_account.svg'
import iconPassword from './../../resource/images/login/icon_password.svg'
import http from './../../lib/service/http'
import Cookie from 'js-cookie';
import logo from "../../resource/images/login/login-logo.svg";
import iconImageCode from "../../resource/images/login/icon-image-code.svg";
import iconRefreshH from "../../resource/images/login/icon-refresh-h.svg";
import iconRefreshN from "../../resource/images/login/icon-refresh-n.svg";
import {jumpLink} from '../../lib/help/helper'

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 24,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 0,
        span: 16,
    },
}

export default class Login extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            validates: {
                regexps_mobile: [
                    {
                        required: true,
                        message: '该项不能为空',
                    },
                    {
                        pattern: regexps_length_4,
                        message: '请输入4-20个字符',
                    }
                ],
                regexps_password: [
                    {
                        required: true,
                        message: '该项不能为空',
                    },
                    {
                        pattern: regexps_length_4,
                        message: '请输入4-20个字符',
                    },
                ],
                regexps_code: [
                    {
                        required: true,
                        message: '该项不能为空',
                    },
                    {
                        pattern: regexps_code_4,
                        message: '请输入4位正确的图片识别码',
                    },
                ]

            },
            imageCode: '',
            uuid: '',
        }
    }
     onFinish(values){
        values.uuid = this.state.uuid;
         http.post('/auth/login',values).then(res=>{
             Cookie.set('token', res.token);
             this.getUserInfo();
        }, ()=> {
             this.formRef.current.setFieldsValue({code: ''})
             this.getImageCode();
         })
    };
    getUserRole  = () => {
        http.get('/auth/permissions').then(res=>{
            let moduleArr = []
            for(let key in res.modules) {
                moduleArr.push(key)
            }
            localStorage.setItem('userRole', JSON.stringify(moduleArr))
            this.props.history.replace(jumpLink(moduleArr))
        })
    }
    getUserInfo() {
        http.get('/auth/user-info').then(res=>{
            localStorage.setItem('userInfo', JSON.stringify(res.user));
            this.getUserRole();
        })
    }
    getImageCode = () => {
        http.get('/image-captcha/generate').then(res=>{
            this.setState({
                imageCode: res.image,
                uuid: res.uuid
            })
        })
    }

    onFinishFailed(errorInfo){
    };
    componentDidMount() {
        let tmpQuery = this.props.location.search.split('&');
        let tokenQuery = tmpQuery.filter(item=>item.indexOf('token') > -1)
        if(tokenQuery.length > 0) {
            let tmpToken = tokenQuery[0].slice(tokenQuery[0].indexOf('token=')+6, )
            Cookie.set('token', tmpToken);
            this.getUserInfo();
        }
        this.getImageCode();
        let tmpDom = document.getElementsByClassName('aside-container');
        tmpDom[0].style.display='none'
        document.getElementsByClassName('ant-layout-content')[0].classList.add("login-layout-content")
    }
    componentWillUnmount () {
        let tmpDom = document.getElementsByClassName('aside-container');
        tmpDom[0].style.display='block'
        document.getElementsByClassName('ant-layout-content')[0].classList.remove("login-layout-content")
    }

    setIcon(url) {
        return (
            <div className="icon-box">
                <img className={'icon'} src={url} alt=""/>
            </div>
        )
    }
    setImageCodeIcon() {
        return (
            <div className="image-code-box">
                {
                    this.state.imageCode &&
                    <img src={'data:image/jpeg;base64,'+this.state.imageCode} alt="" className="img-code"/>
                }
                <div className="refresh-code" onClick={this.getImageCode}>
                    <img src={iconRefreshH} alt="" className="refresh-btn"/>
                    <img src={iconRefreshN} alt="" className="refresh-btn refresh-btn-default"/>
                </div>
            </div>
        )
    }
    render() {
        let tmpQuery = this.props.location.search.split('&');
        let tokenQuery = tmpQuery.filter(item=>item.indexOf('token') > -1)
        if(tokenQuery.length > 0) {
            return null
        }
        return (

            <div className="login-container">
                <div className={"header-container"}>
                    <div className="header-content">
                        <img src={logo} alt="" className="header-logo"/>
                        {/*<p className="logo-header-text"></p>*/}
                    </div>
                </div>
                <div className="login-content">
                    <p className="title">
                        账号登录
                    </p>
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        ref={this.formRef}
                        onFinish={(values)=>this.onFinish(values)}
                        onFinishFailed={()=>this.onFinishFailed()}
                    >
                        <Form.Item
                            label=""
                            name="username"
                            rules={this.state.validates.regexps_mobile}
                        >
                            <Input size="large" bordered={false} placeholder={'请输入账号'} prefix={this.setIcon(iconAccount)} />
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="password"
                            className="password-input"
                            rules={this.state.validates.regexps_password}
                        >
                            <Input.Password bordered={false} size="large" placeholder={'请输入密码'}
                                            prefix={this.setIcon(iconPassword)}
                            />
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="code"
                            rules={this.state.validates.regexps_code}
                            className={'image-code-input'}
                        >
                            <Input bordered={false} size="large"
                                   placeholder={'请输入图片识别码'}
                                   prefix={this.setIcon(iconImageCode)}
                                   suffix={this.setImageCodeIcon(iconImageCode)}
                            />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button className={'submit-btn'} type="primary" htmlType="submit">
                               登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}