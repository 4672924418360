import React from 'react';
import {Form, Input, Pagination, Select, Table, Space, Switch, Modal, message, Popover} from 'antd';
import './index.scss';
import '../../resource/styles/certInfo.scss'
import http from './../../lib/service/http'
import searchInputIcon from './../../resource/images/common/input-search.jpg'
import closeImg from "./../../resource/images/common/cancle.svg";
import ChangePaw from "./changePaw";
import {getUserRole, localTime} from "../../lib/help/helper";
import {ReactComponent as IconWarning} from "../../resource/images/common/icon-warning.svg";
const {Option} = Select;
let roleOptions = getUserRole()

export default class UserInfo extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            total:1,
            department:[],
            type:[
            ],
            visible: false,
            certInfo:{
                name:1
            },
            isEdit:false,
            checked: 0,
            userId: 0,
            data: [],
            userRole:[],
            institutionList: [],
            currentPage: 1,
            keywords:'',
            isConfirmVisible:false,
            editIndex: 0,
            userInfo: {},
            columns: [
                {
                    title: '用户ID',
                    dataIndex: 'ID',
                    key: 'ID',
                    width: 140,
                    fixed: 'left'
                },
                {
                    title: '用户名',
                    dataIndex: 'UserName',
                    key: 'UserName',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '所属机构',
                    dataIndex: 'InstitutionName',
                    key: 'InstitutionName',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '角色',
                    dataIndex: 'RoleName',
                    key: 'RoleName',
                    render: text => {
                        return text ? <Popover content={text} trigger="hover">
                            <span className="list-desc">{text}</span>
                        </Popover> : '- -'
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'CreatedAt',
                    key: 'CreatedAt',
                    width:220,
                    render: time => {
                        return time?<Popover content={localTime(time)} trigger="hover">
                            <span>{localTime(time)}</span>
                        </Popover> : '- -'

                    }
                },
                {
                    title: '启用丨禁止',
                    dataIndex: 'Disabled',
                    key: 'Disabled',
                    width:120,
                    render: (val,row,index) => {
                        return <div>
                            { (roleOptions.includes('用户-编辑') && row.UserType !== 'SUPER_USER') ?
                                <Switch checked={val === 0}
                                        onChange={(checked, event) => this.changeStatus(checked, row.ID, event, index)}/>:''}
                                    <span className='edit-switch-text'>{val === 0 ? '启用' : '禁用'}</span>
                        </div>;
                    }
                },
                {
                    title: '操作',
                    key: 'ID',
                    dataIndex: 'ID',
                    fixed: 'right',
                    width:260,
                    render: (text, record) => {
                        return <Space size="middle" className={'operate-btn'}>
                            <a onClick={this.goDetail.bind(this, record)}>详情</a>
                            {
                                (roleOptions.includes('用户-编辑') && record.UserType !== 'SUPER_USER') &&
                                <a onClick={this.edit.bind(this, record)}>编辑</a>

                            }
                            {
                                ((roleOptions.includes('用户-编辑') && record.UserType !== 'SUPER_USER') || (record.UserType === 'SUPER_USER' && this.state.userInfo.UserType === 'SUPER_USER')) &&
                                <a onClick={this.editPaw.bind(this, record)}>修改密码</a>

                            }

                        </Space>
                    },
                },
            ]
        }
    }

    componentDidMount() {
        const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
        this.setState({
            userInfo
        })
        let tmp= {
            page:1
        };
        this.getList(tmp)
        // this.getDepartment({page:1})
        this.getInstitutionList()
        this.getUserRole({page:1})
    }
    changeStatus(checked, ID, event, index) {
        this.setState({
            editID: ID,
            editIndex: index,
            isConfirmVisible: true
        })
    }
    handleOk = () => {
        const {data, editIndex} = this.state;
        let params = {
            user_id: this.state.editID,
            page : this.state.currentPage
        };
        http.post('/user/disable', params).then(res => {
            message.success(data[editIndex]?.Disabled ? '启用成功' : '禁用成功')
            let tmp= {
                page:this.state.currentPage,
                keywords:''
            };
            this.getList(tmp)
            this.setState({
                isConfirmVisible: false
            })
        }, () => {

        });
    }
    getInstitutionList=()=>{
        http.get('/institution/all').then(res => {
            if (res) {
                this.setState({
                    institutionList: res.data.items,
                    department: []
                }, () => {
                })
            }
        })
    }
    editPaw(row) {
        this.setState({
            visible: true,
            userId: row.ID
        })
    };
    goDetail(record) {
        this.props.history.push({
            pathname:'/user-info/detail/'+record.ID
        });
    };
    edit = (record)=> {
        this.props.history.push({
            pathname:'/user-info/edit/'+record.ID
        });
    };
    downLoad(item) {
    };
    search = () => {
        let params= {
            institution_id: this.formRef.current.getFieldValue('institution_id') || '',
            department: this.formRef.current.getFieldValue('department') || '',
            role_name: this.formRef.current.getFieldValue('role_name')  ||  '',
            keywords:this.state.keywords,
           // page: this.state.currentPage
            page:''
        };
        this.getList(params);
        this.setState({
            currentPage: 1,
        });
    };
    reset = () => {
        this.formRef.current.resetFields();
        let params= {
            keywords:this.state.keywords,
            page: 1
        };
        this.getList(params)
        this.setState({
            currentPage: 1,
            department: []
        });
    };
    onChangePage = page => {
        this.setState({
            currentPage: page,
        });
        let params = {
            institution_id: this.formRef.current.getFieldValue('institution_id') || '',
            department: this.formRef.current.getFieldValue('department') || '',
            role_name: this.formRef.current.getFieldValue('role_name')  ||  '',
            keywords:this.state.keywords,
            page:page,
        };
        this.getList(params)
        this.getDepartment(params)
        this.getUserRole(params)
    };
    getList = (params) => {
        for(var key in params){
            if(params[key] ===''){
                delete params[key]
            }
        }
        http.get('/user/list',params).then(res => {
            if (res) {
                this.setState({
                    data: res.data.items ||[],
                    total: res.data.total
                })
            }
        })
    };
    getUserRole=(tmp)=> {//
        http.get('/user/role',tmp).then(res => {
            if (res) {
                this.setState({
                    userRole: res.data.items || []
                })
            }
        })
    }
    getDepartment=(tmp)=> {
        http.get('/user/department',tmp).then(res => {
            if (res) {
                this.setState({
                    department: res.data.items || []
                })
            }
        })
    };
    getSearchVal = (e) => {
        this.setState({
            keywords: e.target.value
        })
        if (e.target.value.length===0) {
            let params= {
                institution_id: this.formRef.current.getFieldValue('institution_id') || '',
                department: this.formRef.current.getFieldValue('department') || '',
                role_name: this.formRef.current.getFieldValue('role_name')  ||  '',
                keywords:'',
                page: ''
            };
            this.getList(params);
        }
    };
    add=()=> {
        this.props.history.push('/user-info/add');
    }
    handleCancel=()=> {
        this.setState({
            visible:false,
            isConfirmVisible:false
        })
    }
    closeModal = () => {
        this.setState({
            visible: false
        });
    };

    institutionChange = (val) => {
        this.formRef.current.setFieldsValue({department: null})
        this.getDepartment({institution_id: val})
    }

    render() {
        const {data,total,visible,userId,isConfirmVisible,editIndex } = this.state;
        roleOptions = getUserRole()

        return (
            <div className={'user-info-container certInfo'}>
                <div className={'header-navigation'}>
                    <div className={'breadcrumb'}>
                        用户管理
                    </div>
                    <div className={'btn'}>
                        {
                            roleOptions.includes('用户-创建') &&
                            <span onClick={()=>this.add()} className="detail-btn" >+ 新增</span>
                        }
                        {/*<span className="detail-btn" onClick={() => this.add()}>+ 新增</span>*/}
                    </div>
                </div>
                <div className={'search-box'}>
                    <div style={{display:"flex"}}>
                        <Form
                            className="search-form"
                            name="basic"
                            ref={this.formRef}
                            layout={'inline'}
                        >
                            <div style={{display:"flex"}}>
                                <span className={'search-title'}>角色:</span>
                                <Form.Item
                                    name="role_name"
                                    className={'input-select'}
                                >
                                    <Select placeholder="请选择">
                                        {
                                            this.state.userRole.map((item, index) => {
                                                return <Option value={item} key={item+index} label={item}>{item}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{display:"flex"}}>
                                <span className={'search-title'}>机构:</span>
                                <Form.Item
                                    name="institution_id"
                                    className={'input-select'}
                                >
                                    <Select placeholder="请选择" onChange={this.institutionChange}>
                                        {
                                            this.state.institutionList.map((item, index) => {
                                                return <Option value={item.ID} key={item.ID} label={item.Name}>{item.Name}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{display:"flex"}}>
                                <span className={'search-title'}>部门:</span>
                                <Form.Item
                                    name="department"
                                    className={'input-select'}
                                >
                                    <Select placeholder="请选择">
                                        {
                                            this.state.department.map((item, index) => {
                                                return <Option value={item} key={item+index} label={item}>{item}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </div>
                        </Form>

                        <div className="item-enterprise btn">
                            <span className="detail-btn" onClick={() => this.search()}>查找</span>
                            <span className="detail-btn reset-btn" onClick={() => this.reset()}>重置</span>
                        </div>
                    </div>
                    <div className="search-input">
                        <Input size="large"
                               onChange={(event) => this.getSearchVal(event)}
                               placeholder={'输入用户名搜索'}
                               suffix={
                                   <img src={searchInputIcon}
                                        alt=""
                                        onClick={() => this.search()}
                                        className='img-code'/>
                               }

                        />
                        {/*<div className="input-btn" onClick={() => this.search()}/>*/}
                    </div>
                </div>
                <div className={'table-data'}>
                    <Table columns={this.state.columns} scroll={{ x: 1200 }}  dataSource={data} pagination={false}   rowKey='ID'/>
                    <div className={'page-total-data'}>
                        <div >
                            共计 <span className={'total-num'}>{total}</span> 条数据
                        </div>
                        <Pagination
                            className={'pageStyle'}
                            onChange={this.onChangePage.bind(this)}
                            defaultCurrent={1}
                            total={total}
                            pageSize={10}
                            current={this.state.currentPage}
                        />
                    </div>
                </div>
                <Modal title='修改密码'
                       visible={visible}
                       closeIcon={<img src={closeImg} alt=""/>}
                       onCancel={this.handleCancel}
                       onOk={this.handleCancel}
                       okText={'确定'}
                       footer={null}
                       width={600}
                       wrapClassName='modal-edit'
                       destroyOnClose={true}
                >
                    <ChangePaw close={this.closeModal} userID={userId}/>
                </Modal>
                <Modal
                    visible={isConfirmVisible}
                    cancelText={'取消'}
                    width={'458px'}
                    okText={'确定'}
                    title={'提示'}
                    centered
                    onOk={this.handleOk}
                    className="warning-dialog"
                    onCancel={this.handleCancel}>
                    <div className="warning-content">
                        <IconWarning />
                        <p className='account-modal-title logout-title'>
                            {
                                data[editIndex]?.Disabled ? '启用用户后，该用户恢复使用，确定启用该用户？'
                                    : '禁用用户后，该用户无法使用，确定禁用该用户？'
                            }

                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}